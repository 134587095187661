import { Box, makeStyles } from '@material-ui/core'
import styled from 'styled-components'

export const useImageStyles = makeStyles({
	root: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		height: '100vh',
		color: '#ffffff',
	},
	backdrop: {
		backgroundColor: '#000000 !important',
	},
	backButton: {
		color: '#ffffff',
		padding: 0,
	},
	middle: {
		display: 'flex',
		justifyContent: 'center',
		maxHeight: '60vh',
		'& img': {
			maxHeight: '100%',
			maxWidth: '100%',
		},
	},
	text: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		maxWidth: 'calc(100vw - 96px)',
		'& p': {
			maxWidth: '100%',
		},
	},
	bottom: {
		display: 'flex',
		overflowX: 'auto',
		flexWrap: 'nowrap',
		whiteSpace: 'nowrap',
	},

	boxWrapper: {
		width: 200,
		height: 120,
		padding: 20,
	},
})

export const WrapperSlider = styled(Box)`
	display: flex;

	flex-wrap: nowrap;
	white-space: nowrap;

	&::before,
	&::after {
		content: '';
		flex: 1;
	}
	flex-direction: row;
	overflow: auto;
`

export const ImagePreviewWrapper = styled(Box)`
	width: 200px;
	height: 120px;
	padding: 20px;
`
