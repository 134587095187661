export const PATHS = {
	// COMMON
	common: {
		home: '/',
		reset: '/reset',
		signin: '/signin',
		errors: '/errors',
		change_password: '/change_password',
		api: '/api',
		graphql: '/graphql',
		signup: '/signup',
		tracking: '/tracking',
		link_account: '/link_account',
		link_options: '/link_options',
		maintenance: '/maintenance',
	},
	auth: {
		linkedin: '/linkedin',
	},

	// CARE
	care: {
		home: '/home',
		signup: '/signup',
		profile: '/profile',
		find_job: '/find_job',
		my_jobs: '/my_jobs',
		credential: '/credential',
		activation: '/activation',
		timecards: '/timecards',
		benefits: '/benefits',
		popular_cities: '/popular_cities',
		suggested_jobs: '/suggested_jobs',
		hot_jobs: '/hot_jobs',
		job_matches: '/job_matches',
		jobs: '/jobs',
		refer_traveler: '/refer_traveler',
		contact_us: '/contact_us',
		privacy_policy: '/privacy_policy',
		contact_recruiter: '/contact_recruiter',
		settings: '/settings',
		notification: '/notification',
		compensation: '/compensation',
		badges: '/my-badges',
		verify_credential: '/verify-credential',
		licenses_certifications: '/licenses_certifications',
		skills_checklist: '/skills_checklist',
		all_jobs: '/all_jobs',
	},

	// CARE OUTSIDE LINKS
	outside_care: {},
}
