import {
	apolloClient,
	APPLY_JOB_MUTATION,
	GET_WORKER_DETAIL_QUERY,
	FAVORITE_JOB_MUTATION,
	CREATE_EXTEND_ASSIGNMENT_MUTATION,
	GET_WORKER_ASSIGNMENT_BY_ID_QUERY,
} from '~/common/apollo'
import { observable, store, action, computed } from '~/common/mobx.decorator'
import { authStore, notifyStore } from '~/stores'
import { GET_JOB_DETAIL_QUERY, GET_PUBLIC_JOB_DETAIL_QUERY } from '../care-jobs.graphql'
import { isEmpty } from 'lodash'
import { eventClient } from '@opus/web.core.lib.event-tracking'
import { redirectEventTrackingBookMark } from '~/common/helpers/bookMark.helper'

@store()
class CareJobDetailStore {
	@observable job
	@observable worker = {}
	@observable showError = false
	@observable showDialog = false
	@observable workerAssignment = null

	@action
	resetWorkerAssignment = async () => {
		this.workerAssignment = null
	}

	@computed
	get isActivatedWorker() {
		return authStore.activated
	}

	@computed
	get phoneNotVerified() {
		return isEmpty(this.worker?.phoneVerifiedAt)
	}

	@computed
	get phoneNotExisted() {
		return isEmpty(this.worker?.phone)
	}

	@action
	setShowDialog = async (state) => {
		this.showDialog = state
	}

	@action
	fetchWorkerDetail = async () => {
		const { data } = await apolloClient.query({ query: GET_WORKER_DETAIL_QUERY, variables: { id: authStore.id } })
		this.worker = data.worker
	}

	@action
	fetchWorkerAssignment = async (assignmentId) => {
		if (isEmpty(assignmentId)) {
			return
		}

		try {
			const { data } = await apolloClient.query({ query: GET_WORKER_ASSIGNMENT_BY_ID_QUERY, variables: { id: assignmentId } })
			this.workerAssignment = data.workerAssignment
		} catch (error) {
			notifyStore.error(error.message)
		}
	}

	@action
	fetchJob = async (id) => {
		try {
			this.showError = false
			const response = await apolloClient.query({ query: authStore.authorization ? GET_JOB_DETAIL_QUERY : GET_PUBLIC_JOB_DETAIL_QUERY, variables: { id } })
			this.job = authStore.authorization ? response.data?.job : response.data?.publicJob

			if (!this.job?.id) {
				this.showError = true
			}
		} catch (error) {
			notifyStore.error(error.message)
		}
	}

	@action
	onApplyJob = async () => {
		try {
			if (!authStore.authorization) {
				authStore.setShowDialog(true)
				authStore.setLastJobId(this.job.id)
				return false
			}

			const response = await apolloClient.mutate({
				mutation: APPLY_JOB_MUTATION,
				variables: { jobId: this.job.id },
			})

			const jobApplicant = response.data?.createJobApplicant
			this.job = { ...this.job, jobApplicant }

			const eventId = 'please-submit-detail_btn'
			eventClient.logClickEvent(eventId, { job_id: this.job.id }).catch((error) => console.log(`Error tracking data ${eventId}`, error.message))

			return jobApplicant?.id ? true : false
		} catch (error) {
			notifyStore.error(error.message)
			return false
		}
	}

	@action
	createExtendAssignment = async (workerAssignmentId) => {
		try {
			const response = await apolloClient.mutate({
				mutation: CREATE_EXTEND_ASSIGNMENT_MUTATION,
				variables: { workerAssignmentId },
			})

			await this.fetchJob(this.job.id)
			await this.fetchWorkerAssignment(response.data?.createExtendAssignment?.id)

			notifyStore.success('$MESSAGES.SUCCESSFUL')
		} catch (error) {
			notifyStore.error(error.message)
		}
	}

	@action
	onFavoriteJob = async (flag, eventBookmark) => {
		try {
			const response = await apolloClient.mutate({
				mutation: FAVORITE_JOB_MUTATION,
				variables: { jobId: this.job.id, action: flag ? 'unfavorite' : 'favorite' },
			})
			if (eventBookmark) {
				await redirectEventTrackingBookMark(eventBookmark, {
					job_id: this.job.id,
					is_bookmarked: !flag,
					worker_id: authStore.id,
				})
			}

			this.job = response?.data?.favoriteOrUnfavoriteAJob
			notifyStore.success('$MESSAGES.SUCCESSFUL')
		} catch (error) {
			notifyStore.error(error.message)
		}
	}
}

export const careJobDetailStore = new CareJobDetailStore()
