import { Box, Grid, IconButton, Modal, SvgIcon, Typography } from '@material-ui/core'
import React from 'react'
import { MdChevronLeft } from 'react-icons/md'
import { ImagePreviewWrapper, useImageStyles, WrapperSlider } from './image-preview-carousel.style'
import { convertFileNameEllipsis } from '~/common/helpers/file.helper'
import { UploadPreviewPdfSvg } from '~/components/icons'
import { pdfCarouselButton } from '~/components/fields/file-upload-field/file-upload-field.style'

export const ImagePreviewCarousel = ({ open, onClose, activeImage, listImageSlider, handleSelectImgList, handlePreviewPdf }) => {
	const imageStyles = useImageStyles()
	if (!open) {
		return null
	}

	const renderListImage = () => {
		return listImageSlider.map((item, index) => {
			return item?.contentType === 'application/pdf' ||
				item?.contentType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
				item?.contentType === 'application/msword' ? (
				<Box onClick={() => handlePreviewPdf(item)} key={index} className="image-item">
					<ImagePreviewWrapper>
						<Box css={pdfCarouselButton}>
							<SvgIcon component={UploadPreviewPdfSvg} />
						</Box>
					</ImagePreviewWrapper>

					<Box>
						<Typography
							style={{
								overflow: 'hidden',
								textOverflow: 'ellipsis',
								textAlign: 'center',
								maxWidth: 'calc(100vw - 96px)',
							}}
						>
							{convertFileNameEllipsis(item.alt)}
						</Typography>
					</Box>
				</Box>
			) : (
				<Box onClick={() => handleSelectImgList(item)} key={index} className="image-item">
					<ImagePreviewWrapper>
						<img
							style={{
								width: '100%',
								height: '100%',
							}}
							src={item.src}
							alt={item.alt}
						/>
					</ImagePreviewWrapper>

					<Box>
						<Typography
							style={{
								overflow: 'hidden',
								textOverflow: 'ellipsis',
								textAlign: 'center',
								maxWidth: 'calc(100vw - 96px)',
							}}
						>
							{convertFileNameEllipsis(item.alt)}
						</Typography>
					</Box>
				</Box>
			)
		})
	}

	return (
		<Modal open={open} onClose={onClose} BackdropProps={{ className: imageStyles.backdrop }}>
			<Grid>
				<Grid item>
					<Box className={imageStyles.root}>
						<Box mt={2} ml={1} mr={1}>
							<IconButton aria-label="back" onClick={onClose} className={imageStyles.backButton}>
								<SvgIcon component={MdChevronLeft} fontSize="large" /> <Typography>Back</Typography>
							</IconButton>
						</Box>
						<Box className={imageStyles.middle}>
							<img src={activeImage?.url} alt={activeImage?.title || 'preview-image'} />
						</Box>

						<Box>
							<Typography
								style={{
									overflow: 'hidden',
									textOverflow: 'ellipsis',
									textAlign: 'center',
								}}
							>
								{activeImage?.title}
							</Typography>
						</Box>
						<WrapperSlider mb={2} ml={1} mr={1}>
							{renderListImage()}
						</WrapperSlider>
					</Box>
				</Grid>
			</Grid>
		</Modal>
	)
}
