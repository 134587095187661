import { createInstance } from 'localforage'
import { createEffect, createEvent, createStore, sample } from 'effector'
import { persist } from 'effector-storage'
import { gql } from '@apollo/client'
import { apolloClient } from '../apollo'

//HELLO NEW GUYS: To understand the code flow, please open file AutoSaveFlow.jpg

export const CREATE_DATA_STORAGE = gql`
	mutation CreateDataStorage($companyId: String!, $collection: String!, $document: String, $data: String!, $encrypted: Boolean!) {
		createDataStorage(companyId: $companyId, collection: $collection, document: $document, data: $data, encrypted: $encrypted) {
			id
			collection
			document
			data
		}
	}
`

export const UPDATE_DATA_STORAGE = gql`
	mutation UpdateDataStorage($companyId: String!, $id: String!, $data: String!) {
		updateDataStorage(companyId: $companyId, id: $id, data: $data) {
			id
			collection
			document
			data
		}
	}
`

export const DELETE_DATA_STORAGE = gql`
	mutation DeleteDataStorage($companyId: String!, $id: String!) {
		destroyDataStorage(companyId: $companyId, id: $id) {
			id
			success
		}
	}
`

export const GET_DATA_STORAGE = gql`
	query GetDataStorage($companyId: String!, $collection: String!, $document: String) {
		dataStorages(companyId: $companyId, collection: $collection, document: $document) {
			id
			collection
			document
		}
	}
`

export const LOAD_DATA_STORAGE = gql`
	query LoadDataStorage($companyId: String!, $collection: String!, $document: String) {
		dataStorages(companyId: $companyId, collection: $collection, document: $document) {
			id
			collection
			document
			data
		}
	}
`

export const createDataStorageFx = createEffect('createDataStorage', {
	handler: async ({ companyId, collection, document, data }) => {
		const {
			data: { createDataStorage },
		} = await apolloClient.mutate({
			mutation: CREATE_DATA_STORAGE,
			variables: { companyId, collection, document, data, encrypted: false },
		})
		return createDataStorage
	},
})

export const updateDataStorageFx = createEffect('updateDataStorage', {
	handler: async ({ companyId, id, data }) => {
		const {
			data: { updateDataStorage },
		} = await apolloClient.mutate({
			mutation: UPDATE_DATA_STORAGE,
			variables: { companyId, id, data },
		})
		return updateDataStorage
	},
})

export const deleteDataStorageFx = createEffect('deleteDataStorage', {
	handler: async ({ companyId, id }) => {
		const {
			data: { destroyDataStorage },
		} = await apolloClient.mutate({
			mutation: DELETE_DATA_STORAGE,
			variables: { companyId, id },
		})
		return destroyDataStorage
	},
})

export const getDataStorageFx = createEffect('getDataStorage', {
	handler: async ({ companyId, collection, document }) => {
		const {
			data: { dataStorages },
		} = await apolloClient.query({
			query: GET_DATA_STORAGE,
			variables: { companyId, collection, document },
		})

		return dataStorages?.reverse()?.[0]
	},
})

export const clearAllDataStorageFx = createEffect('getAllDataStorage', {
	handler: async ({ companyId, collection, document }) => {
		const {
			data: { dataStorages },
		} = await apolloClient.query({
			query: GET_DATA_STORAGE,
			variables: { companyId, collection, document },
		})

		const promises = dataStorages.map((dataStorage) => deleteDataStorageFx({ companyId, id: dataStorage.id }))
		return Promise.all(promises)
	},
})

export const loadDataStorageFx = createEffect('loadDataStorage', {
	handler: async ({ companyId, collection, document }) => {
		const {
			data: { dataStorages },
		} = await apolloClient.query({
			query: LOAD_DATA_STORAGE,
			variables: { companyId, collection, document },
		})

		return dataStorages?.reverse()?.[0]
	},
})

export const $storage = createStore({}, { name: 'storage' })

export const syncDocumentEvent = createEvent('syncDocument')
export const clearAllDocumentEvent = createEvent('clearAllDocument')
export const saveDocumentEvent = createEvent('saveDocument')
export const saveDataStorageEvent = createEvent('saveDataStorage')

sample({
	clock: syncDocumentEvent,
	target: saveDocumentEvent,
})

sample({
	clock: syncDocumentEvent,
	target: saveDataStorageEvent,
})

sample({
	clock: loadDataStorageFx.doneData,
	filter: (data) => !!data?.id,
	fn: (data) => ({ name: data?.document, data: data?.data }),
	target: saveDocumentEvent,
})

sample({
	clock: saveDataStorageEvent,
	fn: (document) => ({ companyId: process.env['REACT_APP_COMPANY_ID'], collection: document.collection, document: document.name }),
	target: getDataStorageFx,
})

sample({
	clock: getDataStorageFx.doneData,
	source: saveDataStorageEvent,
	filter: (_, data) => !data?.id,
	fn: (document) => ({
		companyId: process.env['REACT_APP_COMPANY_ID'],
		collection: document.collection,
		document: document.name,
		data: JSON.stringify(document.data),
	}),
	target: createDataStorageFx,
})

sample({
	source: saveDataStorageEvent,
	clock: getDataStorageFx.doneData,
	filter: (document, data) => data?.id && !!document?.data,
	fn: (document, data) => ({
		companyId: process.env['REACT_APP_COMPANY_ID'],
		id: data.id,
		data: JSON.stringify(document.data),
	}),
	target: updateDataStorageFx,
})

sample({
	source: saveDataStorageEvent,
	clock: getDataStorageFx.doneData,
	filter: (document, data) => data?.id && !document?.data,
	fn: (_, data) => ({
		companyId: process.env['REACT_APP_COMPANY_ID'],
		id: data.id,
	}),
	target: deleteDataStorageFx,
})

$storage.on(saveDocumentEvent, (state, document) => ({ ...state, [document.name]: document.data || '' }))

const localforage = createInstance({ name: 'data_storage' })

const adapter = (key) => {
	return {
		get: () => localforage.getItem(key),
		set: (value) => localforage.setItem(key, value),
	}
}
if (persist) persist({ store: $storage, adapter })
