import { createMuiTheme } from '@material-ui/core'
import { primary, text, brand, denotive, wireframe, flowkit, background, app } from './colors'

export const careTheme = createMuiTheme({
	overrides: {
		MuiDrawer: {
			paperAnchorDockedLeft: {
				borderRight: '1px solid #F4F5F4',
			},
		},
		MuiListItem: {
			root: {
				color: '#868686',
				fontSize: '14px',
				fontWeight: 800,
				letterSpacing: '0.1px',
				'&.Mui-selected': {
					backgroundColor: 'transparent',
				},
			},
		},
		MuiFormLabel: {
			root: {
				fontSize: '12px',
				color: '#0E3F6C',
				whiteSpace: 'nowrap',
				fontWeight: 500,
				'&$focused': {
					color: '#0E3F6C',
				},
			},
		},

		MuiFormControlLabel: {
			label: {
				fontWeight: 500,
				fontSize: '14px',
				lineHeight: '18px',
				letterSpacing: '0.2px',
				color: '#0E3F6C',
			},
		},
		MuiInputBase: {
			root: {
				color: '#24444E',
				fontWeight: 400,
				fontSize: '14px',
			},
		},
		MuiInputLabel: {
			shrink: {
				transform: 'translate(0, 1.5px) scale(0.86)',
			},
		},
		MuiBackdrop: {
			root: {
				backgroundColor: 'transparent !important',
			},
		},
		MuiDialog: {
			container: {
				backgroundColor: 'rgba(0, 0, 0, 0.5)',
			},
		},
		MuiButtonGroup: {
			groupedOutlinedPrimary: {
				'&:hover': {
					borderColor: '#99E1E1',
				},
			},
		},
		MuiButton: {
			outlinedPrimary: {
				border: `2px solid ${brand.emerald}`,
				backgroundColor: '#FFFFFF',
				'&:hover': {
					border: `2px solid ${brand.emerald}`,
				},
			},
			containedPrimary: {
				color: app.white,
				backgroundColor: brand.emerald,
				boxShadow: 'none',
				'&:hover': {
					backgroundColor: `${brand.emerald} !important`,
					boxShadow: 'none',
				},
			},
			sizeLarge: {
				height: '48px',
				padding: '8px !important',
				fontSize: '15px',
				fontWeight: 800,
			},
			sizeSmall: {
				height: '32px',
			},
		},
		MuiTab: {
			root: {
				textTransform: 'unset',
			},
		},
		MuiDivider: {
			root: {
				backgroundColor: '#E6E6E6',
			},
		},
		MuiTabs: {
			indicator: {
				backgroundColor: `${brand.emerald} !important`,
			},
		},
		MuiPaper: {
			rounded: {
				borderRadius: 'none',
			},
		},
		MuiRadio: {
			colorSecondary: {
				color: '#D1D1D1',
				'&.Mui-checked': {
					color: '#3EA9BC',
					'&.Mui-radio-view': {
						color: '#A2C0CA !important',
					},
				},
			},
		},

		MuiCheckbox: {
			colorSecondary: {
				color: '#D1D1D1 !important',
				stroke: 'white !important',
				'&.Mui-checked': {
					color: '#3EA9BC !important',
					'& svg': {
						'& path': {
							stroke: 'white !important',
						},
					},
					'&.Mui-checked-view': {
						color: '#D1D1D1 !important',
						'& svg': {
							'& path': {
								stroke: 'white !important',
							},
						},
					},
				},
			},
		},
		MuiLinearProgress: {
			colorPrimary: {
				width: '50px',
				height: '6px',
				borderRadius: '2px',
				background: '#E6E6E6 !important',
			},
		},
		MuiCircularProgress: {
			root: {
				color: '#99E1E1',
			},
		},
	},
	spacing: 8,
	palette: {
		primary,
		brand,
		denotive,
		wireframe,
		flowkit,
		background,

		text: {
			primary: '#3EA9BC',
			secondary: '#03606A',
			tertiary: '#7E7E80',
			disabled: '#AEAEAE',
			blue: '#1C5CDB',
			hint: '#868686',
			...text,
		},
	},
	colors: {
		primary,
		brand,
		denotive,
		wireframe,
		flowkit,
		background,

		text: {
			primary: '#000000',
			secondary: '#03606A',
			tertiary: '#7E7E80',
			disabled: '#AEAEAE',
			blue: '#1C5CDB',
			hint: '#868686',
			...text,
		},
	},
	shape: {
		borderRadius: 10,
	},
	borders: ['unset', 'solid 1px #e5e5e5', 'solid 1px rgba(130, 206, 207, 0.1)', 'solid 1px #82CECE'],
	typography: {
		fontFamily: ['Avenir'],
		h1: {
			fontSize: '56px',
			fontStyle: 'normal',
			fontWeight: 800,
			lineHeight: '72px',
			letterSpacing: '0.2px',
		},
		h2: {
			fontSize: '44px',
			fontStyle: 'normal',
			fontWeight: 800,
			lineHeight: '56px',
			letterSpacing: '0.2px',
		},
		h3: {
			fontSize: '32px',
			fontStyle: 'normal',
			fontWeight: 800,
			lineHeight: '40px',
			letterSpacing: '0.1px',
		},
		h4: {
			fontSize: '26px',
			fontStyle: 'normal',
			fontWeight: 800,
			lineHeight: '32px',
			letterSpacing: '0.2px',
		},
		h5: {
			fontSize: '20px',
			fontStyle: 'normal',
			lineHeight: '26px',
			fontWeight: 800,
			letterSpacing: '0.1px',
		},
		h6: {
			fontSize: '18px',
			fontStyle: 'normal',
			lineHeight: '24px',
			fontWeight: 800,
			letterSpacing: '0.1px',
		},
		title1: {
			fontSize: '16px',
			fontStyle: 'normal',
			fontWeight: 800,
			lineHeight: '20px',
			letterSpacing: '0.1px',
		},
		title2: {
			fontSize: '14px',
			fontStyle: 'normal',
			lineHeight: '18px',
			fontWeight: 800,
			letterSpacing: '0.1px',
		},

		title3: {
			fontSize: '12px',
			fontStyle: 'normal',
			lineHeight: '18px',
			fontWeight: 800,
			letterSpacing: '0.1px',
		},

		subtitle1: {
			fontSize: '16px',
			fontStyle: 'normal',
			lineHeight: '20px',
			fontWeight: 500,
			letterSpacing: '0.1px',
		},

		subtitle2: {
			fontSize: '14px',
			fontStyle: 'normal',
			lineHeight: '18px',
			fontWeight: 500,
			letterSpacing: '0.2px',
		},

		subtitle3: {
			fontSize: '12px',
			lineHeight: '18px',
			fontWeight: 500,
			letterSpacing: '0.1px',
		},
		body1: {
			color: '#000',
			fontSize: '16px',
			fontStyle: 'normal',
			lineHeight: '20px',
			fontWeight: 350,
			letterSpacing: '0.3px',
		},

		body2: {
			fontSize: '14px',
			fontStyle: 'normal',
			lineHeight: '18px',
			fontWeight: 350,
			letterSpacing: '0.2px',
		},

		body3: {
			fontSize: '12px',
			fontStyle: 'normal',
			lineHeight: '18px',
			fontWeight: 400,
			letterSpacing: '0.1px',
		},

		price: {
			fontSize: '16px',
			fontStyle: 'normal',
			lineHeight: '24px',
			fontWeight: 800,
			letterSpacing: '0px',
			color: '#333335',
		},

		button: {
			fontSize: '15px',
			fontStyle: 'normal',
			lineHeight: '20px',
			fontWeight: 800,
			letterSpacing: '0px',
		},

		caption: {
			fontSize: '12px',
			fontStyle: 'normal',
			lineHeight: '18px',
			fontWeight: 500,
			letterSpacing: '0.2px',
		},

		ghostButton: {
			fontSize: '16px',
			fontStyle: 'normal',
			lineHeight: '18px',
			fontWeight: 400,
			letterSpacing: '0.2px',
		},

		footNote: {
			fontSize: '12px',
			fontStyle: 'normal',
			lineHeight: '20px',
			fontWeight: 500,
			letterSpacing: '0.1px',
		},

		p1: {
			fontSize: '14px',
			fontStyle: 'normal',
			fontWeight: 700,
			lineHeight: '22px',
			letterSpacing: '0.14px',
		},
		p2: {
			fontSize: '12px',
			fontStyle: 'normal',
			fontWeight: 500,
			lineHeight: '18px',
			letterSpacing: '0.24px',
		},
		p3: {
			fontSize: '11px',
			fontStyle: 'normal',
			fontWeight: 500,
			lineHeight: '16px',
			letterSpacing: '0.22px',
		},
	},
})
