import { gql } from '@apollo/client'
import {
	EMERGENCY_CONTACT_FRAGMENT,
	FILE_FRAGMENT,
	IMAGE_FILE_FRAGMENT,
	SALESFORCE_INTEGRATION_FRAGMENT,
	WORK_EXPERIENCE_FRAGMENT,
	WORK_EXPERIENCE_OVERVIEW_FRAMENT,
	WORKED_TIMESHEET_FRAGMENT,
	WORKER_ADDRESS_FRAGMENT,
	WORKER_CERTIFICATION_FRAGMENT,
	WORKER_CREDENTIAL_FRAGMENT,
	WORKER_FRAGMENT,
	WORKING_AUTHORIZATION_FRAGMENT,
} from '../apollo.fragment'

export const VERIFY_WORKER_QUERY = gql`
	query verifyWorker($token: String!) {
		workerToken(token: $token) {
			token
			notificationChannel
			worker {
				...workerFragment
				recruiter {
					id
					name
					phone
					email
					title
				}
				company {
					salesforceIntegration {
						...salesforceIntegrationFragment
					}
				}
			}
		}
	}

	${WORKER_FRAGMENT}
	${SALESFORCE_INTEGRATION_FRAGMENT}
`

export const GET_WORKER_COMPENSATION_QUERY = gql`
	query getWorkerCompensation {
		workerCompensation {
			id
		}
	}
`

export const GET_WORKER_DETAIL_QUERY = gql`
	query getWorkerDetailQuery($id: String!) {
		worker(id: $id) {
			...workerFragment
			resume {
				...fileFragment
			}
			workExperienceOverview {
				...workExperienceOverview
			}
			workerAddresses {
				...workerAddressFragment
			}
			workingAuthorization {
				...workingAuthorizationFragment
			}
			workExperiences {
				...workExperienceFragment
			}
			workerCertifications {
				...workerCertificationFragment
			}
			emergencyContact {
				...emergencyContactFragment
			}
		}
	}

	${WORKER_FRAGMENT}
	${WORKER_ADDRESS_FRAGMENT}
	${WORKING_AUTHORIZATION_FRAGMENT}
	${WORK_EXPERIENCE_FRAGMENT}
	${WORKER_CERTIFICATION_FRAGMENT}
	${FILE_FRAGMENT}
	${EMERGENCY_CONTACT_FRAGMENT}
	${WORK_EXPERIENCE_OVERVIEW_FRAMENT}
`

export const GET_WORKER_DETAIL_FOR_TIMECARDS_QUERY = gql`
	query getWorkerDetailTimeQuery($id: String!) {
		worker(id: $id) {
			...workerFragment
			company {
				salesforceIntegration {
					...salesforceIntegrationFragment
				}
			}
			workedTimesheets {
				...workedTimesheetFragment
				timesheetImages {
					imageUrls(size: ["100x100", "200x200"])
					...imageFileFragment
				}
			}
		}
	}

	${WORKER_FRAGMENT}
	${SALESFORCE_INTEGRATION_FRAGMENT}
	${WORKED_TIMESHEET_FRAGMENT}
	${IMAGE_FILE_FRAGMENT}
`

export const GET_CREDENTIALING_URL_QUERY = gql`
	query getWorkerDetailCredentialingQuery($id: String!) {
		worker(id: $id) {
			id
			credentialingUrl
		}
	}
`

export const GET_WORKER_SKILL_CHECKLISTS_COUNT_QUERY = gql`
	query getWorkerSkillChecklistsCountQuery($id: String!) {
		worker(id: $id) {
			id
			workerSkillChecklistsCount
		}
	}
`

export const GET_WORKER_CERTIFICATIONS_QUERY = gql`
	query getWorkerCertificationsQuery($id: String!) {
		workerCertifications(workerId: $id) {
			...workerCertificationFragment
			licenseImages {
				...imageFileFragment
			}
		}
	}

	${WORKER_CERTIFICATION_FRAGMENT}
	${IMAGE_FILE_FRAGMENT}
`

export const GET_WORKER_CREDENTIALS_QUERY = gql`
	query getWorkerCredentialsQuery($filter: WorkerCredentialStatusEnum, $after: String, $before: String, $first: Int, $last: Int) {
		workerCredentials(filter: $filter, after: $after, before: $before, first: $first, last: $last) {
			totalCount
			pageInfo {
				hasNextPage
				hasPreviousPage
				endCursor
			}
			nodes {
				...workerCredentialFragment
			}
		}
	}

	${WORKER_CREDENTIAL_FRAGMENT}
`
export const GET_WORKER_SOCIAL_QUERY = gql`
	query getWorkerSocialQuery {
		workerSocialAuthentications {
			providerName
			id
			providerUuid
			email
		}
	}
`
export const GET_WORKER_PARTIAL_SETTINGS_QUERY = gql`
	query getWorkerPartialSettings($workerId: String!) {
		appInit(workerId: $workerId) {
			worker {
				settings
			}
		}
	}
`

export const GET_SIGNED_URLS = gql`
	query getSignedUrls($blobIds: [String!]!) {
		signedUrls(blobIds: $blobIds) {
			id
			fileUrl
		}
	}
`

export const CHECK_REMAINING_OTP_REQUESTS = gql`
	query checkRemainingOtpRequests {
		checkRemainingOtpRequests {
			remainingSessionResendCode
			remainingTotalResendCode
			maxPhoneChange
			remainingPhoneChange
		}
	}
`

export const GET_WORKER_ALL_BADGES_QUERY = gql`
	query allBadgesQuery($after: String, $before: String, $first: Int, $last: Int) {
		badges(after: $after, before: $before, first: $first, last: $last) {
			totalCount
			pageInfo {
				hasNextPage
				hasPreviousPage
				endCursor
			}
			nodes {
				id
				active
				badgeName
				badgeType
				description
				icon {
					id
					thumbnails
				}
			}
		}
	}
`

export const GET_WORKER_EARNED_BADGES_QUERY = gql`
	query workerBadgesQuery($filter: BadgeFilter, $after: String, $before: String, $first: Int, $last: Int) {
		workerBadges(filter: $filter, after: $after, before: $before, first: $first, last: $last) {
			totalCount
			pageInfo {
				hasNextPage
				hasPreviousPage
				endCursor
			}
			nodes {
				id
				badge {
					id
					active
					badgeName
					badgeType
					description
					icon {
						id
						thumbnails
					}
				}
				badgeType
				completedPercent
				status
			}
		}
	}
`
