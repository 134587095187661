import { css } from 'styled-components'

export const notifyDialogStyle = css`
	.MuiDialog-paper {
		margin: 8px;

		> .MuiBox-root {
			min-width: 240px;
			max-width: 370px;

			.header {
				.MuiTypography-root {
					padding: 0 4px;
					background-color: ${({ theme }) => theme.palette.common.white};
					color: #3ea9bc !important;
					font-size: 16px !important;
					font-weight: 800 !important;
				}
			}
		}
	}
	.content-notify p {
		font-size: 13px;
	}
`
