import { FormControl, FormControlLabel, FormHelperText } from '@material-ui/core'
import { useField } from 'formik'
import React, { useCallback } from 'react'
import { FIELD_MODE } from '~/common/constants'
import { useFieldError } from '~/common/hooks'
import { useTranslation } from '@opus/web.core.hooks.use-translation'
import { Checkbox } from '../../checkbox'
import { css } from 'styled-components'

const checkFieldStyle = css`
	.MuiFormControlLabel-label {
		font-size: 14px;
		font-weight: 350;
		color: #000;
	}
`
export const CheckField = ({ name, validate, label, mode, disabled = false, withUrl = false, handleOnChange }) => {
	const { t } = useTranslation()

	const [field, meta] = useField({ name, validate: mode === FIELD_MODE.edit && validate })

	const error = useFieldError(meta)

	const handleChange = useCallback(
		(event, checked) => {
			event.target.value = checked

			if (mode === FIELD_MODE.edit) {
				field.onChange(event)
				handleOnChange && handleOnChange(event)
			}
		},
		// eslint-disable-next-line
		[mode, field]
	)

	const ctrLabel = withUrl ? <>{label}</> : t(label)
	return (
		<FormControl error={!!error}>
			<FormControlLabel
				css={checkFieldStyle}
				disabled={disabled}
				control={<Checkbox {...field} checked={field.value || false} className={`Mui-checked-${mode}`} onChange={handleChange} name={name} />}
				label={ctrLabel}
			/>
			{error && <FormHelperText>{error}</FormHelperText>}
		</FormControl>
	)
}

CheckField.defaultProps = {
	mode: FIELD_MODE.edit,
}
