import { eventClient } from '@opus/web.core.lib.event-tracking'
import { appStore } from '~/stores'

const logActionEvent = (eventId, actionType, eventParams) => {
	eventClient.logActionEvent(eventId, actionType, eventParams).catch((error) => console.log(`Error tracking data ${eventId}`, error.message))
}

export const logClickEvent = (eventId, eventParams) => {
	logActionEvent(eventId, 'click', eventParams)
}

export const logTrackingScreenEvent = (screenName, eventParams) => {
	logActionEvent(screenName, 'screen', eventParams)
}

export const logDefaultActionEvent = (screenName, eventParams) => {
	logActionEvent(screenName, 'default', eventParams)
}

export const logClickLike = (eventType, jobId, listType) => {
	logClickEvent(eventType, {
		list_type: listType,
		job_id: jobId,
	})
}

export const logClickDisLike = (eventType, jobId, listType) => {
	logClickEvent(eventType, {
		list_type: listType,
		job_id: jobId,
	})
}

export const logClickShare = (eventType, jobId, listType) => {
	logClickEvent(eventType, {
		list_type: listType,
		job_id: jobId,
	})
}

export const logClickSignUpToViewDetail = (eventType, jobId, listType) => {
	logClickEvent(eventType, {
		list_type: listType,
		job_id: jobId,
	})
}

export const getLocation = async () => {
	try {
		if (window.host_location) {
			return window.host_location
		}

		const response = await fetch(`https://ipinfo.io/115.79.200.81?token=${process.env.REACT_APP_IP_INFO_TOKEN}`)
		const data = await response.json()
		window.host_location = data

		if (data.error) {
			return {}
		}

		return data
	} catch (error) {
		console.debug(error)
	}
}

export const getIP = async () => {
	try {
		if (window.host_ip) {
			return window.host_ip
		}

		const response = await getLocation()

		const ip = await response.ip
		window.host_ip = ip
		return ip
	} catch (error) {
		console.debug(error)
	}
}

export const getUserTrackingBasicInfor = async () => {
	return new Promise(async (resolve) => {
		const ip = await getIP()
		const location = await getLocation()
		const infor = {
			userAgent: navigator.userAgent,
			ip,
			location,
			createdAt: new Date(),
			deletedAt: null,
			timeStamp: +Math.floor(Date.now() / 1000),
			company_id: appStore.id,
		}

		return resolve(infor)
	})
}
