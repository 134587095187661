import axios from 'axios'

export const createAxios = (headers) => {
	return axios.create({
		baseURL: process.env.REACT_APP_TRACKING_API_URL,
		headers: {
			'x-api-key': process.env.REACT_APP_TRACKING_API_KEY,
			...headers,
		},
		method: 'PUT',
		timeout: 30000,
	})
}

const records = async (data) => {
	// try {
	// 	const trackingData = {
	// 		Records: [data],
	// 	}
	// 	return await createAxios().put('/records', trackingData)
	// } catch (error) {
	// 	console.log('error', error)
	// 	return null
	// }
}

export const httpRequestTracking = {
	record: records,
}
