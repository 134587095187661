import gql from 'graphql-tag'

export const GET_APP_INIT_QUERY = gql`
	query getAppInit($workerId: String!) {
		appInit(workerId: $workerId) {
			companyConfig {
				livechatIntegration {
					accountId
				}
			}
			company {
				id
				mainContactEmail
				phone
			}
		}
	}
`

export const GET_COMPANY_PUBLIC_INFO_QUERY = gql`
	query getCompanyPublicInfo($companyId: String) {
		company(id: $companyId) {
			id
			mainContactEmail
			phone
			companyConfig {
				adpUrl
				contactSupportUrl
				enableHourlySearch
				enableScreenTracking
				faqsUrl
				id
				oneSignalAppId
				privacyPoblicyUrl
				webOpusPolicies
				facebookUrl
				twitterUrl
				instagramUrl
				linkedinUrl
				youtubeUrl
				privacyPolicyContent
			}
		}
	}
`

export const GET_COMPANY_INFO_QUERY = gql`
	query getCompanyInfo($workerId: String!) {
		appInit(workerId: $workerId) {
			company {
				id
				mainContactEmail
				phone
			}
			companyConfig {
				adpUrl
				contactSupportUrl
				enableHourlySearch
				enableScreenTracking
				faqsUrl
				id
				oneSignalAppId
				privacyPoblicyUrl
				webOpusPolicies
				facebookUrl
				twitterUrl
				instagramUrl
				linkedinUrl
				youtubeUrl
				privacyPolicyContent
			}
		}
	}
`
