import { css } from 'styled-components'
import styled from 'styled-components'
import { InputLabel } from '@material-ui/core'
import { Typography } from '~/components/typography'
export const inputStyle = css`
	display: none;
`

export const uploadButtonStyle = css`
	height: 80px;
	width: 80px;
	background: #e7fbfd;
	padding: 0;
	margin: 0;
	border: 1px dashed #99e1e1;
	box-sizing: border-box;
	border-radius: 8px;
	justify-content: center;
	display: flex;
	align-content: center;
	&:hover {
		border: 1px dashed #99e1e1;
	}
`

export const loadingButtonStyle = css`
	height: 80px;
	width: 80px;
	background: white;
	border: 1px solid #e6e6e6;
	box-sizing: border-box;
	border-radius: 8px;
	justify-content: center;
	display: flex;
	align-items: center;
`

export const pdfCarouselButton = css`
	height: 100%;
	width: 100%;
	background: white;
	justify-content: center;
	display: flex;
	align-items: center;
`

export const imageBoxStyle = css`
	position: absolute;
	top: 0;
	left: 0;
	transform: translateY(50%);

	.container {
		width: 40px;
		height: 40px;
		border-radius: 50%;

		display: flex;
		justify-content: center;
		align-items: center;

		background: linear-gradient(180.56deg, #7bcdc8 0.48%, rgba(17, 156, 175, 0) 273.77%), #7aa2af;

		svg {
			font-size: 16px;
		}
	}
`

export const wrapperStyle = css`
	width: 100%;
`

export const itemBoxStyle = css`
	padding: 4px 0;
	display: flex;
	align-items: center;

	height: 30px;
	background: #ffffff;
	border: 1px solid #c2d6dc;
	box-sizing: border-box;
	border-radius: 32px;

	font-weight: 400;
	font-size: 14px;
	line-height: 18px;
	letter-spacing: 0.2px;

	color: #0e3f6c;
	margin: 3px;

	.MuiIconButton-root {
		margin-left: auto;
	}

	p {
		overflow: hidden;
	}

	.preview-icon {
		display: flex;
		margin: 0 13px;

		svg {
			font-size: 18px;
		}
	}

	.lazyload-wrapper {
		width: 28px;
		height: 28px;
		border-radius: 50%;
		overflow: hidden;

		img {
			width: 100%;
			height: 100%;
			object-fit: contain;
		}
	}
`

export const fileNameElisips = css`
	font-size: 12px;
	line-height: 18px;
	text-align: center;
	color: #000;
	font-weight: 350;
`

export const WrapperSliderBox = styled.div`
	display: flex;
	overflow: auto;
	max-width: calc(100vw - 450px);
	@media (max-width: 1065px) {
		max-width: calc(100vw - 350px);
	}
	@media (max-width: 960px) {
		max-width: 90vw;
	}
	@media (max-width: 650px) {
		max-width: 87vw;
	}
	@media (max-width: 600px) {
		max-width: 85vw;
	}
`

export const WrapperInputLabel = styled(InputLabel)`
	padding: 5px 0;
	font-size: 12px;
`

export const WrapperSubtitile = styled(Typography)`
	font-size: 12px;
	font-weight: 400;
	color: #808080;
	line-height: 18px;
	padding: 5px 0;
`
export const WrapperButton = styled.div`
	padding: 10px 10px 10px 0;
`
