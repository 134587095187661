import { eventClient, events } from '@opus/web.core.lib.event-tracking'
import flatten, { unflatten } from 'flat'
import { difference, flatMap, isEmpty, omitBy, trim, omit } from 'lodash'
import { nanoid } from 'nanoid'
import { apolloClient, APPLY_JOB_MUTATION, GET_SUGGESTION_JOBS_QUERY, LOOK_UP_LOCATION_QUERY } from '~/common/apollo'
import { EVENTS } from '~/common/constants'
import { computed, store, action, observable, event } from '~/common/mobx.decorator'
import { appStore, masterStore, notifyStore, authStore } from '~/stores'
import { SEARCH_JOB_QUERY } from './care-find-job.graphql'
import { careStore } from '~/companies/care/care.store'
import { LOCATION_SEARCH_BY } from './components/constants'
@store()
class CareFindJobStore {
	@observable searchData
	@observable sortKey = 'SCORE'
	@observable hotJob = false

	@observable token = null
	@observable searchInfo
	@observable jobs = []
	@observable suggestedJobs = []
	@observable zipcode = null
	@observable locationByZipcode = {}
	@observable showInvalidZipcode = false

	@action
	resetShowInvalidZipcode = () => {
		this.showInvalidZipcode = false
	}

	@computed
	get disciplineVal() {
		const { worker } = careStore
		return worker?.workExperienceOverview?.discipline
			? this.disciplineOptions?.filter((opt) => opt.value === worker?.workExperienceOverview?.discipline).map((opt) => opt.value)[0]
			: null
	}

	@computed
	get specialtyOptionsVal() {
		const { worker } = careStore

		return worker?.workExperienceOverview?.primarySpecialty || worker?.workExperienceOverview?.secondarySpecialty
			? this.specialtyOptions
					?.filter(
						(opt) =>
							(opt.value === worker?.workExperienceOverview?.primarySpecialty && opt.parentValue === this.disciplineVal) ||
							(opt.value === worker?.workExperienceOverview?.secondarySpecialty && opt.parentValue === this.disciplineVal)
					)
					.map((opt) => opt.value)
			: []
	}

	@computed
	get stateOptions() {
		return masterStore.licenseStates
	}

	@computed
	get filter() {
		if (!this.searchData) {
			return null
		}

		const { location, jobId, radius, zipcode, zipcodeRadius, ...values } = this.searchData || {}

		let nearBySearch = null
		let locationState = null

		const licenseStateCompact = values?.locationSearchBy === LOCATION_SEARCH_BY.showAll
		switch (values?.locationSearchBy) {
			case LOCATION_SEARCH_BY.zipCode:
				nearBySearch = !this.showInvalidZipcode
					? {
							lat: zipcode ? this.locationByZipcode?.latitude : null,
							long: zipcode ? this.locationByZipcode?.longitude : null,
							radius: zipcode && this.locationByZipcode?.latitude && this.locationByZipcode?.longitude ? zipcodeRadius : null,
					  }
					: null
				break
			case LOCATION_SEARCH_BY.showAll:
				nearBySearch = null
				break
			case LOCATION_SEARCH_BY.citiesState:
				nearBySearch = location?.city && { lat: location?.latitude, long: location?.longitude, radius }
				locationState = location?.state
				break
			default:
				break
		}

		const data = flatten({ ...values, keywordSearch: trim(jobId) || '', nearBySearch, locationState, hotJob: this.hotJob, licenseStateCompact })
		let normalizeData = omitBy(data, (value) => (typeof value === 'object' ? isEmpty(value) : !value))
		normalizeData = omit(normalizeData, ['zipcode', 'locationSearchBy'])

		return unflatten(normalizeData)
	}

	@action
	resetFilter = () => {
		this.searchData = null
		this.jobs = []
		this.suggestedJobs = []
		this.zipcode = null
		this.locationByZipcode = {}
		this.showInvalidZipcode = false
	}

	@computed
	get hideDistance() {
		return !this.filter?.nearBySearch
	}

	@computed
	get disciplineOptions() {
		return masterStore.jobDisciplines
	}

	@computed
	get suggestedJobsItems() {
		return this.suggestedJobs
	}

	@computed
	get jobShiftOptions() {
		return masterStore.jobShifts
	}

	@computed
	get locationSearchByOptions() {
		return [
			{ label: LOCATION_SEARCH_BY.citiesState, value: LOCATION_SEARCH_BY.citiesState },
			{ label: LOCATION_SEARCH_BY.zipCode, value: LOCATION_SEARCH_BY.zipCode },
			{ label: LOCATION_SEARCH_BY.showAll, value: LOCATION_SEARCH_BY.showAll },
		]
	}

	@computed
	get total() {
		return this.searchInfo?.totalCount || 0
	}

	@computed
	get hasNextPage() {
		return this.jobs?.length < this.total
	}

	@computed
	get specialtyOptions() {
		return flatMap(masterStore.jobDisciplines?.map(({ dependent_values = [], value }) => dependent_values.map((dep) => ({ ...dep, parentValue: value }))))
	}

	@action
	fetchLocationByZipcode = async (zipcode) => {
		this.showInvalidZipcode = false
		const response = await apolloClient.query({ query: LOOK_UP_LOCATION_QUERY, variables: { zipcode } })
		this.locationByZipcode = response?.data?.lookUpLocation
		if (this.locationByZipcode === null) this.showInvalidZipcode = true
	}

	@action
	fetchSuggestedJobs = async () => {
		const response = await apolloClient.query({ query: GET_SUGGESTION_JOBS_QUERY, variables: { first: 3, workerId: authStore?.id } })
		this.suggestedJobs = response.data.suggestionJobs.nodes

		eventClient.logEvent(
			new events.FindJobRecommendJobsSuccessEvent({
				num_jobs: this.suggestedJobs?.length,

				jobs: this.suggestedJobs?.map((job, index) => ({
					id: job.id,
					avg_weekly: job.weeklyPayAmount,
					order: index,
					percent_matched: job.matchingPercentage,
				})),
			})
		)
	}

	@action
	onSubmitSearch = async (searchData, actions) => {
		eventClient.logEvent(
			new events.FindJobClickSearchSuccessEvent({
				worker_id: authStore.id,
				discipline: searchData?.discipline,
				specialties: searchData?.specialties,
				city: searchData?.location?.city,
				state: searchData?.location?.state,
				radius: searchData.radius,
				wage_min: searchData?.wageMin,
				hourly_pay_low: searchData?.hourlyPayLow,
				job_id: searchData?.jobId,
				shifts: searchData?.shifts,
			})
		)

		if (this.showInvalidZipcode) {
			actions.setFieldValue('zipcodeRadius', 5)
			actions.setFieldValue('zipcode', '')
		}

		const previousSearchData = this.searchData && flatten(this.searchData)
		const newSearchData = flatten(searchData)
		const diff = difference(previousSearchData, newSearchData)

		if (!this.searchData || !isEmpty(diff)) {
			this.token = nanoid()
		}

		this.searchData = searchData
	}

	@action
	refreshSearchJob = async () => {
		this.searchInfo = null
		this.jobs = []
		this.onScrollToEnd()
	}

	@action
	onCloseSearch = () => {
		this.searchData = null
		this.sortKey = 'SCORE'
		this.resetShowInvalidZipcode()
	}

	@action
	onScrollToEnd = async () => {
		const { keywordSearch, nearBySearch, city, ...restFilter } = this.filter || {}
		const sortBy = this.sortKey && { field: this.sortKey, order: this.sortKey === 'DISTANCE' ? 'asc' : 'desc' }

		const response = await apolloClient.query({
			query: SEARCH_JOB_QUERY,
			variables: {
				companyId: appStore.id,
				filter: restFilter,
				keywordSearch,
				nearBySearch,
				sortBy,
				token: this.token,
				limit: 20,
				offset: this.jobs?.length || 0,
			},
		})

		this.searchInfo = response?.data?.jobSearch

		eventClient.logEvent(
			new events.FindJobResultsRecommendJobsSuccessEvent({
				num_jobs: this.searchInfo?.totalCount,
				jobs: this.searchInfo?.jobs?.map((job, index) => ({
					id: job.id,
					avg_weekly: job.weeklyPayAmount,
					order: this.jobs?.length + index,
					percent_matched: job.matchingPercentage,
				})),
			})
		)

		if (this.searchInfo?.jobs?.length > 0) {
			this.jobs = Array.from(
				new Map(
					[...this.jobs, ...(this.searchInfo?.jobs ?? [])] // Merge and handle potential undefined with fallback to empty array
						.filter((job) => !isEmpty(job)) // Filter out empty jobs first to optimize
						.map((job) => [job.id, job]) // Use job id as the key for deduplication
				).values() // Extract the unique jobs based on id
			)
		}
	}

	@action
	onUpdateSortKey = async (sortKey) => {
		if (sortKey !== this.sortKey) {
			this.sortKey = sortKey
			this.searchInfo = { totalCount: this.searchInfo?.totalCount || 0 }
			this.jobs = []
			eventClient.logEvent(new events.FindJobResultsChooseSortSuccessEvent({ content: sortKey }))
			await this.onScrollToEnd()
		}
	}

	@action
	onApplyJob = async (jobId) => {
		try {
			const response = await apolloClient.mutate({
				mutation: APPLY_JOB_MUTATION,
				variables: { jobId },
			})

			const jobApplicant = response.data?.createJobApplicant
			this.jobs = this.jobs?.map((job) => (job.id === jobId ? { ...job, jobApplicant } : job))

			eventClient.logEvent(
				new events.FindJobResultsClickPleaseSubmitSuccessEvent({
					job_id: jobId,
					order_number: this.jobs?.findIndex((job) => job.id === jobId),
					jobs: this.jobs?.slice(0, 50).map((item) => item?.id),
				})
			)

			return jobApplicant?.id ? true : false
		} catch (error) {
			notifyStore.error(error.message)
		}
	}

	@action
	onApplySuggestedJob = async (jobId) => {
		try {
			const response = await apolloClient.mutate({
				mutation: APPLY_JOB_MUTATION,
				variables: { jobId },
			})

			const jobApplicant = response.data?.createJobApplicant
			this.suggestedJobs = this.suggestedJobs?.map((job) => (job.id === jobId ? { ...job, jobApplicant } : job))

			eventClient.logEvent(
				new events.FindJobClickPleaseSubmitSuccessEvent({
					job_id: jobId,
					order_number: this.suggestedJobs.findIndex((job) => job.id === jobId),
					jobs: this.suggestedJobs?.slice(0, 50).map((item) => item?.id),
				})
			)
			return jobApplicant?.id ? true : false
		} catch (error) {
			notifyStore.error(error.message)
		}
	}

	@action
	toggleHotJob = async () => {
		this.hotJob = !this.hotJob
		await this.refreshSearchJob()
	}

	@event(EVENTS.authStore.logout)
	userLogout() {
		this.searchData = null
		this.sortKey = 'SCORE'
	}
}

export const careFindJobStore = new CareFindJobStore()
