import React, { useMemo, useState } from 'react'
import { Box, IconButton, InputAdornment, Popper } from '@material-ui/core'
import { VisibilityOutlined } from '@material-ui/icons'
import { useField } from 'formik'
import { Typography } from '~/components/typography'
import { HidePassSvg } from '~/components/icons'
import { rules } from './helpers'
import { TextField } from '../text-field/text-field.component'

const Message = ({ rule, value }) => {
	const valid = useMemo(() => value && rule.regex.test(value?.trim()), [rule.regex, value])

	return (
		<Box
			display="flex"
			alignItems="center"
			gridGap="4px"
			padding="1px 8px"
			bgcolor={valid ? 'rgba(165, 251, 124, 0.20)' : '#F8F8F8'}
			color={valid ? '#3BB700' : '#7E7E80'}
			borderRadius="16px"
		>
			{valid && (
				<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
					<g clip-path="url(#clip0_20848_228)">
						<path
							d="M11 5.54286V6.00286C10.9994 7.08107 10.6503 8.1302 10.0047 8.99377C9.35908 9.85735 8.45164 10.4891 7.41768 10.7948C6.38372 11.1005 5.27863 11.0638 4.26724 10.6902C3.25584 10.3165 2.39233 9.62591 1.80548 8.7214C1.21863 7.81688 0.939896 6.74689 1.01084 5.67102C1.08178 4.59514 1.4986 3.57103 2.19914 2.7514C2.89968 1.93177 3.84639 1.36055 4.89809 1.12293C5.9498 0.885317 7.05013 0.99403 8.035 1.43286M11 2L6 7.005L4.5 5.505"
							stroke="#3BB700"
							stroke-width="1.5"
							stroke-linecap="round"
							stroke-linejoin="round"
						/>
					</g>
					<defs>
						<clipPath id="clip0_20848_228">
							<rect width="12" height="12" fill="white" />
						</clipPath>
					</defs>
				</svg>
			)}
			<Typography variant="subtitle3" color="inherit">
				{rule.message}
			</Typography>
		</Box>
	)
}

export const PasswordPopper = ({ open, anchorRef, value }) => {
	const focused = document?.activeElement === anchorRef?.current

	if (!anchorRef?.current) {
		return null
	}

	return (
		<Popper open={focused} anchorEl={anchorRef?.current} placement="bottom-start">
			<Box
				display="flex"
				gridGap="8px"
				width={anchorRef.current.clientWidth + 46}
				bgcolor="#FFFFFF"
				padding="12px"
				borderRadius="8px"
				marginTop="4px"
				boxShadow="0px 2px 12px 0px rgba(16, 24, 40, 0.08), 0px 0px 4px 0px rgba(16, 24, 40, 0.06)"
				flexWrap="wrap"
			>
				{rules.map((rule, index) => {
					return <Message key={index} rule={rule} value={value} />
				})}
			</Box>
		</Popper>
	)
}

export const PasswordField = ({ showPopper, ...props }) => {
	const [show, setShow] = useState()
	const anchorRef = React.useRef()
	const [field] = useField(props?.name)

	return (
		<Box>
			{showPopper && <PasswordPopper open={true} anchorRef={anchorRef} value={field?.value}></PasswordPopper>}

			<TextField
				inputRef={anchorRef}
				type={show ? 'text' : 'password'}
				InputProps={{
					endAdornment: (
						<InputAdornment position="end">
							<IconButton style={{ padding: 0 }} onClick={() => setShow(!show)}>
								{show ? <VisibilityOutlined /> : <HidePassSvg />}
							</IconButton>
						</InputAdornment>
					),
					...props?.InputProps,
				}}
				{...props}
			/>
		</Box>
	)
}
