import { gql } from '@apollo/client'
import {
	FILE_FRAGMENT,
	ONBOARDING_PART_FRAGMENT,
	WORKER_ONBOARDING_PART_FRAGMENT,
	SKILL_CHECKLIST_FRAGMENT,
	WORKER_SKILL_CHECKLIST_FRAGMENT,
} from '../apollo.fragment'

export const GET_WORKER_ONBOARDING_OVERVIEW_QUERY = gql`
	query getWorkerOnboardingQuery {
		workerOnboardingOverview {
			id
			message
			percentageCompleted
			state
			companyOnboarding {
				companyId
				description
				id
				name
				onboardingPartsNumber
			}
			onboardingParts {
				workerOnboardingPart {
					...workerOnboardingPartFragment
				}
				...onboardingPartFragment
			}
		}
	}

	${ONBOARDING_PART_FRAGMENT}
	${WORKER_ONBOARDING_PART_FRAGMENT}
`

export const GET_WORKER_ONBOARDING_PART_QUERY = gql`
	query getWorkerOnboardingPart($id: String!) {
		onboardingPart(id: $id) {
			workerOnboardingPart {
				...workerOnboardingPartFragment
			}
		}
	}

	${WORKER_ONBOARDING_PART_FRAGMENT}
`

export const GET_WORKER_RESUMES_QUERY = gql`
	query workerResumesQuery($id: String!) {
		worker(id: $id) {
			resume {
				...fileFragment
			}
		}
	}

	${FILE_FRAGMENT}
`

export const GET_SKILLS_CHECKLIST_QUERY = gql`
	query getSkillChecklistsQuery($discipline: String!, $specialty: String, $after: String, $before: String, $first: Int, $last: Int) {
		skillChecklists(discipline: $discipline, specialty: $specialty, after: $after, before: $before, first: $first, last: $last) {
			totalCount
			pageInfo {
				hasNextPage
				hasPreviousPage
				endCursor
			}
			nodes {
				...skillChecklistFragment
			}
		}
	}

	${SKILL_CHECKLIST_FRAGMENT}
`

export const GET_WORKER_SKILLS_CHECKLISTS_QUERY = gql`
	query workerSkillsChecklistQuery($id: String!) {
		worker(id: $id) {
			workerSkillChecklists {
				...workerSkillChecklistFragment
			}
		}
	}

	${WORKER_SKILL_CHECKLIST_FRAGMENT}
`
