import { Box, CircularProgress, Collapse, FormHelperText, SvgIcon } from '@material-ui/core'
import { useField, useFormikContext } from 'formik'
import React, { useCallback, useMemo, useState } from 'react'
import { EVENTS, FIELD_MODE } from '~/common/constants'
import { useFieldError } from '~/common/hooks'
import { useTranslation } from '@opus/web.core.hooks.use-translation'
import { Button } from '~/components/button'
import {
	fileNameElisips,
	inputStyle,
	loadingButtonStyle,
	uploadButtonStyle,
	WrapperButton,
	WrapperInputLabel,
	WrapperSliderBox,
	wrapperStyle,
	WrapperSubtitile,
} from './file-upload-field.style'
import { captureException, removeFile as defaultRemoveFile, uploadFile as defaultUploadFile, blobToFile } from '~/common/helpers'
import { filter, startsWith } from 'lodash'
import { AddImagePreviewSvg } from '~/components/icons'
import { useDebounce } from 'react-use'
import { eventBus } from 'mobx-event-bus2'
import { ImagePreviewCarousel } from 'src/components/image-preview-carousel/image-preview-carousel.component.js'
import { convertFileNameEllipsis } from '~/common/helpers/file.helper'
import UploadListPdf from '~/components/fields/file-upload-field/upload-list-pdf'
import UploadListImage from '~/components/fields/file-upload-field/upload-list-image'
import { ICONS, IMAGE_TYPE, SPECIAL_TYPES, HEIC_TYPES } from '~/components/fields/file-upload-field/file-upload.helper'
import * as PropTypes from 'prop-types'
import UploadListDoc from '~/components/fields/file-upload-field/upload-list-doc'
import heic2any from 'heic2any'
function LoadingUploadBox(props) {
	return (
		<Box
			style={{
				padding: '10px 10px 10px 0',
			}}
		>
			<Box css={loadingButtonStyle}>
				<CircularProgress size={20} />
			</Box>
			<p css={fileNameElisips}>{convertFileNameEllipsis(props.item?.name)}</p>
		</Box>
	)
}

LoadingUploadBox.propTypes = { item: PropTypes.any }
export const FileUploadField = ({
	name,
	label,
	validate,
	mode,
	multiple,
	title,
	message,
	camera,
	uploadFile,
	removeFile,
	accept,
	image,
	subTitle,
	onlyPdf,
	isNotShowTitle = false,
}) => {
	const { t } = useTranslation()
	const { setFieldValue } = useFormikContext()
	const [showPreview, setShowPreview] = useState()
	const [processingItems, setProcesingItems] = useState([])

	const validateFunc = useCallback(
		(value) => {
			if (processingItems?.length > 0) {
				return 'UPLOADING'
			}

			if (typeof validate === 'function') {
				return validate(value)
			}
		},
		[validate, processingItems]
	)

	const [field, meta] = useField({ name, validate: validateFunc })

	const id = useMemo(() => `${name}_file_upload`, [name])

	const error = useFieldError(meta)

	const [activeImage, setActiveImage] = useState({})

	useDebounce(
		async () => {
			if (processingItems.length === 0) {
				return
			}

			const [file, ...rest] = processingItems

			try {
				if (HEIC_TYPES.some((t) => file?.type === t) || SPECIAL_TYPES.some((t) => file?.name?.includes(t))) {
					await heic2any({ blob: file, toType: 'image/jpeg', quality: 1 }).then(async (newImage) => {
						const re = /HEIC|HEIF/gi
						const newFile = await blobToFile(newImage, file?.name?.replace(re, 'jpeg'))

						const { signedBlobId, preSignedUrl, blobId } = await uploadFile(newFile)

						const values = [{ filename: newFile.name, contentType: newFile.type, signedBlobId, src: preSignedUrl, blobId }, ...(field.value || [])]
						field.onChange({ target: { name, value: values } })
					})
				} else {
					const { signedBlobId, preSignedUrl, blobId } = await uploadFile(file)

					const values = [{ filename: file.name, contentType: file.type, signedBlobId, src: preSignedUrl, blobId }, ...(field.value || [])]
					field.onChange({ target: { name, value: values } })
				}
			} catch (error) {
				console.log(error?.message, 'error')
				captureException('File upload failed.', error)
				eventBus.post(EVENTS.notifyStore.fireError, { message: `File upload failed. "${file.name}"` })
			} finally {
				setProcesingItems(rest)
			}
		},
		300,
		[processingItems]
	)

	const handleChange = useCallback(
		async (event) => {
			const files = Array.from(event?.target?.files)

			event.target.value = ''

			const invalidFileSize = files?.some((file) => file.size >= 10 * 1000 * 1000)
			const invalidFileType = files?.some(
				(file) =>
					!(
						Object.keys(ICONS).includes(file?.type) ||
						startsWith(file?.type, IMAGE_TYPE) ||
						file?.type.indexOf('application/pdf') === -1 ||
						SPECIAL_TYPES.some((t) => file?.name?.includes(t))
					)
			)

			const invalidePdf = files?.every((file) => file.type.indexOf('application/pdf') === -1)

			if (invalidFileSize) {
				return eventBus.post(EVENTS.notifyStore.fireError, { message: '$ERRORS.MAXIMUM_FILE_SIZE' })
			}

			if (invalidFileType) {
				const invalidMsg = t('$ERRORS.UNSUPPORT_CONTENT_TYPE_UPLOAD', { types: accept })
				return eventBus.post(EVENTS.notifyStore.fireError, { message: invalidMsg })
			}
			if (onlyPdf && invalidePdf) {
				return eventBus.post(EVENTS.notifyStore.fireError, { message: '$ERRORS.UNSUPPORT_PDF' })
			}

			setProcesingItems(files)
		},
		[onlyPdf, t, accept]
	)

	const handleDelete = useCallback(
		async (index) => {
			const file = field?.value?.[index]
			if (file.id) {
				setTimeout(() => removeFile(file.id), 100)
				setFieldValue(`${name}.${index}._destroy`, true)
			}
			setFieldValue(`${name}.${index}._destroy`, true)
		},
		[setFieldValue, name, removeFile, field.value]
	)

	const visible = useMemo(() => field.value?.filter((item) => !item?._destroy)?.length > 0 || processingItems.length > 0, [field.value, processingItems])

	const disabled = useMemo(() => processingItems?.length > 0, [processingItems])

	const actualViewValue = filter(field?.value, (v) => !v?._destroy)

	const hideUpload = useMemo(() => !multiple && (processingItems?.length > 0 || actualViewValue.length > 0), [processingItems, multiple, actualViewValue])

	const handlePreviewImage = (item) => {
		setActiveImage({
			url: item?.fileUrl || item?.imageUrls?.s_500x500 || item?.src,
			title: item?.filename,
			blodId: item?.blodId,
		})
		setShowPreview(true)
	}

	const handlePreviewPdf = async (item) => {
		await window.open(item?.fileUrl || item?.src, '_blank')
	}

	const handleSelectImgList = (item) => {
		setActiveImage({
			url: item?.fileUrl || item?.src,
			title: item?.alt,
			blobId: item?.blobId,
		})
	}
	const listImageSlider = field.value
		?.filter((item) => !item?.hasOwnProperty('_destroy'))
		.map((item) => {
			return {
				alt: item?.filename,
				src: item?.fileUrl || item?.imageUrls?.s_500x500 || item?.src,
				id: item?.id,
				blodId: item?.blodId,
				contentType: item?.contentType,
			}
		})

	function titleSubTitle({ title, subTitle }) {
		return (
			<>
				<WrapperInputLabel>{t(title)}</WrapperInputLabel>
				<WrapperSubtitile>{t(subTitle)}</WrapperSubtitile>
			</>
		)
	}

	function subTitleView({ subTitle }) {
		return (
			<>
				<WrapperSubtitile>{t(subTitle)}</WrapperSubtitile>
			</>
		)
	}
	function renderTitle() {
		if (isNotShowTitle) {
			return subTitleView({ subTitle })
		} else if (mode === FIELD_MODE.edit || mode === FIELD_MODE.viewDisable) {
			return titleSubTitle({ title, subTitle })
		}
	}

	const renderButton = (mode === FIELD_MODE.edit && !hideUpload) || (mode === FIELD_MODE.viewDisable && !hideUpload)

	return (
		<Box>
			{renderTitle()}
			<WrapperSliderBox>
				{renderButton && (
					<WrapperButton position="relative">
						<input
							webkitdirectory="true"
							disabled={disabled}
							accept={accept}
							max={3}
							multiple={multiple}
							camera={camera}
							id={id}
							type="file"
							css={inputStyle}
							onChange={handleChange}
						/>

						<label htmlFor={id} css={wrapperStyle}>
							<Button border={2} variant="outlined" color="primary" component="div" css={uploadButtonStyle}>
								<Box>
									<div className="container">
										<SvgIcon component={AddImagePreviewSvg} />
									</div>
								</Box>
							</Button>
						</label>
					</WrapperButton>
				)}
				<Collapse in={visible}>
					<div style={{ display: 'flex', overflow: 'auto', overscrollBehaviorX: 'none' }}>
						{processingItems?.map((item, index) => (
							<LoadingUploadBox key={`${item.name}_${index}`} item={item} />
						))}

						{field?.value?.map((item, index) => {
							return (
								!item?._destroy &&
								(item?.contentType === 'application/pdf' ? (
									<UploadListPdf
										key={index}
										onClick={() => handlePreviewPdf(item)}
										item={item}
										mode={mode}
										onClick1={(e) => {
											e.stopPropagation()
											handleDelete(index)
										}}
									/>
								) : item?.contentType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
								  item?.contentType === 'application/msword' ? (
									<UploadListDoc
										key={index}
										onClick={() => handlePreviewPdf(item)}
										item={item}
										mode={mode}
										onClick1={(e) => {
											e.stopPropagation()
											handleDelete(index)
										}}
									/>
								) : (
									<UploadListImage
										key={index}
										onClick={() => handlePreviewImage(item)}
										setRefreshImage={(item) => {
											setFieldValue(`${name}.${index}`, item)
										}}
										refresh={true}
										item={item}
										mode={mode}
										onClick1={(e) => {
											e.stopPropagation()
											handleDelete(index)
										}}
									/>
								))
							)
						})}
					</div>
				</Collapse>
			</WrapperSliderBox>

			{(!actualViewValue || actualViewValue.length === 0) && label && mode === FIELD_MODE.view && (
				<Box mt={1} mb={1}>
					{t('$PLACEHOLDERS.NONE')}
				</Box>
			)}

			{error && (
				<FormHelperText error={true} style={{ position: 'relative', top: 0 }}>
					{error}
				</FormHelperText>
			)}
			<ImagePreviewCarousel
				handlePreviewPdf={handlePreviewPdf}
				handleSelectImgList={handleSelectImgList}
				listImageSlider={listImageSlider}
				open={showPreview}
				activeImage={activeImage}
				onClose={() => setShowPreview(false)}
			/>
		</Box>
	)
}

FileUploadField.defaultProps = {
	mode: FIELD_MODE.edit,
	camera: true,
	removeFile: defaultRemoveFile,
	uploadFile: defaultUploadFile,
	accept: '.png,.jpg,.jpeg,.pdf,.doc,.docx,.heic,.heif',
	multiple: true,
}
