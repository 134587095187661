import React, { useCallback, useMemo, useState } from 'react'
import { useField } from 'formik'
import { Box, TextField as MuiTextField } from '@material-ui/core'
import { useFieldError, useFieldFocused } from '~/common/hooks'
import { useTranslation } from '@opus/web.core.hooks.use-translation'
import { labelStyle, phoneFieldStyle, viewLabelStyle } from './phone-field.style'
import NumberFormat from 'react-number-format'
import { FIELD_MODE } from '~/common/constants'
import { LabelField } from '@opus/web.core.form.label-field'
import { useDebounce } from 'react-use'
import { defaultTo } from 'lodash'
import { observer } from '~/common/mobx.decorator'
import InputLabel from '@material-ui/core/InputLabel'

const PHONE_FORMAT = '(###) ###-####'
const PHONE_PLACEHOLDER = '(xxx) xxx-xxxx'
const PHONE_CODE = '+1'

const PhoneFormat = observer(({ inputRef, onChange, value, ...rest }) => {
	const [inputValue, updateInputValue] = useState(value)
	const [, setFocus] = useState()

	const onValueChange = React.useCallback(
		(values) => {
			updateInputValue(values.value || '')
		},
		[updateInputValue]
	)

	const onFocus = useCallback(
		(event) => {
			setFocus(true)
			if (typeof rest.onFocus === 'function') {
				rest.onFocus(event)
			}
		},
		[setFocus, rest]
	)

	useDebounce(
		() => {
			if (value !== inputValue) {
				updateInputValue(value)
			}
		},
		0,
		[value]
	)

	useDebounce(
		() => {
			onChange({ target: { name: rest.name, value: inputValue } })
		},
		0,
		[inputValue]
	)

	const fieldValue = useMemo(() => defaultTo(inputValue, value), [inputValue, value])

	return (
		<NumberFormat
			{...rest}
			value={fieldValue}
			defaultValue={PHONE_CODE}
			getInputRef={inputRef}
			onValueChange={onValueChange}
			format={PHONE_FORMAT}
			onFocus={onFocus}
			isNumericString
		/>
	)
})

export const PhoneField = observer(({ name, validate, label, placeholder, mode, size, endLabel, disabled, InputProps }) => {
	const { t } = useTranslation()
	const [field, meta] = useField({ name, validate: mode === FIELD_MODE.edit && validate })
	const [focusProps] = useFieldFocused(field)
	const error = useFieldError(meta)

	const fieldValue = useMemo(() => field.value?.replace(PHONE_CODE, '') || null, [field.value])

	const handleChange = useCallback(
		(event) => {
			const value = event.target.value ? `${PHONE_CODE}${event.target.value}` : ''

			if (!value && !field.value) {
				return
			}

			field.onChange({ target: { name, value: value } })
		},
		[name, field]
	)

	if (mode === FIELD_MODE.view) {
		return (
			<LabelField
				css={viewLabelStyle}
				shrink
				label={t(label)}
				displayValueFormat={() => (
					<Box display="flex" alignItems="center" width="100%">
						{fieldValue ? (
							<>
								<NumberFormat value={fieldValue} displayType="text" format={PHONE_FORMAT} />
							</>
						) : (
							<span>-</span>
						)}
					</Box>
				)}
			/>
		)
	}

	return (
		<Box>
			<Box display="flex" justifyContent="space-between" alignItems="center">
				<InputLabel shrink htmlFor={name} css={labelStyle}>
					<Box display="flex" alignItems="center" gridGap={4}>
						{t(label)}
					</Box>
				</InputLabel>
				{endLabel}
			</Box>
			<MuiTextField
				id={name}
				size={size}
				placeholder={placeholder}
				helperText={error}
				error={!!error}
				css={phoneFieldStyle}
				InputProps={{
					inputComponent: PhoneFormat,
					...focusProps,
					...InputProps,
				}}
				{...field}
				value={fieldValue}
				onChange={handleChange}
				InputLabelProps={{ shrink: true }}
			/>
		</Box>
	)
})
PhoneField.defaultProps = {
	label: 'PHONE_NUMBER',
	// validate: compose(required, phone),
	mode: FIELD_MODE.edit,
	size: 'small',
	placeholder: PHONE_PLACEHOLDER,
}
