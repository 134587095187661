import gql from 'graphql-tag'
import { LOCATION_FRAGMENT } from '~/common/apollo/apollo.fragment'

export const GET_JOB_DETAIL_QUERY = gql`
	query getJobDetail($id: String!) {
		job(id: $id) {
			id
			title
			description
			hotJob
			discipline
			specialty
			duration
			isFavorited
			hourlyPayHigh
			hourlyPayLow
			stipends
			taxableHigh
			taxableLow
			account {
				name
			}
			location {
				...locationFragment
			}
			locationImage
			activeAssignment {
				id
				startDate
				endDate
				lastDayScheduled
			}
			jobApplicant {
				id
				status
				workerAssignment {
					id
					startDate
					endDate
					availableToRate
					extendAssignment {
						id
						status
					}
					rating {
						comment
						createdAt
						id
						ratePoint
					}
				}
			}
			matchingPercentage
			weeklyPayAmount
			minWeeklyPayAmount
			startDate
			endDate
			hoursPerWeek
			shiftsPerWeek
			externalJobId
			licensesRequired
			licenseStateRequired
			certificationsRequired
			status
			shifts
		}
	}

	${LOCATION_FRAGMENT}
`

export const GET_PUBLIC_JOB_DETAIL_QUERY = gql`
	query getPublicJobDetail($id: String!) {
		publicJob(id: $id) {
			id
			title
			description
			hotJob
			discipline
			specialty
			duration
			isFavorited
			hourlyPayHigh
			hourlyPayLow
			stipends
			taxableHigh
			taxableLow
			account {
				name
			}
			location {
				...locationFragment
			}
			locationImage
			activeAssignment {
				id
				startDate
				endDate
				lastDayScheduled
			}
			jobApplicant {
				id
				status
				workerAssignment {
					id
					startDate
					endDate
					extendAssignment {
						id
						status
					}
				}
			}
			matchingPercentage
			weeklyPayAmount
			minWeeklyPayAmount
			startDate
			hoursPerWeek
			shiftsPerWeek
			externalJobId
			licensesRequired
			licenseStateRequired
			certificationsRequired
			status
			shifts
		}
	}

	${LOCATION_FRAGMENT}
`
