import { useTranslation } from '@opus/web.core.hooks.use-translation'
import React from 'react'
import { ButtonBase, Grid, Popover, Typography } from '@material-ui/core'
import { ContentWrapper, StyledDeleteButton, typoPopoverStyle } from './notification-item.style'
import { NotificationBellSvg, ThreeDotSvg, UnreadNotificationSvg } from '~/components/icons'
import { careNotificationStore } from '../care-notification.store'
import { formatNotiCreatedAt } from '~/common/helpers'
import { NOTIFICATION_DATA_EVENT, NOTIFICATION_DATA_TYPE } from '~/common/constants'
import { apolloClient, GET_WORKER_ASSIGNMENT_QUERY } from '~/common/apollo'
import { routerStore } from '~/stores'
import { RateDialogConfirmComponent } from '~/components/job-card/rate-dialog-confirm'

export const NOTI_STATUS = {
	unread: 'unread',
	read: 'read',
}

export const NotificationItem = ({ notification, onDelete }) => {
	const { id, status, heading, message, createdAt, webUrl, dataType, dataEvent, dataId } = notification
	const { markNotificationStatus, fetchNotification } = careNotificationStore
	const { t } = useTranslation()
	const [anchorEl, setAnchorEl] = React.useState(null)
	const [job, setJob] = React.useState(null)
	const [workerAssigment, setWorkerAssigment] = React.useState(null)
	const [openRateDialog, setOpenRateDialog] = React.useState(false)
	const handleClosePopover = () => setAnchorEl(null)
	const notiCreatedAt = formatNotiCreatedAt(createdAt)
	const onDeleteThisNoti = () => {
		onDelete(id)
		setAnchorEl(null)
	}
	const onClickItem = async () => {
		if (dataType === NOTIFICATION_DATA_TYPE.workerAssignment && dataEvent === NOTIFICATION_DATA_EVENT.assignmentRatingReminder) {
			const response = await apolloClient.query({
				query: GET_WORKER_ASSIGNMENT_QUERY,
				variables: { id: dataId },
			})

			if (!response?.data?.workerAssignment?.rating?.ratePoint) {
				if (status === NOTI_STATUS.unread) {
					await markNotificationStatus(id)
				}
				routerStore.goPage(`/jobs/${response?.data?.workerAssignment?.job?.id}?isShowRatingForm=true`)
			} else {
				setWorkerAssigment(response?.data?.workerAssignment)
				setOpenRateDialog(true)
				setJob(response?.data?.workerAssignment?.job)
				if (status === NOTI_STATUS.unread) {
					await markNotificationStatus(id)
					await fetchNotification()
				}
			}
			return
		}

		setTimeout(() => {
			const websiteHost = window.origin
			const webUrlObj = new URL(webUrl)
			if (websiteHost === webUrlObj.origin) {
				window.open(webUrl, '_self')
			} else {
				window.open(webUrl, '_blank')
			}
		}, 500)
		if (status === NOTI_STATUS.unread) {
			await markNotificationStatus(id)
			await fetchNotification()
		}
	}

	return (
		<ContentWrapper container id={id} spacing={1} status={status}>
			<Grid item xs>
				<NotificationBellSvg className="bell-icon" />
			</Grid>

			<Grid item xs={12} style={{ cursor: 'pointer' }} onClick={onClickItem}>
				<Typography>{heading}</Typography>
				<Typography>{message}</Typography>

				<Typography>{notiCreatedAt}</Typography>
			</Grid>

			{status === NOTI_STATUS.unread ? (
				<Grid item>
					<UnreadNotificationSvg />
				</Grid>
			) : null}

			<Grid item>
				<ButtonBase onClick={(e) => setAnchorEl(e.currentTarget)}>
					<ThreeDotSvg />
				</ButtonBase>
			</Grid>

			<Popover
				open={!!anchorEl}
				onClose={handleClosePopover}
				anchorEl={anchorEl}
				elevation={3}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
			>
				<StyledDeleteButton onClick={onDeleteThisNoti}>
					<Typography css={typoPopoverStyle}>{t('DELETE_THIS_NOTIFICATION')}</Typography>
				</StyledDeleteButton>
			</Popover>

			{job && (
				<RateDialogConfirmComponent confirmDialog={openRateDialog} setShowConfirmDialog={setOpenRateDialog} workerAssigment={workerAssigment} job={job} />
			)}
		</ContentWrapper>
	)
}
