import { isEmpty } from 'lodash'
import { EVENTS } from '~/common/constants'
import { store, observable, action, computed, event } from '~/common/mobx.decorator'
import Bowser from 'bowser'
import { authStore } from '~/stores'
import { careNotificationStore } from '~/features/care-notification/care-notification.store'
import OneSignal from 'react-onesignal'

@store()
class NotifyStore {
	@observable messages = []
	@observable doneMessages = {}

	@observable dialogs = []
	@observable doneDialogs = {}
	@observable isOpenNotification = false
	@computed
	get waitMessages() {
		return this.messages.filter((message) => !this.doneMessages[message.key])
	}

	@computed
	get waitDialogs() {
		return this.dialogs.filter((dialog) => !this.doneDialogs[dialog.key])
	}

	@computed
	get signal() {
		return window.OneSignal || []
	}

	@action
	open = async (message, options) => {
		const data = {
			key: `${Date.now()}_${Math.random()}`,
			message,
			options,
		}

		if (options.showDialog) {
			this.dialogs.push(data)

			return data.key
		}
		if (options?.onlyWebPush) {
			return
		}

		this.messages.push(data)
		return data.key
	}

	@action
	success = async (message, options) => {
		return this.open(message, { ...options, variant: 'success' })
	}

	@action
	error = async (message, options) => {
		return this.open(message, { ...options, variant: 'error' })
	}

	@action
	info = async (message, options) => {
		return this.open(message, { ...options, variant: 'info' })
	}

	@action
	warning = async (message, options) => {
		return this.open(message, { ...options, variant: 'warning' })
	}

	@action
	closeMessage = async (key) => {
		this.doneMessages[key] = true
	}

	@action
	closeDialog = async (key) => {
		this.doneDialogs[key] = true
	}

	@event(EVENTS.notifyStore.fireError)
	handleError({ payload }) {
		this.error(payload?.message)
	}

	@action
	initOneSignal = async (oneSignalAppId) => {
		if (!isEmpty(oneSignalAppId)) {
			this.signal.push(() => {
				this.signal.init({
					appId: oneSignalAppId,
					safari_web_id: process.env.REACT_APP_SAFARI_ONESIGNAL,
					autoRegister: false,
					autoResubscribe: true,
					notifyButton: {
						enable: true,
						prenotify: true,
					},
					allowLocalhostAsSecureOrigin: true,
				})
			})
		}
	}

	@action
	sendInfo = async () => {
		const { profile, notificationChannel } = authStore
		OneSignal.setExternalUserId(notificationChannel)
		// OneSignal.addListenerForNotificationOpened(() => {
		// 	this.isOpenNotification = true
		// 	if (token) {
		// 		routerStore.goPage(PATHS.care.credential)
		// 	}
		// })
		if (!isEmpty(profile?.company?.companyConfig?.oneSignalAppId)) {
			const browser = Bowser.getParser(window.navigator.userAgent)
			OneSignal.getSubscription((notOptedOut) => {
				if (notOptedOut) {
					OneSignal.setSubscription(true)
					OneSignal.registerForPushNotifications()
				}
			})

			OneSignal.sendTags({
				companyId: profile?.company?.companyConfig?.companyId,
				userType: 'Worker',
				browser: browser.getBrowser(),
				workerId: profile?.id,
				workerEmail: profile?.email,
			}).then((tagsSent) => {
				console.warn('tagsSent: ', tagsSent)
			})
		}
	}

	@action
	unInitOneSignal = async () => {
		// await OneSignal.removeExternalUserId()
	}

	@action
	markPushNotification = async () => {
		this.signal.push(() => {
			const onNotificationClicked = function (data) {
				careNotificationStore.markNotificationStatus(data?.data?.id)
			}

			const handler = function (data) {
				onNotificationClicked(data)

				OneSignal.addListenerForNotificationOpened(handler)
			}

			OneSignal.addListenerForNotificationOpened(handler)
		})
	}
}

export const notifyStore = new NotifyStore()
