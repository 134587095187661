import React from 'react'
import { Box, IconButton, SvgIcon } from '@material-ui/core'
import { fileNameElisips, loadingButtonStyle } from '~/components/fields/file-upload-field/file-upload-field.style'
import { RemoveSvg, UploadPreviewPdfSvg } from '~/components/icons'
import { convertFileNameEllipsis } from '~/common/helpers'
import { FIELD_MODE } from '~/common/constants'

function UploadListPdf(props) {
	const isRenderEdit = props.mode === FIELD_MODE.edit || (props.mode === FIELD_MODE.viewDisable && props?.item.hasOwnProperty('signedBlobId'))
	return (
		<Box
			onClick={props.onClick}
			style={{
				padding: '10px 10px 10px 0',
				cursor: 'pointer',
				position: 'relative',
				marginLeft: '0px',
			}}
		>
			<Box css={loadingButtonStyle}>
				<SvgIcon component={UploadPreviewPdfSvg} />
			</Box>
			<p css={fileNameElisips}>{convertFileNameEllipsis(props.item?.filename)}</p>
			{isRenderEdit && (
				<IconButton style={{ position: 'absolute', top: '15px', right: '25px' }} size="small" onClick={props.onClick1}>
					<RemoveSvg />
				</IconButton>
			)}
		</Box>
	)
}

export default UploadListPdf
