import { apolloClient } from '~/common/apollo'
import { action, computed, observable, store } from '~/common/mobx.decorator'
import { DELETE_ALL_NOTIFICATIONS, GET_NOTIFICATION_LIST, MARK_ALL_NOTIFICATIONS, MARK_NOTIFICATION_STATUS } from './care-notification.graphql'
import { notifyStore } from '~/stores'
import { NOTI_STATUS } from './components/notification-item.component'

@store()
class CareNotificationStore {
	@observable notifications = []
	@observable totalCount
	@observable totalRead
	@observable totalUnread
	@observable pageInfo

	@computed
	get endCursor() {
		return this.pageInfo?.endCursor
	}

	@computed
	get hasNextPage() {
		return this.pageInfo?.hasNextPage
	}

	@action
	fetchNotification = async () => {
		const response = await apolloClient.query({
			query: GET_NOTIFICATION_LIST,
		})
		this.notifications = response?.data?.notifications?.nodes
		this.setNotiData(response?.data?.notifications)
	}

	@action
	deleteAllReadNoti = async (id) => {
		try {
			await apolloClient.mutate({
				mutation: DELETE_ALL_NOTIFICATIONS,
				variables: {
					notificationIds: id ? [id] : null,
				},
			})
		} catch (error) {
			notifyStore.error(error?.message)
		}
	}

	@action
	markAllNotiAsRead = async () => {
		try {
			await apolloClient.mutate({
				mutation: MARK_ALL_NOTIFICATIONS,
				variables: {},
			})
		} catch (error) {
			notifyStore.error(error?.message)
		}
	}

	@action
	markNotificationStatus = async (id) => {
		try {
			await apolloClient.mutate({
				mutation: MARK_NOTIFICATION_STATUS,
				variables: {
					notificationIds: [id],
					status: NOTI_STATUS.read,
				},
			})
		} catch (error) {
			notifyStore.error(error?.message)
		}
	}

	@action
	onLoadMore = async () => {
		const response = await apolloClient.query({
			query: GET_NOTIFICATION_LIST,
			variables: {
				after: this.endCursor,
			},
		})
		this.notifications = [...this.notifications, ...response?.data?.notifications?.nodes]
		this.setNotiData(response?.data?.notifications)
	}

	setNotiData = (notifications) => {
		this.totalCount = notifications?.totalCount
		this.totalRead = notifications?.totalRead
		this.totalUnread = notifications?.totalUnread
		this.pageInfo = notifications?.pageInfo
	}

	formatNotiNumber = (totalUnread) => {
		let result = totalUnread
		if (totalUnread >= 100) {
			result = '99+'
		}
		if (totalUnread >= 1000) {
			result = '999+'
		}
		return result
	}
}

export const careNotificationStore = new CareNotificationStore()
