import React from 'react'
import { Box, Dialog, FormHelperText, Grid, Typography } from '@material-ui/core'
import ReactStarRating from 'react-star-ratings-component'
import { Form, Formik } from 'formik'
import { Button } from '~/components/button'
import { observer } from 'mobx-react-lite'
import { showMappingAddress } from '~/common/helpers/mapping.helper'
import { useTranslation } from '@opus/web.core.hooks.use-translation'
import { CREATE_JOB_RATING } from '~/common/apollo'
import { useMutation } from '@apollo/client'
import { careJobDetailStore } from '~/features/care-jobs/detail/care-job-detail.store'
import { useRouteMatch } from 'react-router-dom'
import { modalStyled } from './style'
import { TextField } from '../fields'
import { notifyStore } from '~/stores'
// import { logClickEvent } from '~/common/tracking/event-client.tracking'
// import { COMMON_EVENT_CLICK_TRACKING } from '~/common/constants/tracking.constant'

export const RateDialogComponent = observer(({ workerAssigmentId, jobGeneral, open, setClose, rating = 0, comment = '', viewOnly = false }) => {
	const [ratingJob, { loading }] = useMutation(CREATE_JOB_RATING)
	const [startRate, setStartRate] = React.useState(rating)

	const [showErrorCode, setShowErrorCode] = React.useState(false)
	const { params } = useRouteMatch()
	const { t } = useTranslation()
	const handleOnClose = () => {
		setClose(false)
		setShowErrorCode(false)
		setStartRate(0)
		// logClickEvent(COMMON_EVENT_CLICK_TRACKING.cancelBtn)
	}

	return (
		<>
			<Dialog open={open} onClose={handleOnClose} css={modalStyled}>
				<Box style={{ maxWidth: 450 }} m={2}>
					{!viewOnly && (
						<Grid container className="wrap-title">
							<Grid item xs={12} sm={6}>
								<Typography>Rate this job</Typography>
							</Grid>
						</Grid>
					)}

					<Formik
						initialValues={{ note: comment }}
						onSubmit={async (variables, { setErrors }) => {
							try {
								if (startRate === 0) {
									setShowErrorCode(true)
								} else {
									const { data } = await ratingJob({
										variables: {
											workerAssignmentId: workerAssigmentId,
											comment: variables?.note,
											ratePoint: startRate,
										},
									})

									if (data?.createJobRating?.id) {
										await setClose(false)
										await notifyStore.success('Thanks for sharing your feedback!')
										await setShowErrorCode(false)
										await careJobDetailStore.fetchJob(params.id)
									}
									await setStartRate(0)
									// logClickEvent(COMMON_EVENT_CLICK_TRACKING.reviewRatingBtn)
								}
							} catch (e) {
								notifyStore.error('An error has occurred. Please try again later')
								await setClose(false)
								await careJobDetailStore.fetchJob(params.id)
								return
							}
						}}
					>
						{({ handleSubmit, isSubmitting, values, setValues, resetForm }) => {
							return (
								<Form onSubmit={handleSubmit}>
									<div className="wrap-form">
										<div>
											<Grid
												style={{
													display: 'flex',
													justifyContent: 'center',
													alignItems: 'center',
												}}
												container
											>
												<Grid className={'wrapper-box-info'} item xs={12}>
													<Typography className="job-title">{jobGeneral?.title}</Typography>
													<Typography className="sub-title">{jobGeneral?.account?.name}</Typography>
													<Typography className="sub-title">{showMappingAddress(jobGeneral?.location)}</Typography>
												</Grid>

												{!viewOnly && (
													<Typography
														css={[
															{
																margin: '24px 0',
															},
														]}
													>
														How would you rate your experience in this job
													</Typography>
												)}
												<Grid
													style={{
														display: 'flex',
														justifyContent: 'center',
														margin: viewOnly ? '50px 0px 30px 0px' : '10px 0',
													}}
													item
													xs={12}
												>
													<ReactStarRating
														numberOfStar={5}
														numberOfSelectedStar={rating || 0}
														colorFilledStar="#FF9B04"
														colorEmptyStar="#E6E6E6"
														starSize="48px"
														spaceBetweenStar="10px"
														disableOnSelect={false}
														onSelectStar={(val) => {
															setStartRate(val)
															if (showErrorCode) {
																setShowErrorCode(false)
															}
														}}
													/>
												</Grid>

												{showErrorCode && (
													<FormHelperText style={{ color: '#FB4E4E', fontSize: 12, fontWeight: 500 }}>Please rate your experience</FormHelperText>
												)}

												{!viewOnly ? (
													<Grid item xs={12}>
														<TextField
															placeholder={t('Share your thoughts')}
															name="note"
															mode={'edit'}
															label="Your comment"
															maxLength={200}
															inputProps={{ maxLength: 200 }}
															multiline={true}
														/>

														<Box mt={'5px'} mb={2} color="#868686" textAlign={'right'} fontSize={'12px'} fontWeight={'400'}>
															{values?.note?.length || 0}/200
														</Box>
													</Grid>
												) : (
													<Box width={'100%'} textAlign={'left'}>
														<Typography style={{ marginTop: 12, marginBottom: 12, fontSize: '12px', fontWeight: '500', color: '#0E3F6C' }}>
															Your comment
														</Typography>
														<Typography style={{ fontSize: '16px', fontWeight: '350', color: '#000000', marginBottom: '20px' }}>{comment}</Typography>
													</Box>
												)}
											</Grid>
										</div>
									</div>

									<Grid container spacing={2}>
										<Grid style={{ marginTop: 12, marginBottom: 12 }} item justify="space-between" container>
											<Grid item xs={viewOnly ? 12 : 6}>
												<Button type="button" className={'button-action'} onClick={setClose} color="primary" variant="outlined">
													{t('CANCEL')}
												</Button>
											</Grid>
											{!viewOnly && (
												<Grid style={{ paddingLeft: 4 }} item xs={6}>
													<Button loading={loading} disabled={loading} type="submit">
														Submit
													</Button>
												</Grid>
											)}
										</Grid>
									</Grid>
								</Form>
							)
						}}
					</Formik>
				</Box>
			</Dialog>
		</>
	)
})
