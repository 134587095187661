import { action, computed, event, observable, store } from '~/common/mobx.decorator'
import { appStore, authStore, notifyStore, routerStore } from '~/stores'
import {
	FindSvg,
	HomeSvg,
	MyJobsSvg,
	NotificationSvg,
	ProfileNavSvg,
	SettingsSvg,
	TimecardNavSvg,
	CredentialingSvg,
	ActiveCredentialingSvg,
	ActiveHomeSvg,
	UserPlusSvg,
	ContactSvg,
	ActiveFindSvg,
	ActiveMyJobsSvg,
	ActiveTimecardNavSvg,
	ActiveNotificationSvg,
	ActiveContactSvg,
	ActiveSettingsSvg,
	ActiveReferralSvg,
	ChangePassSvg,
	ActiveChangePassSvg,
	CupSvg,
	ActiveCupSvg,
	PrivacyPolicySvg,
	ActivePrivacyPolicySvg,
} from '~/components/icons'
import { EVENTS, PATHS } from '~/common/constants'
import { apolloClient, GET_WORKER_DETAIL_QUERY, UPDATE_SCHEDULED_DATE, GET_COMPANY_INFO_QUERY, GET_COMPANY_PUBLIC_INFO_QUERY } from '~/common/apollo'
import { eventBus } from 'mobx-event-bus2'
import { previousTabStore } from '~/features/care-timecards/tabs/previous-tab/previous-tab.store'
import { currentTabStore } from '~/features/care-timecards/tabs/current-tab/current-tab.store'

const FEATURE_TYPE = {
	activated: 'activated',
	nonActivated: 'nonActivated',
	public: 'public',
	common: 'common',
}
@store()
class CareStore {
	@observable showProfileSidebar
	@observable customHeader = {}
	@observable worker = {}
	@observable timezone = null //TODO: fetch timezone from API
	@observable companyInfo = {}
	@observable companyConfig = {}

	@action
	setCompanyConfig = async (companyConfig) => {
		this.companyConfig = companyConfig
	}

	@computed
	get privacyPolicyUrl() {
		return this.companyConfig?.privacyPolicyUrl
	}
	@computed
	get termsOfUseUrl() {
		return this.companyConfig?.termUrl
	}

	get referralUrl() {
		return 'https://focus-staff.com/refer-your-friend'
	}

	@computed
	get enableHourlySearch() {
		return appStore.config?.companyConfigs?.enableHourlySearch || false
	}

	@action
	fetchCompanyInfo = async () => {
		const { data } = await apolloClient.query({ query: GET_COMPANY_INFO_QUERY, variables: { workerId: authStore?.id } })

		this.companyInfo = data?.appInit?.company
		this.companyConfig = data?.appInit?.companyConfig
	}

	@action
	fetchPublicInfo = async () => {
		const { data } = await apolloClient.query({ query: GET_COMPANY_PUBLIC_INFO_QUERY, context: { clientName: 'public' } })
		this.companyInfo = data?.company
		this.companyConfig = data?.company?.companyConfig
	}

	@computed
	get facebookUrl() {
		return this.companyConfig?.facebookUrl
	}

	@computed
	get instagramUrl() {
		return this.companyConfig?.instagramUrl
	}

	@computed
	get linkedinUrl() {
		return this.companyConfig?.linkedinUrl
	}

	@computed
	get twitterUrl() {
		return this.companyConfig?.twitterUrl
	}

	@computed
	get youtubeUrl() {
		return this.companyConfig?.youtubeUrl
	}

	@action
	fetchWorkerDetail = async () => {
		const { data } = await apolloClient.query({ query: GET_WORKER_DETAIL_QUERY, variables: { id: authStore.id } })
		this.worker = data.worker
	}

	getFeature = (type) => {
		const activatedMenu = [
			{
				code: 'care-home',
				name: 'HOME',
				path: PATHS.care.home,
				icon: HomeSvg,
				activeIcon: ActiveHomeSvg,
				auth: true,
				showAppBar: true,
				showSideBar: true,
				useLayout: true,
				eventId: 'home-nav_btn',
			},
			{
				code: 'verify-credential',
				name: 'VERIFY_CREDENTIAL',
				path: PATHS.care.verify_credential,
				icon: 'privacy',
				auth: false,
				showAppBar: false,
				showSideBar: false,
				isDisabled: false,
				useBanner: false,
				useLayout: false,
			},
			{
				code: 'care-credentials',
				name: 'CREDENTIALS',
				path: PATHS.care.credential,
				icon: CredentialingSvg,
				activeIcon: ActiveCredentialingSvg,
				auth: true,
				showAppBar: false,
				showSideBar: true,
				useLayout: true,
				eventId: '',
			},

			{
				code: 'care-find-job',
				name: 'SEARCH_JOBS',
				path: PATHS.care.find_job,
				id: 'search-button-module',
				icon: FindSvg,
				activeIcon: ActiveFindSvg,
				auth: false,
				showAppBar: true,
				showSideBar: true,
				isDisabled: false,
				useLayout: true,
			},
			{
				code: 'care-all-jobs',
				name: 'LIST_OF_JOBS',
				path: PATHS.care.all_jobs,
				id: 'all-jobs',
				icon: FindSvg,
				activeIcon: ActiveFindSvg,
				auth: false,
				showAppBar: false,
				showSideBar: false,
				isDisabled: false,
				useLayout: true,
			},
			{
				code: 'care-my-jobs',
				name: 'MYJOBS',
				path: PATHS.care.my_jobs,
				id: 'my-job-button-module',
				icon: MyJobsSvg,
				activeIcon: ActiveMyJobsSvg,
				auth: true,
				showAppBar: true,
				showSideBar: true,
				isDisabled: false,
				useLayout: true,
				eventId: 'my-jobs-nav_btn',
			},
			{
				code: 'care-timecards',
				name: 'TIMECARDS',
				path: PATHS.care.timecards,
				icon: TimecardNavSvg,
				activeIcon: ActiveTimecardNavSvg,
				auth: true,
				showAppBar: authStore?.hasActiveAssignment,
				showSideBar: authStore?.hasActiveAssignment,
				isDisabled: false,
				useLayout: true,
				eventId: 'timecards-nav_btn',
			},
			{
				code: 'care-referral',
				name: 'REFER_TRAVELER',
				icon: UserPlusSvg,
				activeIcon: ActiveReferralSvg,
				path: PATHS.care.refer_traveler,
				auth: true,
				showAppBar: false,
				showSideBar: true,
				isDisabled: false,
				useLayout: true,
			},
			{
				code: 'care-profile',
				name: 'PROFILE',
				path: PATHS.care.profile,
				icon: ProfileNavSvg,
				auth: true,
				showAppBar: false,
				showSideBar: true,
				isDisabled: false,
				useLayout: true,
			},

			{
				code: 'care-notification',
				name: 'NOTIFICATIONS',
				path: PATHS.care.notification,
				icon: NotificationSvg,
				activeIcon: ActiveNotificationSvg,
				auth: true,
				showAppBar: false,
				showSideBar: true,
				isDisabled: false,
				useLayout: true,
			},
			{
				code: 'my-badges',
				name: 'MY_BADGES',
				path: PATHS.care.badges,
				icon: CupSvg,
				activeIcon: ActiveCupSvg,
				auth: true,
				showAppBar: false,
				showSideBar: true,
				isDisabled: false,
				useLayout: true,
			},
			{
				code: 'care-contact-us',
				name: 'CONTACT_US',
				icon: ContactSvg,
				activeIcon: ActiveContactSvg,
				path: PATHS.care.contact_us,
				auth: true,
				showAppBar: false,
				showSideBar: true,
				useLayout: true,
			},
			{
				code: 'care-privacy-policy',
				name: 'PRIVACY_POLICY',
				icon: PrivacyPolicySvg,
				activeIcon: ActivePrivacyPolicySvg,
				path: PATHS.care.privacy_policy,
				auth: true,
				showAppBar: false,
				showSideBar: true,
				useLayout: true,
			},
			{
				code: 'care-popular-cities',
				name: 'POPULAR_CITIES',
				path: PATHS.care.popular_cities,
				icon: MyJobsSvg,
				auth: true,
				showAppBar: false,
				showSideBar: false,
				isDisabled: false,
				useLayout: true,
			},
			{
				code: 'care-suggested-jobs',
				name: 'SUGGESTED_JOBS',
				path: PATHS.care.suggested_jobs,
				icon: MyJobsSvg,
				auth: true,
				showAppBar: false,
				showSideBar: false,
				isDisabled: false,
				useLayout: true,
			},
			{
				code: 'care-hot-jobs',
				name: 'HOT_JOBS',
				path: PATHS.care.hot_jobs,
				icon: MyJobsSvg,
				auth: true,
				showAppBar: false,
				showSideBar: false,
				isDisabled: false,
				useLayout: true,
			},
			{
				code: 'care-jobs',
				name: 'JOBS',
				path: PATHS.care.jobs,
				auth: false,
				showAppBar: false,
				showSideBar: false,
				isDisabled: false,
				useLayout: true,
			},
			{
				code: 'common-change-password',
				name: 'CHANGE_PASSWORD',
				path: PATHS.common.change_password,
				icon: ChangePassSvg,
				activeIcon: ActiveChangePassSvg,
				auth: true,
				showAppBar: false,
				showSideBar: true,
				isDisabled: false,
				useLayout: true,
			},
			{
				code: 'care-settings',
				name: 'SETTINGS',
				path: PATHS.care.settings,
				icon: SettingsSvg,
				activeIcon: ActiveSettingsSvg,
				auth: true,
				showAppBar: false,
				showSideBar: true,
				isDisabled: false,
				useLayout: true,
			},
		].filter(Boolean)

		const nonActivatedMenu = [
			{
				code: 'care-home',
				name: 'HOME',
				path: PATHS.care.home,
				activeIcon: ActiveHomeSvg,
				icon: HomeSvg,
				auth: true,
				showAppBar: true,
				showSideBar: true,
				useLayout: true,
				eventId: 'home-nav_btn',
			},
			{
				code: 'verify-credential',
				name: 'VERIFY_CREDENTIAL',
				path: PATHS.care.verify_credential,
				icon: 'privacy',
				auth: false,
				showAppBar: false,
				showSideBar: false,
				isDisabled: false,
				useBanner: false,
				useLayout: false,
			},
			{
				code: 'care-credentials',
				name: 'CREDENTIALS',
				path: PATHS.care.credential,
				icon: CredentialingSvg,
				activeIcon: ActiveCredentialingSvg,
				auth: true,
				showAppBar: false,
				showSideBar: true,
				useLayout: true,
				eventId: '',
			},
			{
				code: 'care-find-job',
				name: 'SEARCH_JOBS',
				path: PATHS.care.find_job,
				id: 'search-button-module',
				icon: FindSvg,
				activeIcon: ActiveFindSvg,
				auth: false,
				showAppBar: true,
				showSideBar: true,
				isDisabled: false,
				useLayout: true,
			},
			{
				code: 'care-all-jobs',
				name: 'LIST_OF_JOBS',
				path: PATHS.care.all_jobs,
				id: 'all-jobs',
				icon: FindSvg,
				activeIcon: ActiveFindSvg,
				auth: false,
				showAppBar: false,
				showSideBar: false,
				isDisabled: false,
				useLayout: true,
			},
			{
				code: 'care-my-jobs',
				name: 'MYJOBS',
				path: PATHS.care.my_jobs,
				id: 'my-job-button-module',
				icon: MyJobsSvg,
				activeIcon: ActiveMyJobsSvg,
				auth: true,
				showAppBar: true,
				showSideBar: true,
				isDisabled: false,
				useLayout: true,
			},
			{
				code: 'care-timecards',
				name: 'TIMECARDS',
				path: PATHS.care.timecards,
				icon: TimecardNavSvg,
				activeIcon: ActiveTimecardNavSvg,
				auth: true,
				showAppBar: authStore?.hasActiveAssignment,
				showSideBar: authStore?.hasActiveAssignment,
				isDisabled: false,
				useLayout: true,
				eventId: 'timecards-nav_btn',
			},
			{
				code: 'care-referral',
				name: 'REFER_TRAVELER',
				icon: UserPlusSvg,
				activeIcon: ActiveReferralSvg,
				path: PATHS.care.refer_traveler,
				auth: true,
				showAppBar: false,
				showSideBar: true,
				isDisabled: false,
				useLayout: true,
			},
			{
				code: 'care-activation',
				name: 'PROFILE',
				path: PATHS.care.activation,
				icon: ProfileNavSvg,
				auth: true,
				showAppBar: false,
				showSideBar: true,
				isDisabled: false,
				useLayout: true,
				eventId: 'application-nav_btn',
			},
			{
				code: 'care-notification',
				name: 'NOTIFICATIONS',
				path: PATHS.care.notification,
				icon: NotificationSvg,
				activeIcon: ActiveNotificationSvg,
				auth: true,
				showAppBar: false,
				showSideBar: true,
				isDisabled: false,
				useLayout: true,
			},
			{
				code: 'my-badges',
				name: 'MY_BADGES',
				path: PATHS.care.badges,
				icon: CupSvg,
				auth: true,
				showAppBar: false,
				showSideBar: true,
				isDisabled: false,
				useLayout: true,
			},
			{
				code: 'care-contact-us',
				name: 'CONTACT_US',
				icon: ContactSvg,
				activeIcon: ActiveContactSvg,
				path: PATHS.care.contact_us,
				auth: true,
				showAppBar: false,
				showSideBar: true,
				useLayout: true,
			},
			{
				code: 'care-privacy-policy',
				name: 'PRIVACY_POLICY',
				icon: PrivacyPolicySvg,
				activeIcon: ActivePrivacyPolicySvg,
				path: PATHS.care.privacy_policy,
				auth: true,
				showAppBar: false,
				showSideBar: true,
				useLayout: true,
			},
			{
				code: 'common-change-password',
				name: 'CHANGE_PASSWORD',
				path: PATHS.common.change_password,
				icon: ChangePassSvg,
				activeIcon: ActiveChangePassSvg,
				auth: true,
				showAppBar: false,
				showSideBar: true,
				isDisabled: false,
				useLayout: true,
			},
			{
				code: 'care-settings',
				name: 'SETTINGS',
				path: PATHS.care.settings,
				icon: SettingsSvg,
				activeIcon: ActiveSettingsSvg,
				auth: true,
				showAppBar: false,
				showSideBar: true,
				isDisabled: false,
				useLayout: true,
			},
			{
				code: 'care-popular-cities',
				name: 'POPULAR_CITIES',
				path: PATHS.care.popular_cities,
				icon: MyJobsSvg,
				auth: true,
				showAppBar: false,
				showSideBar: false,
				isDisabled: false,
				useLayout: true,
			},
			{
				code: 'care-suggested-jobs',
				name: 'SUGGESTED_JOBS',
				path: PATHS.care.suggested_jobs,
				icon: MyJobsSvg,
				auth: true,
				showAppBar: false,
				showSideBar: false,
				isDisabled: false,
				useLayout: true,
			},
			{
				code: 'care-hot-jobs',
				name: 'HOT_JOBS',
				path: PATHS.care.hot_jobs,
				icon: MyJobsSvg,
				auth: true,
				showAppBar: false,
				showSideBar: false,
				isDisabled: false,
				useLayout: true,
			},
			{
				code: 'care-jobs',
				name: 'JOBS',
				path: PATHS.care.jobs,
				auth: false,
				showAppBar: false,
				showSideBar: false,
				isDisabled: false,
				useLayout: true,
			},
		]

		const publicMenu = [
			{
				code: 'common-change-password',
				name: 'CHANGE_PASSWORD',
				path: PATHS.common.change_password,
				icon: ChangePassSvg,
				auth: true,
				showAppBar: false,
				showSideBar: false,
				isDisabled: false,
				useLayout: true,
			},
			{
				code: 'verify-credential',
				name: 'VERIFY_CREDENTIAL',
				path: PATHS.care.verify_credential,
				icon: 'privacy',
				auth: false,
				showAppBar: false,
				showSideBar: false,
				isDisabled: false,
				useBanner: false,
				useLayout: false,
			},
			{
				code: 'care-job-matches',
				name: 'JOB_MATCHES',
				path: PATHS.care.job_matches,
				icon: MyJobsSvg,
				auth: true,
				showAppBar: false,
				showSideBar: false,
				isDisabled: false,
				useLayout: true,
			},
			{
				code: 'care-jobs',
				name: 'JOBS',
				path: PATHS.care.jobs,
				auth: false,
				showAppBar: false,
				showSideBar: false,
				isDisabled: false,
				useLayout: true,
			},
			{
				code: 'licenses-certifications',
				name: 'LICENSES_CERTIFICATIONS',
				path: PATHS.care.licenses_certifications,
				auth: true,
				showAppBar: false,
				showSideBar: false,
				isDisabled: false,
				useLayout: true,
			},
			{
				code: 'skills-checklist',
				name: 'SKILLS_CHECKLIST',
				path: PATHS.care.skills_checklist,
				auth: true,
				showAppBar: false,
				showSideBar: false,
				isDisabled: false,
				useLayout: true,
			},
		].filter(Boolean)

		const common = [
			{
				code: 'common-signin',
				name: 'SIGNIN',
				path: PATHS.common.signin,
				icon: 'user',
				auth: false,
				showAppBar: false,
				showSideBar: false,
				useLayout: false,
			},
			{
				code: 'common-reset',
				name: 'RESET',
				path: PATHS.common.reset,
				icon: 'user',
				auth: false,
				showAppBar: false,
				showSideBar: false,
				isDisabled: false,
				useLayout: false,
			},
			{
				code: 'care-signup',
				name: 'SIGNUP',
				path: PATHS.common.signup,
				icon: 'user',
				auth: false,
				showAppBar: false,
				showSideBar: false,
				isDisabled: false,
				useLayout: false,
			},
			{
				code: 'common-change-password',
				name: 'REFER_TRAVELER',
				path: PATHS.common.change_password,
				icon: 'user',
				auth: false,
				showAppBar: false,
				showSideBar: false,
				isDisabled: false,
				useLayout: false,
			},
			{
				code: 'common-errors',
				name: 'ERRORS',
				path: PATHS.common.errors,
				icon: 'user',
				auth: false,
				showAppBar: false,
				showSideBar: false,
				isDisabled: false,
				useLayout: false,
			},
		].filter(Boolean)

		let result = null
		switch (type) {
			case FEATURE_TYPE.activated:
				result = activatedMenu
				break
			case FEATURE_TYPE.nonActivated:
				result = nonActivatedMenu
				break
			case FEATURE_TYPE.public:
				result = publicMenu
				break
			case FEATURE_TYPE.common:
				result = common
				break
			default:
				result = []
				break
		}
		return result
	}

	@computed
	get features() {
		const publicMenu = this.getFeature(FEATURE_TYPE.public)
		const activatedMenu = this.getFeature(FEATURE_TYPE.activated)
		const nonActivatedMenu = this.getFeature(FEATURE_TYPE.nonActivated)

		return authStore.activated ? [...activatedMenu, ...publicMenu] : [...nonActivatedMenu, ...publicMenu]
	}

	@computed
	get allFeatures() {
		const common = this.getFeature(FEATURE_TYPE.common)
		const allFeature = [...this.features, ...common]

		return allFeature?.find((feature) => feature.path === routerStore.basePath)
	}

	@computed
	get activeFeature() {
		return this.features?.find((feature) => feature.path === routerStore.basePath)
	}

	@computed
	get code() {
		return this.activeFeature?.code
	}

	@computed
	get name() {
		return this.activeFeature?.name || ''
	}

	@computed
	get useLayout() {
		return this.activeFeature?.useLayout
	}

	@computed
	get sidebarMenu() {
		return this.features?.filter((feature) => feature.showSideBar)
	}

	@computed
	get appbarMenu() {
		return this.features?.filter((feature) => feature.showAppBar)
	}

	@computed
	get primarySpecialty() {
		return this.worker?.workExperienceOverview?.primarySpecialty
	}

	@computed
	get secondarySpecialty() {
		return this.worker?.workExperienceOverview?.secondarySpecialty
	}

	@action
	toogleProfileSidebar = async () => {
		this.showProfileSidebar = !this.showProfileSidebar
	}

	@action
	updateLastDayScheduled = async (date, id) => {
		await apolloClient.mutate({
			mutation: UPDATE_SCHEDULED_DATE,
			variables: { lastDayScheduled: date, id },
		})
	}

	@action
	logout = () => {
		this.showProfileSidebar = false
		notifyStore.unInitOneSignal()
		eventBus.post(EVENTS.authStore.logout)
		previousTabStore.resetSelectedWorkerAssignment()
		currentTabStore.resetSelectedWorkerAssignment()
		routerStore.goPage(PATHS.common.signin)
	}

	@action
	handleClickChangePassword = async () => {
		this.showProfileSidebar = false
		await routerStore.goPage(PATHS.common.change_password)
	}

	@event(EVENTS.careStore.updateHeader)
	async onUpdateHeader({ payload }) {
		this.customHeader = payload
	}

	redirectToHomePage = () => {
		return routerStore.goPage(PATHS.common.home)
	}
}

export const careStore = new CareStore()
