import gql from 'graphql-tag'

export const CREATE_JOB_RATING = gql`
	mutation createJobRating($ratePoint: Float, $comment: String, $workerAssignmentId: String!) {
		createJobRating(ratePoint: $ratePoint, comment: $comment, workerAssignmentId: $workerAssignmentId) {
			comment
			createdAt
			id
			ratePoint
		}
	}
`
