import React from 'react'
export const FEATURES = {
	// HOST HEALTHCARE FEATURES
	'care-home': React.lazy(() => import('~/features/care-home')),
	'care-activation': React.lazy(() => import('~/features/care-activation')),
	'care-timecards': React.lazy(() => import('~/features/care-timecards')),
	'care-signup': React.lazy(() => import('~/features/care-signup')),
	'care-profile': React.lazy(() => import('~/features/care-profile')),
	'care-popular-cities': React.lazy(() => import('~/features/care-popular-cities')),
	'care-find-job': React.lazy(() => import('~/features/care-find-job')),
	'care-suggested-jobs': React.lazy(() => import('~/features/care-suggested-jobs')),
	'care-hot-jobs': React.lazy(() => import('~/features/care-hot-jobs')),
	'care-job-matches': React.lazy(() => import('~/features/care-job-matches')),
	'care-my-jobs': React.lazy(() => import('~/features/care-my-jobs')),
	'care-jobs': React.lazy(() => import('~/features/care-jobs')),
	// 'care-referral': React.lazy(() => import('~/features/care-referral')),
	'care-contact-us': React.lazy(() => import('~/features/care-contact-us')),
	'care-settings': React.lazy(() => import('~/features/care-settings')),
	'care-notification': React.lazy(() => import('~/features/care-notification')),
	'care-compensation': React.lazy(() => import('~/features/care-compensation')),
	'care-credentials': React.lazy(() => import('~/features/care-credentials')),
	'care-all-jobs': React.lazy(() => import('~/features/care-all-jobs')),
	'my-badges': React.lazy(() => import('~/features/my-badges')),
	'licenses-certifications': React.lazy(() => import('~/features/licenses-certifications')),
	'skills-checklist': React.lazy(() => import('~/features/skills-checklists')),
	'verify-credential': React.lazy(() => import('~/features/care-verify-credential')),
	'care-privacy-policy': React.lazy(() => import('~/features/care-privacy-policy')),

	// COMMON FEATURES
	'common-maintenance': React.lazy(() => import('~/features/common-maintenance')),
	'common-signin': React.lazy(() => import('~/features/common-signin')),
	'common-errors': React.lazy(() => import('~/features/common-errors')),
	'common-reset': React.lazy(() => import('~/features/common-reset')),
	'common-change-password': React.lazy(() => import('~/features/common-change-password')),
	'common-link-account': React.lazy(() => import('~/features/common-link-account')),
	'common-link-options': React.lazy(() => import('~/features/common-link-options')),
}
