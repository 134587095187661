import React from 'react'
import { Redirect, Route as BaseRoute, Switch, useLocation } from 'react-router-dom'
import { observer } from '~/common/mobx.decorator'
import { Route } from './components/route'
import { FEATURES } from '~/features'
import { COMPANIES } from '~/companies'
import { PATHS } from './common/constants'
import { Tracking } from './tracking'
import { careStore } from '~/companies/care/care.store'
import { eventClient } from '@opus/web.core.lib.event-tracking'
import { isEmpty } from 'lodash'
import { LinkedInCallback } from 'react-linkedin-login-oauth2'
import { generateUUID } from '~/common/helpers/uid.helper'

export const AppRoute = observer(() => {
	let location = useLocation()

	React.useEffect(() => {
		const code = careStore.allFeatures?.code

		if (!isEmpty(code)) {
			eventClient.logScreenEvent(code)
			eventClient.setCurrentComponentName(code)
			eventClient.setCurrentUIKey(generateUUID())
		} else {
			eventClient.setCurrentComponentName('')
			eventClient.setCurrentUIKey('')
		}
	}, [location])

	return (
		<Switch>
			<BaseRoute
				exact
				sensitive
				path="/:url([a-z/]*[A-Z]+[a-z/]*)/"
				render={(props) => <Redirect to={{ pathname: `${props.location.pathname}`.toLowerCase() }} />}
			/>
			<Route path={PATHS.common.maintenance} component={FEATURES['common-maintenance']} />
			<Route path={PATHS.common.tracking} component={Tracking} />
			<Route path={PATHS.common.errors} component={FEATURES['common-errors']} />
			<Route path={PATHS.common.signin} component={FEATURES['common-signin']} />
			<Route path={PATHS.common.reset} component={FEATURES['common-reset']} />
			<Route path={PATHS.care.signup} component={FEATURES['care-signup']} />
			<Route path={PATHS.common.link_account} component={FEATURES['common-link-account']} />
			<Route path={PATHS.common.link_options} component={FEATURES['common-link-options']} />
			<Route exact path={PATHS.auth.linkedin} component={LinkedInCallback} />
			{/* <Route auth={true} path={PATHS.common.change_password} component={FEATURES['common-change-password']} /> */}
			<Route path={PATHS.common.home} component={COMPANIES.care} />
		</Switch>
	)
})
