import gql from 'graphql-tag'
import { LOCATION_FRAGMENT } from '~/common/apollo/apollo.fragment'

export const SEARCH_JOB_QUERY = gql`
	query searchJobQuery(
		$companyId: String!
		$filter: JobFilter
		$keywordSearch: String
		$nearBySearch: NearByJobSearch
		$sortBy: JobManagementSort
		$token: String
		$limit: Int
		$offset: Int
	) {
		jobSearch(
			companyId: $companyId
			filter: $filter
			keywordSearch: $keywordSearch
			nearBySearch: $nearBySearch
			sortBy: $sortBy
			token: $token
			limit: $limit
			offset: $offset
		) {
			totalCount
			jobs {
				id
				title
				description
				discipline
				hotJob
				specialty
				duration
				distance
				isFavorited
				hourlyPayHigh
				hourlyPayLow
				stipends
				taxableHigh
				taxableLow
				activeAssignment {
					id
					startDate
					endDate
				}
				location {
					...locationFragment
				}
				jobApplicant {
					id
					status
					workerAssignment {
						id
						startDate
						endDate
						extendAssignment {
							id
							status
						}
					}
				}
				locationImage
				matchingPercentage
				weeklyPayAmount
				minWeeklyPayAmount
				startDate
				endDate
				createdDate
				status
				shifts
				account {
					name
				}
			}
		}
	}
	${LOCATION_FRAGMENT}
`
