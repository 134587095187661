import { isEmpty, isEqual, isNil, omit, omitBy, sortBy, unionBy } from 'lodash'

export const hasTimecardData = (obj) => {
	const newObj = omit(obj, ['workerId', 'id', 'endDate', 'startDate'])
	for (const key in newObj) {
		const data = obj[key]
		if (typeof data === 'undefined') {
			continue
		}

		if (typeof data === 'object' && data !== null && data.length !== 0) {
			return true
		}
		if (typeof data === 'boolean' && data === true) {
			return true
		}
		if ((typeof data === 'number' || typeof data === 'string') && !isEmpty(data.toString())) {
			return true
		}
	}

	return false // If no key has data, return false
}

export const compareActivationData = (values, initialValues) => {
	const changed = Object.keys(values).filter((field) => {
		let data = values[field]
		let initData = initialValues[field]
		if (field === 'workerCertifications') {
			data = sortBy(data, 'id')
			initData = sortBy(initData, 'id')
		}

		if (field === 'workingPreferredLocations') {
			data = data.filter((item) => item?.preferredWorkingState !== '')
			initData = initData.filter((item) => item?.preferredWorkingState !== '')
		}
		return !isEqual(omitBy(data, isNil), omitBy(initData, isNil))
	})
	return changed?.length > 0
}

export const combineRealAndStoragedData = (arr1, arr2) => {
	if (arr1?.length === 0) {
		return arr2
	}
	const mergedArray = unionBy(arr2, arr1, 'id')

	return mergedArray
}
