import gql from 'graphql-tag'

export const MARK_ALL_NOTIFICATIONS = gql`
	mutation markAllNotificationAsRead {
		markAllNotificationsStatus {
			success
		}
	}
`

export const DELETE_ALL_NOTIFICATIONS = gql`
	mutation deleteAllNotificationRead($notificationIds: [String!]) {
		destroyNotifications(notificationIds: $notificationIds) {
			success
		}
	}
`

export const GET_NOTIFICATION_LIST = gql`
	query getAllNotifications($first: Int, $last: Int, $after: String) {
		notifications(first: $first, last: $last, after: $after) {
			nodes {
				id
				heading
				message
				status
				createdAt
				updatedAt
				url
				webUrl
				dataType
				dataId
				dataEvent
			}
			totalCount
			totalRead
			totalUnread
			pageInfo {
				hasNextPage
				hasPreviousPage
				endCursor
			}
		}
	}
`

export const MARK_NOTIFICATION_STATUS = gql`
	mutation markNotificationsStatusMutation($notificationIds: [String!]!, $status: NotificationStatusEnum) {
		markNotificationsStatus(notificationIds: $notificationIds, status: $status) {
			success
		}
	}
`
