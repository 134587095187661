import { Autocomplete } from '@material-ui/lab'
import { useField, useFormikContext } from 'formik'
import { filter, find, get, includes, map } from 'lodash'
import React, { useCallback, useMemo, useState } from 'react'
import { MdExpandMore } from 'react-icons/md'
import { useDebounce, usePrevious } from 'react-use'
import { FIELD_MODE } from '~/common/constants'
import { useFieldError, useFieldFocused } from '~/common/hooks'
import { useTranslation } from '@opus/web.core.hooks.use-translation'
import { LabelField } from '@opus/web.core.form.label-field'
import { TextField } from '../text-field/text-field.component'

export const AutoCompleteField = ({
	name,
	validate,
	label,
	placeholder,
	disabled,
	options,
	freeSolo,
	allowAddValue,
	mode,
	parentName,
	multiple,
	disableClearable,
	onChange,
}) => {
	const { t } = useTranslation()
	const { values } = useFormikContext()
	const [field, meta] = useField({ name, validate: mode === FIELD_MODE.edit && validate })

	const [inputValue, updateInputValue] = useState(field.value)
	const [focused, focusProps] = useFieldFocused(field)

	const error = useFieldError(meta)

	const handleChange = useCallback(
		(_, option) => {
			updateInputValue(multiple ? option?.map((opt) => opt.value) : option?.value)
			onChange && onChange(option)
		},
		// eslint-disable-next-line
		[updateInputValue, multiple]
	)

	useDebounce(
		() => {
			if (field.value !== inputValue && !focused) {
				updateInputValue(field.value || (multiple ? [] : ''))
			}
		},
		300,
		[field.value]
	)

	useDebounce(
		() => {
			field.onChange({ target: { name, value: inputValue || '' } })
		},
		300,
		[inputValue]
	)

	const parentValue = useMemo(() => get(values, parentName), [values, parentName])

	const finalOptions = useMemo(() => {
		const items = parentName ? options?.filter((option) => option.parentValue === parentValue) : options

		return map(items, (option) => (option.label ? option : { ...option, label: option.value }))
	}, [options, parentName, parentValue])

	const selectedOption = useMemo(() => {
		if (multiple) {
			return filter(finalOptions, (option) => includes(field.value, option.value)) || []
		}

		return find(finalOptions, (option) => option.value === field.value) || (freeSolo && inputValue ? { value: inputValue, label: inputValue } : null)
	}, [finalOptions, field.value, multiple, freeSolo, inputValue])

	const prevParentValue = usePrevious(parentValue)

	useDebounce(
		() => {
			if (mode === FIELD_MODE.edit && parentName && prevParentValue !== parentValue) {
				field.onChange({ target: { name, value: null } })
			}
		},
		300,
		[parentValue, prevParentValue]
	)

	const viewValue = useMemo(() => (multiple ? selectedOption?.map((opt) => opt.label)?.join(', ') : selectedOption?.label), [multiple, selectedOption])

	if (mode === FIELD_MODE.view) {
		return <LabelField label={t(label)} displayValueFormat={() => viewValue || field.value} />
	}
	if (mode === FIELD_MODE.viewDisable) {
		return <LabelField label={t(label)} disabled displayValueFormat={() => viewValue || field.value} />
	}

	return (
		<Autocomplete
			id={name}
			freeSolo={freeSolo}
			options={finalOptions}
			getOptionLabel={(option) => option.label}
			getOptionSelected={(option, value) => option.value === value?.value}
			selectOnFocus
			filterOptions={(options, params) => {
				if (!params.inputValue) {
					return options
				}

				const filtered = filter(options, (option) => option?.label?.toLowerCase()?.startsWith(params?.inputValue?.toLowerCase()))

				// Suggest the creation of a new value
				if (params.inputValue !== '' && allowAddValue) {
					filtered.push({
						value: params.inputValue,
						label: params.inputValue,
						isNew: true,
					})
				}

				return filtered
			}}
			fullWidth
			multiple={multiple}
			{...field}
			{...focusProps}
			disabled={disabled}
			disableClearable={disableClearable}
			value={selectedOption}
			onChange={handleChange}
			popupIcon={<MdExpandMore />}
			renderInput={(params) => (
				<TextField
					{...params}
					name={name}
					label={t(label)}
					disabled={disabled}
					placeholder={placeholder && t(placeholder)}
					error={!!error}
					helperText={error}
				/>
			)}
		/>
	)
}

AutoCompleteField.defaultProps = {
	freeSolo: false,
	allowAddValue: false,
	options: [],
	mode: FIELD_MODE.edit,
	multiple: false,
}
