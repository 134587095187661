import React, { useMemo } from 'react'
import { useField, useFormikContext } from 'formik'
import { MenuItem, SvgIcon, TextField as MuiTextField } from '@material-ui/core'
import { selectFieldStyle } from './select-field.style'
import { useFieldError } from '~/common/hooks'
import { useTranslation } from '@opus/web.core.hooks.use-translation'
import { MdExpandMore } from 'react-icons/md'
import { useIsMobile } from '~/common/hooks'
import { filter, get } from 'lodash'
import { useDebounce } from 'react-use'
import { FIELD_MODE } from '~/common/constants'
import { LabelField } from '@opus/web.core.form.label-field'

export const SelectField = ({ name, validate, options, label, placeholder, parentName, multiple, mode, ...rest }) => {
	const { t } = useTranslation()
	const { values } = useFormikContext()
	const [field, meta] = useField({ name, validate: mode === FIELD_MODE.edit && validate })
	const isMobile = useIsMobile()
	const OptionComponent = useMemo(() => (isMobile ? 'option' : MenuItem), [isMobile])

	const parentValue = useMemo(() => parentName && get(values, parentName), [values, parentName])

	const finalOptions = useMemo(() => {
		if (!parentName) {
			return options
		}

		return filter(options, (option) => (!parentValue ? !option.parentValue : parentValue === option.parentValue)) || []
	}, [parentName, parentValue, options])

	const disabled = useMemo(() => parentName && finalOptions.length === 0, [parentName, finalOptions])

	const error = useFieldError(meta)

	useDebounce(
		() => {
			if (parentName && field.value && finalOptions?.length === 0) {
				field.onChange({ target: { name, value: null } })
			}
		},
		2000,
		[parentValue]
	)

	const fieldValue = useMemo(() => field.value || (multiple ? [] : ''), [field.value, multiple])

	const selectedOption = useMemo(() => finalOptions?.find((option) => option.value === fieldValue), [fieldValue, finalOptions])

	if (mode === FIELD_MODE.view) {
		return <LabelField disabled label={t(label)} displayValueFormat={() => (multiple ? fieldValue?.join(', ') : selectedOption?.label)} />
	}

	return (
		<MuiTextField
			id={name}
			select
			label={t(label)}
			placeholder={placeholder && t(placeholder)}
			helperText={error}
			error={!!error}
			css={selectFieldStyle}
			disabled={disabled}
			multiline={multiple}
			SelectProps={{
				IconComponent: (iconProps) => <SvgIcon {...iconProps} component={MdExpandMore} />,
				native: isMobile,
				multiple,
			}}
			InputLabelProps={{
				shrink: !!field.value || (multiple && isMobile),
			}}
			{...field}
			{...rest}
			value={fieldValue}
		>
			{isMobile && !multiple && <OptionComponent aria-label="None" value="" disabled />}
			{finalOptions.length === 0 && <OptionComponent aria-label="Temp" value={field.value} label={field.value} disabled />}
			{finalOptions?.map((option) => (
				<OptionComponent key={option.value} value={option.value}>
					{option.keepOriginLabel ? option.label : t(option.label)}
				</OptionComponent>
			))}
		</MuiTextField>
	)
}

SelectField.defaultProps = {
	options: [],
	mode: FIELD_MODE.edit,
}
