import { eventClient, events } from '@opus/web.core.lib.event-tracking'
import axios from 'axios'
import { omit } from 'lodash'
import {
	apolloClient,
	GET_CURRENT_WORKER_ASSIGNMENT_QUERY,
	GET_WORKER_SKILL_CHECKLISTS_COUNT_QUERY,
	SIGNOUT_WORKER_MUTATION,
	VERIFY_WORKER_QUERY,
	GET_WORKER_COMPENSATION_QUERY,
} from '~/common/apollo'
import { EVENTS, ONE_DAY } from '~/common/constants'
import { captureException } from '~/common/helpers'
import { action, computed, event, observable, persist, store } from '~/common/mobx.decorator'
import { notifyStore } from './notify.store'
import { getUserTrackingBasicInfor } from '~/common/tracking/event-client.tracking'
import { careFindJobStore } from '~/features/care-find-job/care-find-job.store'

const matchAnonymousEvents = async () => {
	// ONLY CALL THIS FUNCTION AFTER setUserId & setUserProperties
	try {
		const anonymousEvents = await window.host.getUserEvents()
		const anonymousId = await window.host.getId()

		const searchEvents = anonymousEvents
			.filter((event) => event.name === 'ANONYMOUS_SEARCH')
			.map(
				(event) =>
					new events.AnonymousSearchSuccessEvent({
						...event.data,
						metadata: {
							ip: event.ip,
							user_agent: event.userAgent,
							anonymous_id: anonymousId,
						},
					})
			)

		searchEvents.forEach((event) => eventClient.logEvent(event))
		await window.host.archiveUserEvents()
		// IN CASE: You want to clean up all archived events
		// Please call: window.host.cleanUserEvents()
	} catch (error) {
		console.debug(error)
	}
}

class ProfileItem {
	@persist @observable id
	@persist @observable firstName
	@persist @observable lastName
	@persist @observable workAuthorized
	@persist @observable workerEmail
}

@store({ persist: true })
class AuthStore {
	@persist @observable token = ''
	@persist @observable maintenanceMessage = ''
	@persist @observable expiresAt = 0
	@persist('object', ProfileItem) @observable profile

	@observable currentJob = null
	@observable notificationChannel = ''
	@observable workerSkillChecklistsCount = null
	@observable showCompensation = null
	@observable lastJobId = null
	@observable showDialog = false

	@observable attempt = 0
	@observable logoutSuccess = false

	@computed
	get recruiter() {
		return this.profile?.recruiter
	}

	@computed
	get authorization() {
		return !this.token || Date.now() > this.expiresAt ? '' : this.token
	}

	@computed
	get features() {
		const defaultFeatures = [
			'care-home',
			'care-activation',
			'care-profile',
			'care-popular-cities',
			'care-find-job',
			'care-all-jobs',
			'care-suggested-jobs',
			'care-hot-jobs',
			'care-job-matches',
			'care-my-jobs',
			'care-jobs',
			'care-referral',
			'care-settings',
			'care-notification',
			'my-badges',
			'licenses-certifications',
			'skills-checklist',
			'common-change-password',
			'care-contact-us',
			'care-privacy-policy',
			'care-credentials',
			'verify-credential',
		]

		if (this?.hasActiveAssignment) {
			defaultFeatures.push('care-timecards')
		}

		return defaultFeatures
	}

	@computed
	get id() {
		return this.authorization && this.profile?.id
	}

	@computed
	get firstName() {
		return this.profile?.firstName
	}

	@computed
	get workerEmail() {
		return this.profile?.email
	}

	@computed
	get lastName() {
		return this.profile?.lastName
	}

	@computed
	get fullName() {
		return [this.firstName, this.lastName].join(' ').trim()
	}

	@computed
	get activated() {
		return this.profile?.workAuthorized
	}

	@computed
	get hasWorkerAssignments() {
		return this.profile?.hasWorkerAssignments || false
	}

	@computed
	get hasActiveAssignment() {
		return this.profile?.hasActiveAssignment || false
	}

	@action
	setMaintenanceMessage = (message) => {
		this.maintenanceMessage = message
	}

	@action
	updateNotificationChannel = (notificationChannel) => {
		this.notificationChannel = notificationChannel
	}

	@action
	changeActiveTabIndex = (tabIndex) => {
		if (this.activeTabIndex === tabIndex) {
			return
		}

		this.prevActiveTabIndex = -1
		this.activeTabIndex = tabIndex
	}

	@action
	fetchWorkerCompensation = async () => {
		try {
			const response = await apolloClient.query({ query: GET_WORKER_COMPENSATION_QUERY })
			this.showCompensation = response.data?.workerCompensation?.id || false
		} catch (error) {
			captureException('worker compensation', error)
		}
	}

	@action
	changeProfile = async (profile) => {
		if (this.profile && profile && this.profile?.id === profile?.id && profile.workAuthorized === true && this.activated !== profile?.workAuthorized) {
			eventClient.logEvent(new events.OnboardingWorkerAuthorizedSuccessEvent())
		}

		this.profile = profile

		if (profile) {
			eventClient.setUserId(profile.id)
			eventClient.setUserProperties({
				id: profile.id,
				worker_id: authStore.id,
				first_name: null,
				last_name: null,
				company_id: process.env.REACT_APP_COMPANY_ID,
				work_authorized: profile.workAuthorized,
			})

			getUserTrackingBasicInfor().then((response) => {
				eventClient.setBasicInfo({
					...response,
					userProperties: {
						id: profile.id,
						worker_id: authStore.id,
						first_name: null,
						last_name: null,
						company_id: process.env.REACT_APP_COMPANY_ID,
						work_authorized: profile.workAuthorized,
					},
				})
			})

			// ONLY CALL THIS FUNCTION AFTER setUserId & setUserProperties
			await matchAnonymousEvents()
			// if (authStore.lastJobId) {
			// 	await routerStore.goPage(PATHS.care.job_detail, { id: authStore.lastJobId })
			// }
		}

		// notifyStore.signal.push(() => {
		// 	notifyStore.signal.sendTags(profile)
		// 	notifyStore.signal.setExternalUserId(profile?.id)
		//})

		await careFindJobStore.resetFilter()

		await notifyStore.sendInfo()
	}

	@action
	changeToken = async (token = '', duration = ONE_DAY * 30) => {
		this.token = token
		this.expiresAt = token ? Date.now() + duration : -1

		if (token) {
			axios.defaults.headers.common['Auth-Token'] = token
		} else {
			delete axios.defaults.headers.common['Auth-Token']
		}

		if (!token) {
			notifyStore.signal.push(() => {
				notifyStore.signal.removeExternalUserId()
			})
		}
	}

	@action
	verifyToken = async () => {
		if (!this.authorization) {
			await this.changeToken('')
			return
		}

		try {
			const {
				data: { workerToken },
			} = await apolloClient.query({ query: VERIFY_WORKER_QUERY, variables: { token: this.authorization } })
			const { token, worker, notificationChannel } = workerToken

			this.profile = worker
			this.updateNotificationChannel(notificationChannel)
			await this.changeToken(token)
			await this.changeProfile(worker)

			return worker
		} catch (error) {
			captureException('Verify token', error)
			await this.changeToken('')
		}
	}

	@event(EVENTS.authStore.logout)
	async logout() {
		const bkToken = this.token
		await this.changeToken('')
		eventClient.setUserId(null)
		eventClient.setUserProperties(null)
		getUserTrackingBasicInfor().then((response) => {
			eventClient.setBasicInfo(omit(response, ['userProperties']))
		})

		await authStore.setLastJobId(null)
		const maxAttempts = 3

		while (this.attempt < maxAttempts && !this.logoutSuccess) {
			try {
				const { data } = await apolloClient.mutate({
					mutation: SIGNOUT_WORKER_MUTATION,
					context: {
						headers: {
							'Auth-Token': bkToken,
						},
					},
				})
				this.logoutSuccess = data?.signOutWorker?.success
			} catch (error) {
				this.attempt = this.attempt + 1
				console.error(`Logout attempt ${this.attempt} failed`, error)
				if (this.attempt === maxAttempts) {
					captureException('Failed to logout after many attempts', error)
				}
			}
		}
	}

	@event('authStore/changeToken.success')
	async fetchCurrentJob({ payload }) {
		if (payload?.payload) {
			const response = await apolloClient.query({ query: GET_CURRENT_WORKER_ASSIGNMENT_QUERY })
			this.currentJob = response?.data?.currentWorkerAssignment
		}
	}

	@action
	setLastJobId = async (jobId) => {
		this.lastJobId = jobId
		return null
	}

	@action
	setShowDialog = async (value) => {
		this.showDialog = value
	}

	@action
	fetchWorkerSkillChecklistsCount = async () => {
		const response = await apolloClient.query({
			query: GET_WORKER_SKILL_CHECKLISTS_COUNT_QUERY,
			variables: { id: this.id },
		})
		this.workerSkillChecklistsCount = response?.data?.worker?.workerSkillChecklistsCount

		return this.workerSkillChecklistsCount
	}

	@action
	setPhoneVerifyAt = (value) => {
		this.phoneVerifyAt = value
	}
}

export const authStore = new AuthStore()
