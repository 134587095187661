import { gql } from '@apollo/client'
import { JOB_FRAGMENT, WORKED_TIMESHEET_FRAGMENT, WORKER_FRAGMENT_SHORT } from '../apollo.fragment'

export const GET_CURRENT_WORKER_ASSIGNMENT_QUERY = gql`
	query getCurrentWorkerAssignmentQuery($scope: ActiveAssignmentFilterEnum) {
		currentWorkerAssignment(scope: $scope) {
			id
		}
	}
`

// export const GET_WORKER_CURRENT_JOB_QUERY = gql`
// 	query getWorkerCurrentJobQuery {
// 		currentWorkerAssignment {
// 			id
// 			status
// 			createdAt
// 			updatedAt
// 			startDate
// 			endDate
// 			job {
// 				...jobFragment
// 			}
// 			worker {
// 				...workerFragment
// 			}
// 			workedTimesheets {
// 				...workedTimesheetFragment
// 			}
// 		}
// 	}

// 	${JOB_FRAGMENT}
// 	${WORKER_FRAGMENT_SHORT}
// 	${WORKED_TIMESHEET_FRAGMENT}
// `

export const GET_CURRENT_ASSIGNMENTS_HISTORY_TAB_QUERY = gql`
	query getWorkerAssignmentsHistoryTabQuery(
		$filterScope: WorkerAssignmentFilterEnum
		$weekManagementScope: WeekManagementFilterEnum
		$offset: Int
		$limit: Int
	) {
		workerAssignments(filterScope: $filterScope, weekManagementScope: $weekManagementScope, offset: $offset, limit: $limit) {
			id
			status
			createdAt
			updatedAt
			startDate
			endDate
			job {
				...jobFragment
			}
			worker {
				...workerFragment
			}
			workedTimesheets {
				...workedTimesheetFragment
			}
		}
	}
	${JOB_FRAGMENT}
	${WORKER_FRAGMENT_SHORT}
	${WORKED_TIMESHEET_FRAGMENT}
`

export const GET_WORKER_PAST_JOB_QUERY = gql`
	query getWorkerPastJobQuery($offset: Int, $limit: Int) {
		workerAssignments(offset: $offset, limit: $limit) {
			id
			status
			createdAt
			updatedAt
			startDate
			endDate
			job {
				...jobFragment
			}
			worker {
				...workerFragment
			}
		}
	}

	${JOB_FRAGMENT}
	${WORKER_FRAGMENT_SHORT}
`

export const GET_WORKER_ASSIGNMENT_BY_ID_QUERY = gql`
	query getWorkerAssignmentByIdQuery($id: String) {
		workerAssignment(id: $id) {
			id
			status
			createdAt
			updatedAt
			startDate
			endDate
			availableToRate
			extendAssignment {
				id
				status
			}
			rating {
				comment
				createdAt
				id
				ratePoint
			}
		}
	}
`

export const GET_JOB_DETAIL_QUERY = gql`
	query getJobDetailQuery($id: String!) {
		workerAssignment(id: $id) {
			id
			status
			createdAt
			updatedAt
			startDate
			endDate
			job {
				...jobFragment
			}
			worker {
				...workerFragment
			}
			workedTimesheets {
				...workedTimesheetFragment
			}
		}
	}

	${JOB_FRAGMENT}
	${WORKER_FRAGMENT_SHORT}
	${WORKED_TIMESHEET_FRAGMENT}
`
export const GET_WORKER_ASSIGNMENT_QUERY = gql`
	query($id: String!) {
		workerAssignment(id: $id) {
			id
			startDate
			endDate
			rating {
				id
				comment
				ratePoint
			}
			availableToRate
			job {
				...jobFragment
			}
			extendAssignment {
				id
				status
			}
			rating {
				id
				comment
				ratePoint
			}
		}
	}
	${JOB_FRAGMENT}
`
