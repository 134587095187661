import { createMuiTheme } from '@material-ui/core'

export const opusTheme = createMuiTheme({
	palette: {
		primary: {
			main: 'rgba(0, 0, 0, 0.5)',
		},
		text: {
			primary: '#ffffff',
			secondary: 'rgba(255, 255, 255, 0.7)',
			disabled: 'rgba(255, 255, 255, 0.5)',
		},
		background: {
			default: '#303030',
			paper: '#424242',
		},
		action: {
			active: '#ffffff',
			hover: 'rgba(255, 255, 255, 0.08)',
			selected: 'rgba(255, 255, 255, 0.16)',
			disabled: 'rgba(255, 255, 255, 0.3)',
			disabledBackground: 'rgba(255, 255, 255, 0.12)',
		},
		divider: 'rgba(255, 255, 255, 0.12)',
	},

	typography: {
		fontFamily: ['Avenir'],
	},
})
