import axios from 'axios'
import { nanoid } from 'nanoid'
import { captureException } from '~/common/helpers'
import { action, computed, observable, persist, storage, store } from '~/common/mobx.decorator'
import { authStore } from './auth.store'
import { notifyStore } from './notify.store'
import { THEMES } from '~/themes'
import { careStore } from '~/companies/care/care.store'

axios.defaults.baseURL = `${process.env.REACT_APP_PROXY_API_URL}`
axios.defaults.headers['APP_ID'] = process.env.REACT_APP_PROXY_API_APP_ID
axios.defaults.headers['APP_SECRET'] = process.env.REACT_APP_PROXY_API_APP_SECRET

axios.interceptors.response.use(undefined, (error) => {
	captureException('Axios Client', error)
	return Promise.reject(error)
})

@store({ persist: true })
class AppStore {
	constructor() {
		storage.ready(() => {
			this.ready = true
			if (!this._uniqueId && nanoid) {
				this._uniqueId = nanoid()
			}
		})
	}

	@persist @observable _uniqueId
	@observable config = null
	@observable ready = false

	@observable meta_domain

	@computed
	get id() {
		// Company Id
		return process.env.REACT_APP_COMPANY_ID
	}

	@computed
	get name() {
		return 'Focus Staff'
	}

	@computed
	get logo() {
		return `${process.env.PUBLIC_URL}/assets/care/logo.png?version=${process.env.REACT_APP_CI_COMMIT_TAG}`
	}

	get logoWithSlogan() {
		return `${process.env.PUBLIC_URL}/assets/care/logo_with_slogan.png?version=${process.env.REACT_APP_CI_COMMIT_TAG}`
	}

	@computed
	get getTheme() {
		return THEMES.care
	}

	@action
	init = async () => {
		await this.fetchFEConfig()
		await this.fetchAppConfig()

		return new Promise((resolve) => {
			// wait 1s to make sure all resource loaded
			setTimeout(async () => {
				await authStore.verifyToken()
				resolve()
			}, 1000)
		})
	}

	fetchAppConfig = async () => {
		const response = await axios.get(`/company_configs/${this.id}`)

		this.config = response?.data
		this.meta_domain = response?.data?.companyConfigs?.ssrUrl

		careStore.setCompanyConfig(response?.data?.companyConfigs)

		const oneSignalAppId = response?.data?.companyConfigs?.notificationAppId
		await notifyStore.initOneSignal(oneSignalAppId)

		// await notifyStore.sendInfo()
	}

	fetchFEConfig = async () => {
		const response = await fetch('/app-config.json')
		const data = await response.json()

		return data
	}
}

export const appStore = new AppStore()
