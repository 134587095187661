import moment from 'moment'
import { DATE_TIME_FORMAT_VIEW, DATE_FORMAT_VIEW, DATE_FORMAT_SAVE } from '../constants'

export const dateFormat = (value, format = DATE_FORMAT_VIEW) => {
	return moment(value).format(format)
}

export const datetimeFormat = (value, format = DATE_TIME_FORMAT_VIEW) => {
	return moment(value).format(format)
}

export const toDate = (value, format = DATE_FORMAT_SAVE) => {
	return moment(value, format).toDate()
}

export const getDuration = (job) => {
	const hasAssignment = job?.jobApplicant?.workerAssignment

	if (!hasAssignment) return job.duration

	const [startDate, endDate] = [
		job?.jobApplicant?.workerAssignment?.startDate ? moment(job?.jobApplicant?.workerAssignment?.startDate, DATE_FORMAT_SAVE) : null,
		job?.jobApplicant?.workerAssignment?.endDate ? moment(job?.jobApplicant?.workerAssignment?.endDate, DATE_FORMAT_SAVE) : null,
	]

	if (!startDate || !endDate) return null

	return Math.ceil(moment.duration(endDate.diff(startDate)).asWeeks())
}

export const formatNotiCreatedAt = (createdAt) => {
	const specialValue = ['1 day ago', '1 hour ago', '1 month ago']
	let result = moment(createdAt).parseZone().fromNow()
	if (specialValue.includes(result)) {
		const timeUnit = result.split(' ')[1]
		result = `${timeUnit === 'hour' ? 'an' : 'a'} ${timeUnit} ago`
	}
	return result
}
