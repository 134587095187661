export const MASTER_DATA_OBJECTS = {
	Worker: 'Worker',
	WorkExperienceOverview: 'WorkExperienceOverview',
	WorkerCertification: 'WorkerCertification',
	WorkExperience: 'WorkExperience',
	WorkerExpense: 'WorkerExpense',
	Job: 'Job',
}

export const MASTER_DATA_FIELDS = {
	discipline: 'discipline',
	specialty: 'specialty',
	primarySpecialty: 'primary_specialty',
	secondarySpecialty: 'secondary_specialty',
	licenseState: 'license_state',
	preferredLocations: 'preferred_locations',
	unitSpecialty: 'unit_specialty',
	agency: 'agency',
	referral: 'referral_method',
	charting: 'charting_software_used',
	referralRelationship: 'referral_relationship',
	category: 'category',
	shifts: 'shifts',
}
