import { makeStyles } from '@material-ui/core'
import { css } from 'styled-components'

export const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		'& input::placeholder': {
			...theme.typography.body1,
			color: theme?.colors?.content?.dark,
			fontSize: '14px',
		},
	},
	label: {
		...theme?.typography?.subtitle3,
	},
}))

export const optionalFieldStyle = css`
	${({ theme }) => theme.typography.body2};
	color: ${({ theme }) => theme.palette.text.text2};
`
