import { gql } from '@apollo/client'
import { JOB_FRAGMENT, WORKER_FRAGMENT, JOB_WITH_WORKER_ASSIGNMENT_FRAGMENT, WORKER_ASSIGNMENT_FRAGMENT, LOCATION_FRAGMENT } from '../apollo.fragment'

export const GET_GENERAL_RANKED_JOBS_QUERY = gql`
	query getGeneralRankedJobsQuery {
		popularCities {
			city
			cityImage
			state
			avgWeeklyRate
			totalJobs
		}
	}
`

export const GET_DETAIL_RANKED_JOBS_QUERY = gql`
	query getRankedJobsQuery($state: String!, $city: String!, $after: String, $before: String, $first: Int, $last: Int) {
		popularCityJobs(state: $state, city: $city, after: $after, before: $before, first: $first, last: $last) {
			totalCount
			pageInfo {
				hasNextPage
				hasPreviousPage
				endCursor
			}
			nodes {
				...jobFragment
			}
		}
	}

	${JOB_FRAGMENT}
`

export const GET_SUGGESTION_JOBS_QUERY = gql`
	query getSuggestionJobsQuery($after: String, $before: String, $first: Int, $last: Int, $workerId: String!) {
		suggestionJobs(after: $after, before: $before, first: $first, last: $last, workerId: $workerId) {
			totalCount
			pageInfo {
				hasNextPage
				hasPreviousPage
				endCursor
			}
			nodes {
				...jobFragment
			}
		}
	}

	${JOB_FRAGMENT}
`

export const GET_HOT_JOBS_QUERY = gql`
	query getHotJobsQuery(
		$companyId: String!
		$filter: JobFilter
		$keywordSearch: String
		$nearBySearch: NearByJobSearch
		$sortBy: JobManagementSort
		$token: String
		$limit: Int
		$offset: Int
	) {
		jobSearch(
			companyId: $companyId
			filter: $filter
			keywordSearch: $keywordSearch
			nearBySearch: $nearBySearch
			sortBy: $sortBy
			token: $token
			limit: $limit
			offset: $offset
		) {
			totalCount
			jobs {
				id
				title
				discipline
				hotJob
				specialty
				duration
				distance
				isFavorited
				hourlyPayHigh
				hourlyPayLow
				stipends
				taxableHigh
				taxableLow
				activeAssignment {
					id
					startDate
					endDate
				}
				location {
					...locationFragment
				}
				jobApplicant {
					id
					status
					workerAssignment {
						id
						startDate
						endDate
						extendAssignment {
							id
							status
						}
					}
				}
				locationImage
				matchingPercentage
				weeklyPayAmount
				minWeeklyPayAmount
				startDate
				createdDate
				status
				shifts
				account {
					name
				}
			}
		}
	}

	${LOCATION_FRAGMENT}
`

export const GET_ACTIVE_JOBS_ON_HOME_QUERY = gql`
	query getActiveJobOnHomeQuery($filterScope: WorkerAssignmentFilterEnum, $offset: Int, $limit: Int) {
		workerAssignments(filterScope: $filterScope, offset: $offset, limit: $limit) {
			id
			status
			createdAt
			updatedAt
			startDate
			endDate
			job {
				...jobFragment
			}
			extendAssignment {
				id
				status
			}
		}
	}

	${JOB_WITH_WORKER_ASSIGNMENT_FRAGMENT}
`

export const GET_ALL_WORKER_ASSIGNMENTS_QUERY = gql`
	query getAllWorkerAssignmentsQuery($filterScope: WorkerAssignmentFilterEnum, $weekManagementScope: WeekManagementFilterEnum, $offset: Int, $limit: Int) {
		workerAssignments(filterScope: $filterScope, weekManagementScope: $weekManagementScope, offset: $offset, limit: $limit) {
			id
			startDate
			endDate
			isStartDateWithinDays(days: 3)

			job {
				...jobFragment
			}

			workerTasks {
				externalId
				name
				available
			}
			currentWorkWeek {
				id
				active
				startDate
				endDate
			}
			previousWorkWeek {
				id
				active
				startDate
				endDate
			}

			shiftTypes
			facilitySigned
			isSpecialWorkWeek

			account {
				name
				timekeepingDocuments {
					fileUrl
					filename
					id
				}
			}
		}
	}
	${JOB_FRAGMENT}
`

export const GET_JOB_MATCHES_QUERY = gql`
	query getJobMatchesQuery($sortBy: JobManagementSortEnum, $after: String, $before: String, $first: Int, $last: Int) {
		jobMatches(sortBy: $sortBy, after: $after, before: $before, first: $first, last: $last) {
			totalCount
			pageInfo {
				hasNextPage
				hasPreviousPage
				endCursor
			}
			nodes {
				...jobFragment
			}
		}
	}

	${JOB_FRAGMENT}
`

export const GET_ACTIVE_JOBS_QUERY = gql`
	query getActiveWorkerAssignments {
		activeWorkerAssignments {
			completed {
				...workerAssignmentFragment
			}
			current {
				...workerAssignmentFragment
			}
			upcoming {
				...workerAssignmentFragment
			}
		}
	}

	${WORKER_ASSIGNMENT_FRAGMENT}
`

export const GET_APPLIED_JOBS_QUERY = gql`
	query getAppliedJobsQuery($filter: JobApplicantStatusEnum, $offset: Int, $limit: Int) {
		appliedJobs(filter: $filter, offset: $offset, limit: $limit) {
			id
			status
			createdAt
			updatedAt
			job {
				...jobFragment
				jobApplicant {
					id
					status
					workerAssignment {
						id
						startDate
						endDate
						extendAssignment {
							id
							status
						}
					}
				}
			}
			worker {
				...workerFragment
			}
		}
	}

	${JOB_FRAGMENT}
	${WORKER_FRAGMENT}
`

export const GET_FAVORITED_JOBS_QUERY = gql`
	query getfavoritedJobsQuery($after: String, $before: String, $first: Int, $last: Int) {
		favoritedJobs(after: $after, before: $before, first: $first, last: $last) {
			totalCount
			pageInfo {
				hasNextPage
				hasPreviousPage
				endCursor
			}
			nodes {
				...jobFragment
			}
		}
	}

	${JOB_FRAGMENT}
`
