import { css } from 'styled-components'

export const modalStyled = css`
	z-index: 1300 !important;

	.wrapper-box-info {
		background: #d8f4f7;
		padding: 16px;
		.job-title.MuiTypography-body1 {
			font-size: 26px;
			font-weight: 800;
			line-height: 32px;
			text-align: center;
			color: #3ea9bc;
		}
		.sub-title.MuiTypography-body1 {
			font-size: 14px;
			font-weight: 350;
			text-align: center;
			color: #000000;
		}
	}
	.wrap-title {
		display: flex;
		justify-content: space-between;
		padding-bottom: 20px;
		.MuiTypography-body1 {
			font-size: 16px;
			font-weight: 800;
			color: #3ea9bc;
		}
	}
	.have-account {
		min-width: 195px;
		font-style: normal;
		font-weight: 400;
		font-size: 14px !important;
		line-height: 20px;
		display: flex;
		align-items: center;
		text-align: center;
		letter-spacing: 0.2px;
		.sign-in-here {
			margin-left: 5px;
			font-weight: 600;
			color: rgb(44, 110, 238);
			cursor: pointer;
		}

		${({ theme }) => theme.breakpoints.up('sm')} {
			justify-content: flex-end;
		}
	}
	.title .MuiTypography-body1 {
		font-size: 20px;
		font-style: normal;
		font-weight: 800;
		line-height: 28px;
		color: ${({ theme }) => theme.colors.primary.main};
	}

	.star-rating-container .star-list {
		display: block;
	}
`
export const ratingWrapperStyle = css`
	margin: 32px auto 16px auto;
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;

	.rating-value.MuiTypography-body1 {
		font-size: 18px;
		font-weight: 800;
		text-align: center;
		margin-bottom: 30px;
	}

	.comment-rating.MuiTypography-body1 {
		font-size: 14px;
		font-weight: 350;
		color: #7e7e80;
		margin-top: 15px;
	}
	.star-rating-container .star-list {
		display: block;
	}
`
