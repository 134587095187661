import { gql } from '@apollo/client'
import {
	DIRECT_UPLOAD_FRAGMENT,
	EMERGENCY_CONTACT_FRAGMENT,
	FILE_FRAGMENT,
	IMAGE_FILE_FRAGMENT,
	SALESFORCE_INTEGRATION_FRAGMENT,
	WORK_EXPERIENCE_FRAGMENT,
	WORKED_TIMESHEET_FRAGMENT,
	WORKER_ADDRESS_FRAGMENT,
	WORKER_CERTIFICATION_FRAGMENT,
	WORKER_FRAGMENT,
	WORKING_AUTHORIZATION_FRAGMENT,
} from '../apollo.fragment'

export const SIGNIN_WORKER_MUTATION = gql`
	mutation signInWorker($email: String!, $password: String!, $recaptchaToken: String, $socialAuthenticationId: String, $jobId: String) {
		signInWorker(recaptchaToken: $recaptchaToken, email: $email, password: $password, socialAuthenticationId: $socialAuthenticationId, jobId: $jobId) {
			authToken
			notificationChannel
			worker {
				...workerFragment
				recruiter {
					id
					name
					phone
					email
					title
				}
			}
		}
	}

	${WORKER_FRAGMENT}
`

export const VERIFY_OAUTH_LOGIN = gql`
	mutation verifyOauthLogin(
		$providerName: SocialProviderEnum!
		$clientId: String!
		$clientToken: String
		$email: String
		$code: String
		$redirectUri: String
		$recaptchaToken: String
	) {
		verifySocialAuthentication(
			providerName: $providerName
			clientId: $clientId
			clientToken: $clientToken
			email: $email
			code: $code
			redirectUri: $redirectUri
			recaptchaToken: $recaptchaToken
		) {
			authToken
			emailHasAccount
			notificationChannel
			userSocialAuthentication {
				email
				providerName
				id
				providerUuid
				userId
			}
			worker {
				company {
					id
					logoUrl
					name
					country
					city
					address
				}
				email
				firstName
				id
				lastName
				phone
				phoneVerifiedAt
				workAuthorized
			}
		}
	}
`

export const CREATE_WORKER_MUTATION = gql`
	mutation createWorker(
		$recaptchaToken: String
		$firstName: String!
		$lastName: String!
		$email: String!
		$companyId: String!
		$phone: String!
		$socialAuthenticationId: String
		$password: String
		$passwordConfirmation: String
		$jobId: String
	) {
		createIntegrationWorker(
			socialAuthenticationId: $socialAuthenticationId
			recaptchaToken: $recaptchaToken
			firstName: $firstName
			lastName: $lastName
			email: $email
			companyId: $companyId
			phone: $phone
			password: $password
			passwordConfirmation: $passwordConfirmation
			source: opus_web_portal
			jobId: $jobId
		) {
			authToken
			notificationChannel
			worker {
				...workerFragment
				company {
					salesforceIntegration {
						...salesforceIntegrationFragment
					}
				}
			}
		}
	}

	${WORKER_FRAGMENT}
	${SALESFORCE_INTEGRATION_FRAGMENT}
`

export const UPDATE_WORKER_MUTATION = gql`
	mutation updateWorker($id: String!, $referral: String!) {
		updateIntegrationWorker(id: $id, referralMethod: $referral) {
			id
		}
	}
`

export const UPDATE_WORKER_LOCATION_MUTATION = gql`
	mutation createWorkingPreferredLocations($id: String!, $location1: String, $location2: String, $location3: String) {
		createWorkingPreferredLocations(
			id: $id
			workingPreferredLocationsAttributes: [{ preferredWorkingState: $location1 }, { preferredWorkingState: $location2 }, { preferredWorkingState: $location3 }]
		) {
			success
		}
	}
`

export const SEND_PASSWORD_RESET_WORKER_MUTATION = gql`
	mutation sendPasswordResetWorker($email: String!) {
		sendPasswordResetWorker(email: $email) {
			success
		}
	}
`

export const RESET_PASSWORD_WORKER_MUTATION = gql`
	mutation resetPasswordWorker($token: String!, $password: String!, $passwordConfirmation: String!, $recaptchaToken: String) {
		resetPasswordForUser(recaptchaToken: $recaptchaToken, token: $token, password: $password, passwordConfirmation: $passwordConfirmation) {
			success
		}
	}
`

export const UPDATE_WORK_EXPERIENCE_MUTATION = gql`
	mutation updateWorkExperience($id: String!, $experiences: [WorkExperienceArgument!]) {
		updateIntegrationWorker(id: $id, workExperiencesAttributes: $experiences) {
			workExperiences {
				...workExperienceFragment
			}
		}
	}
	${WORK_EXPERIENCE_FRAGMENT}
`

export const UPDATE_WORKER_LICENSE_MUTATION = gql`
	mutation updateWorkerLicenses($id: String!, $licenses: [WorkerCertificationArgument!]) {
		updateIntegrationWorker(id: $id, workerCertificationsAttributes: $licenses) {
			id
			workerCertifications {
				...workerCertificationFragment
			}
		}
	}

	${WORKER_CERTIFICATION_FRAGMENT}
`

export const CREATE_DIRECT_UPLOAD_MUTATION = gql`
	mutation createDirectUpload($filename: String!, $byteSize: Int!, $checksum: String!, $contentType: String!) {
		createDirectUpload(filename: $filename, byteSize: $byteSize, checksum: $checksum, contentType: $contentType) {
			...directUploadFragment
		}
	}
	${DIRECT_UPLOAD_FRAGMENT}
`

export const CREATE_WORKED_TIMESHEET_MUTATION = gql`
	mutation createWorkedTimesheet($workerId: String!, $signedBlobId: [String!]!, $endDate: String!, $workerAssignmentId: String) {
		createWorkedTimesheet(workerId: $workerId, signedBlobId: $signedBlobId, endDate: $endDate, workerAssignmentId: $workerAssignmentId) {
			...workedTimesheetFragment
			timesheetImages {
				...imageFileFragment
			}
		}
	}
	${WORKED_TIMESHEET_FRAGMENT}
	${IMAGE_FILE_FRAGMENT}
`

export const DESTROY_FILE_MUTATION = gql`
	mutation destroyFile($id: String!) {
		destroyFile(id: $id) {
			id
			success
		}
	}
`

export const UPDATE_WORKED_TIMESHEET_MUTATION = gql`
	mutation updateWorkedTimesheet($id: String!, $signedBlobId: [String!]!, $workerAssignmentId: String) {
		updateWorkedTimesheet(id: $id, signedBlobId: $signedBlobId, workerAssignmentId: $workerAssignmentId) {
			...workedTimesheetFragment
			timesheetImages {
				...imageFileFragment
			}
		}
	}
	${WORKED_TIMESHEET_FRAGMENT}
	${IMAGE_FILE_FRAGMENT}
`
export const UPDATE_WORKER_RESUME_MUTATION = gql`
	mutation updateWorkerInfo($id: String!, $signedBlobId: String) {
		updateIntegrationWorker(id: $id, signedBlobId: $signedBlobId) {
			...workerFragment
			resume {
				...fileFragment
			}
			workerAddresses {
				...workerAddressFragment
			}
			workingAuthorization {
				...workingAuthorizationFragment
			}
			workExperiences {
				...workExperienceFragment
			}
			workerCertifications {
				...workerCertificationFragment
			}
		}
	}

	${WORKER_FRAGMENT}
	${WORKER_ADDRESS_FRAGMENT}
	${WORKING_AUTHORIZATION_FRAGMENT}
	${WORK_EXPERIENCE_FRAGMENT}
	${WORKER_CERTIFICATION_FRAGMENT}
	${FILE_FRAGMENT}
`

export const SEND_OTP_CODE_MUTATION = gql`
	mutation sendOtpCodeMutation($phoneNumber: String!) {
		sendOtpCode(phoneNumber: $phoneNumber) {
			success
		}
	}
`

export const VERIFY_OTP_CODE_MUTATION = gql`
	mutation verifyOtpCodeMutation($otpCode: String!, $phoneNumber: String!) {
		verifyOtpCode(otpCode: $otpCode, phoneNumber: $phoneNumber) {
			success
		}
	}
`

export const UPDATE_SCHEDULED_DATE = gql`
	mutation updateWorkerAssignmentMutation($id: String!, $lastDayScheduled: ISO8601Date!) {
		updateWorkerAssignment(id: $id, lastDayScheduled: $lastDayScheduled) {
			id
			lastDayScheduled
		}
	}
`

export const UPDATE_WORKER_INFO_MUTATION = gql`
	mutation updateWorkerInfo(
		$id: String!
		$firstName: String
		$lastName: String
		$phone: String
		$avatarImageBase64: String
		$avatarImageFileName: String
		$emergencyContact: EmergencyContactArgument
		$workingAuthorization: WorkingAuthorizationArgument
		$workerAddresses: [WorkerAddressArgument!]
		$maidenName: String
		$signedBlobId: String
	) {
		updateIntegrationWorker(
			id: $id
			firstName: $firstName
			lastName: $lastName
			phone: $phone
			avatarImageBase64: $avatarImageBase64
			avatarImageFileName: $avatarImageFileName
			maidenName: $maidenName
			emergencyContactAttributes: $emergencyContact
			workingAuthorizationAttributes: $workingAuthorization
			workerAddressesAttributes: $workerAddresses
			signedBlobId: $signedBlobId
		) {
			...workerFragment
			resume {
				...fileFragment
			}
			workerAddresses {
				...workerAddressFragment
			}
			workingAuthorization {
				...workingAuthorizationFragment
			}
			workExperiences {
				...workExperienceFragment
			}
			workerCertifications {
				...workerCertificationFragment
			}
			emergencyContact {
				...emergencyContactFragment
			}
		}
	}

	${WORKER_FRAGMENT}
	${WORKER_ADDRESS_FRAGMENT}
	${WORKING_AUTHORIZATION_FRAGMENT}
	${WORK_EXPERIENCE_FRAGMENT}
	${WORKER_CERTIFICATION_FRAGMENT}
	${FILE_FRAGMENT}
	${EMERGENCY_CONTACT_FRAGMENT}
`

export const UPDATE_WORKER_ADDRESS_MUTATION = gql`
	mutation updateWorkerInfo(
		$id: String!
		$street: String
		$city: String
		$zipcode: String
		$state: String
		$active: Boolean
		$default: Boolean
		$workerId: String
		$aptNumber: String
	) {
		updateWorkerAddress(
			id: $id
			street: $street
			city: $city
			zipcode: $zipcode
			state: $state
			active: $active
			default: $default
			workerId: $workerId
			aptNumber: $aptNumber
		) {
			id
		}
	}
`

export const CREATE_WORKER_CERTICATION_MUTATION = gql`
	mutation createWorkerCertification(
		$licenseDiscipline: String!
		$licenseState: String!
		$licenseNumber: String!
		$compactLicense: Boolean!
		$expirationDate: String!
		$certificationType: String!
		$licenseImages: [FileArgument!]!
	) {
		createWorkerCertification(
			licenseDiscipline: $licenseDiscipline
			licenseState: $licenseState
			licenseNumber: $licenseNumber
			compactLicense: $compactLicense
			expirationDate: $expirationDate
			certificationType: $certificationType
			licenseImages: $licenseImages
		) {
			...workerCertificationFragment
			licenseImages {
				...imageFileFragment
			}
		}
	}

	${WORKER_CERTIFICATION_FRAGMENT}
	${IMAGE_FILE_FRAGMENT}
`
export const UPDATE_SHOW_PARTIAL_JOBS = gql`
	mutation updateIntegrationWorker($updateIntegrationWorkerId: String!, $settings: JSON) {
		updateIntegrationWorker(id: $updateIntegrationWorkerId, settings: $settings) {
			id
			settings
		}
	}
`

export const DISCONNECT_SOCIAL_AUTHENTICATION = gql`
	mutation DisconnectSocialAuthentication($socialAuthenticationId: String!) {
		disconnectSocialAuthentication(socialAuthenticationId: $socialAuthenticationId) {
			success
		}
	}
`

export const SIGNOUT_WORKER_MUTATION = gql`
	mutation signOutWorkerMutation {
		signOutWorker {
			success
		}
	}
`

export const GET_WORKER_ASSIGNMENT_TIMECARD_INSTRUCTIONS_QUERY = gql`
	query {
		currentWorkerAssignment {
			id
			startDate
			account {
				name
			}
		}
	}
`
