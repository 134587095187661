import * as yup from 'yup'

const ERROR_PASS_REQUIRED = 'Please enter your password to continue'
const ERROR_PASS_INVALID_RULES = 'Password must be at least 6 characters and include one number and one letter without any space'

export const passwordRuleRegexes = {
	atLeast8Characters: /.{8,}/,
	oneLetter: /[a-zA-Z]/,
	oneNumber: /[0-9]/,
	noSpaces: /^\S*$/,
}

export const rules = [
	{
		message: 'From 6 characters',
		regex: passwordRuleRegexes.atLeast8Characters,
	},
	{ message: '1 letter', regex: passwordRuleRegexes.oneLetter },
	{ message: '1 number', regex: passwordRuleRegexes.oneNumber },
	{ message: 'No spaces', regex: passwordRuleRegexes.noSpaces },
]

export const getPasswordFieldSchema = (requireMessage = ERROR_PASS_REQUIRED) => {
	return yup.object().shape({
		password: yup
			.string()
			.trim()
			.required(requireMessage)
			.matches(passwordRuleRegexes.noSpaces, ERROR_PASS_INVALID_RULES)
			.matches(passwordRuleRegexes.oneLetter, ERROR_PASS_INVALID_RULES)
			.matches(passwordRuleRegexes.oneNumber, ERROR_PASS_INVALID_RULES)
			.min(6, (obj) => ERROR_PASS_INVALID_RULES),
	})
}
