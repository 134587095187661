import { defaultsDeep, isEqual, isObject, mapValues, transform } from 'lodash'
import { flatten, unflatten } from 'flat'

/**
 * Deep diff between two object, using lodash
 * @param  {Object} object Object compared
 * @param  {Object} base   Object to compare with
 * @return {Object}        Return a new object who represent the diff
 */
export const diff = (object, base) => {
	function changes(object, base) {
		return transform(object, function (result, value, key) {
			if (!isEqual(value, base[key])) {
				result[key] = isObject(value) && isObject(base[key]) ? changes(value, base[key]) : value
			}
		})
	}

	return changes(object, base)
}

export const diffUpdate = (object, base) => {
	const newData = diff(object, base)
	const removeData = diff(base, object)

	const nullData = unflatten(mapValues(flatten(removeData), () => null))

	const updateData = defaultsDeep({}, newData, nullData)

	return updateData
}

/**
 * Compare diff between two array, only for equals to Id
 * @param  {Array} a Array compared
 * @param  {Array} b Array to compare with

 */
export const equalsId = (a, b) => a?.length === b?.length && a.every((v, i) => v.id === b[i].id)

/**
 * Find unique array of shifts only based on 4 props: Day of the Week, Shift Type, Time In, Time Out
 * @param  {Array} arr Array compared
 */

export const uniqueShifts = (arr) => {
	let hasDuplicate = false
	const modifiedItems = []
	arr &&
		arr.length !== 0 &&
		arr.map((item, index) => {
			const isDuplicate = arr.find(
				(obj, ind) => obj.title === item.title && obj.description === item.description && obj.startEnd === item.startEnd && index !== ind
			)

			hasDuplicate = hasDuplicate || isDuplicate

			return modifiedItems.push({ ...item, isDuplicate })
		})

	return { hasDuplicate, modifiedItems }
}
