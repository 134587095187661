import React from 'react'
import { RateDialogComponent } from './rate-dialog.component'
import { NotifyDialog } from '../notify'
import { Box } from '@material-ui/core'
import { observer } from 'mobx-react-lite'

export const RateDialogConfirmComponent = observer(({ confirmDialog, setShowConfirmDialog, workerAssigment, job }) => {
	const [open, setOpen] = React.useState(false)
	if (!job) {
		return null
	}

	return (
		<>
			<NotifyDialog
				open={confirmDialog}
				title="You have rated for this job already"
				onClose={() => setShowConfirmDialog(false)}
				onOk={() => setOpen(true)}
				onCancel={() => setShowConfirmDialog(false)}
				okText={'view my rating'}
				cancelText={'CANCEL'}
			>
				<Box color={'#000000'} mb={2}>
					You can view your rating in the job detail page.
				</Box>
			</NotifyDialog>

			<RateDialogComponent
				workerAssigmentId={workerAssigment.id}
				jobGeneral={job}
				open={open}
				rating={workerAssigment?.rating?.ratePoint}
				comment={workerAssigment?.rating?.comment}
				viewOnly
				setClose={() => {
					setOpen(false)
				}}
			/>
		</>
	)
})
