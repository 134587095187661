import validator from 'validator'
import moment from 'moment'
import { DATE_ENCRYPT_PATTERN, DATE_FORMAT_SAVE } from '../constants'
import { isEmpty, some, toNumber } from 'lodash'
import { validateUSPhoneNumber } from '../helpers/phone-format.helper'

export const ERRORS = {
	required: '$ERRORS.REQUIRED',
	email: '$ERRORS.EMAIL',
	phone: '$ERRORS.PHONE',
	socialSecurityNumber: '$ERRORS.SOCIAL_SECURITY_NUMBER',
	checked: '$ERRORS.CHECKED',
	date: '$ERRORS.DATE',
	dateRange: '$ERRORS.DATE_RANGE',
	birthDay: '$ERRORS.BIRTH_DAY',
	address: '$ERRORS.ADDRESS',
	minArrayLength: '$ERRORS.MIN_ARRAY_LENGTH',
	time: '$ERRORS.TIME',
	number: '$ERRORS.NUMBER',
	maxLength: '$ERRORS.MAX_LENGTH',
	doubleQuote: '$ERRORS.DOUBLE_QUOTE',
}

export const doubleQuote = (value) => {
	if (typeof value !== 'string') {
		return
	}

	return value?.indexOf('"') !== -1 && ERRORS.doubleQuote
}

export const required = (value) => {
	if (value === null || typeof value === 'undefined') {
		return ERRORS.required
	}

	if (typeof value === 'string') {
		return validator.isEmpty(value, { ignore_whitespace: true }) && ERRORS.required
	}
}

export const boolean = (value) => {
	return typeof value !== 'boolean' && ERRORS.required
}

export const email = (value) => {
	return value && !validator.isEmail(value) && ERRORS.email
}

export const phone = (value) => {
	return value && !validateUSPhoneNumber(value) && ERRORS.phone
}

export const socialSecurityNumber = (value) => {
	if (!value) {
		return
	}

	const expression = /^(?!666|000|9\d{2})\d{3}[- ]{0,1}(?!00)\d{2}[- ]{0,1}(?!0{4})\d{4}$/
	const encryptPattern = /^[x]{3}-[x]{2}-[0-9]{4}$/

	return !encryptPattern.test(value) && !expression.test(value) && ERRORS.socialSecurityNumber
}

export const checked = (value) => {
	return (typeof value !== 'boolean' || value !== true) && ERRORS.checked
}

export const date = (value) => {
	if (!value) {
		return
	}

	const date = moment(value, DATE_FORMAT_SAVE)

	return (!date.isValid() || date.isBefore(moment('1700-01-01'), 'year')) && ERRORS.date
}

export const maxDate = (date) => (value) => {
	if (!value) {
		return
	}

	const max = moment(date)
	const selectDate = moment(value, DATE_FORMAT_SAVE)

	return (!selectDate.isValid() || selectDate.isAfter(max, 'date')) && ERRORS.date
}

export const maxLength = (max = 60) => (value) => {
	if (!value) {
		return
	}

	return value?.length > max && `Maximum is ${max} characters`
}

export const minValue = (min = 0) => (value) => {
	const num = toNumber(value)
	if (typeof num !== 'number') {
		return
	}

	return num <= min && ERRORS.number
}

export const minDate = (min = new Date(), message = ERRORS.date) => (value) => {
	const date = moment(value, DATE_FORMAT_SAVE)

	if (!date.isValid()) {
		return
	}

	return date.isBefore(moment(min), 'date') && message
}

export const birthDay = (minYear = 18, format = DATE_FORMAT_SAVE) => (value) => {
	if (DATE_ENCRYPT_PATTERN.test(value) || !value) {
		return
	}

	const date = moment(value, format)

	if (!date.isValid()) {
		return ERRORS.date
	}

	return date.isAfter(moment().subtract(minYear, 'years')) && ERRORS.birthDay
}

export const address = (values) => {
	if (isEmpty(values)) {
		return { street: ERRORS.required }
	}
	const KEYS = ['street', 'city', 'zipcode', 'country', 'state']
	const missingKeys = KEYS.some((key) => !Object.keys(values).includes(key))

	const invalid = some(Object.entries(values), ([key, value]) => KEYS.includes(key) && !value)

	return (invalid || missingKeys) && { street: ERRORS.address }
}

export const minArrayLength = (min = 1, message = ERRORS.minArrayLength) => (values = []) => {
	const items = values.filter((v) => !v._destroy)

	return items?.length < min && message
}

export const dateRange = (value) => {
	if (!value?.[0] || !value?.[1]) {
		return ERRORS.required
	}

	const [start, end] = [moment(value[0], DATE_FORMAT_SAVE), moment(value[1], DATE_FORMAT_SAVE)]

	if (!start.isValid() || !end.isValid()) {
		return ERRORS.date
	}

	if (start.isAfter(moment(), 'day')) {
		return ERRORS.date
	}

	if (start.isBefore(moment('1700-01-01'), 'year')) {
		return ERRORS.date
	}

	if (end.isBefore(start, 'day')) {
		return ERRORS.dateRange
	}
}

export const currentDateRange = (value) => {
	if (!value?.[0]) {
		return ERRORS.required
	}

	const date = moment(value?.[0], DATE_FORMAT_SAVE)

	if (!date.isValid()) {
		return ERRORS.date
	}

	if (date.isAfter(moment(), 'day')) {
		return ERRORS.dateRange
	}

	if (date.isBefore(moment('1700-01-01'), 'year')) {
		return ERRORS.date
	}
}

export const samePassword = (t) => ({ password, passwordConfirmation }) => {
	return password !== passwordConfirmation && { passwordConfirmation: t('$ERRORS.PASSWORD_CONFIRM') }
}

export const compose = (...funcs) => async (...args) => {
	for (let index = 0; index < funcs.length; index++) {
		const func = funcs[index]
		let error

		if (typeof func.then === 'function') {
			error = await func(...args)
		} else {
			error = func(...args)
		}

		if (error) {
			return error
		}
	}
}

export const composeT = (t) => (...funcs) => async (...args) => {
	const error = await compose(...funcs)(...args)
	return error && t(error)
}

export const time = (value) => {
	return value && !/^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/.test(value) && ERRORS.time
}
