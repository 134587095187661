import { css } from 'styled-components'

export const phoneFieldStyle = css`
	width: 100%;
`

export const labelStyle = css`
	color: ${({ theme }) => theme.palette.denotive.dark_blue};
`

export const viewLabelStyle = css`
	.MuiFormLabel-root {
		color: ${({ theme }) => theme.palette.denotive.dark_blue};
	}

	.MuiInputBase-root > .MuiBox-root {
		word-break: break-word;
	}
`
