import { gql } from '@apollo/client'
import { NOTIFICATION_SETTING_FRAGMENT } from '~/common/apollo/apollo.fragment'

export const CHANGE_PASSWORD_USER_MUTATION = gql`
	mutation changePasswordUser($oldPassword: String!, $password: String!, $passwordConfirmation: String!) {
		changePasswordForUser(oldPassword: $oldPassword, password: $password, passwordConfirmation: $passwordConfirmation) {
			success
		}
	}
`

export const UPDATE_NOTIFICATION_SETTING_MUTATION = gql`
	mutation updateNotificationSettingMutation(
		$id: String!
		$jobsRecommendation: Boolean
		$completeOnboarding: Boolean
		$licenseExpired: Boolean
		$timecardSubmissionReminder: Boolean
		$referralUpdates: Boolean
		$licenseExpiredOptions: JSON
		$licenseWillBeExpiredOptions: JSON
		$jobNotifications: JobNotificationArgument
		$assignmentReminders: AssignmentReminderArgument
		$credentialReminder: Boolean
	) {
		updateNotificationSetting(
			id: $id
			jobsRecommendation: $jobsRecommendation
			completeOnboarding: $completeOnboarding
			licenseExpired: $licenseExpired
			timecardSubmissionReminder: $timecardSubmissionReminder
			licenseExpiredOptions: $licenseExpiredOptions
			referralUpdates: $referralUpdates
			licenseWillBeExpiredOptions: $licenseWillBeExpiredOptions
			jobNotifications: $jobNotifications
			assignmentReminders: $assignmentReminders
			credentialReminder: $credentialReminder
		) {
			...notificationSettingFragment
		}
	}
	${NOTIFICATION_SETTING_FRAGMENT}
`

export const UPDATE_WORKER_INFORMATION_MUTATION = gql`
	mutation updateWorker(
		$id: String!
		$firstName: String
		$lastName: String
		$email: String
		$phone: String
		$password: String
		$workerAddresses: [WorkerAddressArgument!]
		$avatarImageBase64: String
		$avatarImageFileName: String
		$agreedToTerms: Boolean
		$noNotification: Boolean
	) {
		updateWorker(
			id: $id
			firstName: $firstName
			lastName: $lastName
			email: $email
			phone: $phone
			password: $password
			workerAddresses: $workerAddresses
			avatarImageBase64: $avatarImageBase64
			avatarImageFileName: $avatarImageFileName
			agreedToTerms: $agreedToTerms
			noNotification: $noNotification
		) {
			id
		}
	}
`

export const UPDATE_WORKER_SIGNUP_MUTATION = gql`
	mutation updateWorkerSignup($id: String!, $workExperienceOverviewAttributes: WorkExperienceOverviewArgument) {
		updateIntegrationWorker(id: $id, workExperienceOverviewAttributes: $workExperienceOverviewAttributes) {
			id
		}
	}
`
