export const CREDENTIALS_COMPLETE_TYPE = {
	external: 'external',
	in_app: 'in_app',
}

export const CREDENTIALS_STATUS = {
	pending: 'pending',
	in_verification: 'in_verification',
	verified: 'verified',
	expired: 'expired',
	signed: 'signed',
}

export const CREDENTIALS_ATS_SOURCE = {
	bullhorn: 'bullhorn',
	symplr: 'symplr',
}

export const VERIFICATION_PHONE_ERROR = {
	sixTimeOtpRequest: {
		title: '$MESSAGES.PHONE_VERIFICATION_SIX_TIMES_DIALOG',
		message: '$MESSAGES.PHONE_VERIFICATION_SIX_TIMES_MESSAGE',
	},
	otpRequestLimit: {
		title: '$MESSAGES.PHONE_VERIFICATION_ONE_TIME_DIALOG',
		message: '$MESSAGES.PHONE_VERIFICATION_ONE_TIME_MESSAGE',
	},
	otpSessionLocked: {
		title: '$MESSAGES.PHONE_VERIFICATION_LOCK_TIME_DIALOG',
		message: '$MESSAGES.PHONE_VERIFICATION_LOCK_TIME_MESSAGE',
	},
	showTemporaryLock: {
		title: '$MESSAGES.PHONE_VERIFICATION_LOCK_TIME_DIALOG',
		message: '$MESSAGES.PHONE_VERIFICATION_LOCK_TIME_MESSAGE',
	},
}

export const BADGE_TAB = {
	earned: 'earned',
	all: 'all',
}

export const NOTIFICATION_DATA_TYPE = {
	workerAssignment: 'WorkerAssignment',
}

export const NOTIFICATION_DATA_EVENT = {
	assignmentRatingReminder: 'assignment_rating_reminder',
}
