import { DocSvg, PdfSvg } from '~/components/icons'

export const ICONS = {
	'application/doc': DocSvg,
	'application/msword': DocSvg,
	'application/ms-doc': DocSvg,
	'application/vnd.openxmlformats-officedocument.wordprocessingml.document': DocSvg,
	'application/pdf': PdfSvg,
	'application/xml': DocSvg,
}

export const IMAGE_TYPE = 'image/'

export const SPECIAL_TYPES = ['.heic', '.heif'] //being used for windows, android if don't have any type using extension.

export const HEIC_TYPES = ['image/heic', 'image/heif']
