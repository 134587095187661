import { gql } from '@apollo/client'

export const EMPLOYER_FRAGMENT = gql`
	fragment employerFragment on Employer {
		id
		email
		firstName
		lastName
		avatarUrl
	}
`

export const EMERGENCY_CONTACT_FRAGMENT = gql`
	fragment emergencyContactFragment on EmergencyContact {
		id
		contactName
		contactPhone
		contactRelationship
	}
`

export const WORKER_ADDRESS_FRAGMENT = gql`
	fragment workerAddressFragment on WorkerAddress {
		id
		zipcode
		street
		default
		city
		state
		country
		latitude
		longitude
	}
`
export const NOTIFICATION_SETTING_FRAGMENT = gql`
	fragment notificationSettingFragment on NotificationSetting {
		assignmentReminders {
			description
			endOfAssignment
			placementProfile
			postAssignmentSurvey
			temporaryAddress
			ratingReminder
		}
		completeOnboarding
		id
		credentialReminder
		jobNotifications {
			description
			jobMatches
			jobRecommendations
		}
		jobsRecommendation
		licenseExpired
		licenseExpiredOptions
		licenseExpiries {
			description
			licenseWillBeExpiredOptions
		}
		licenseWillBeExpiredOptions
		referralUpdates
		timecardSubmissionReminder
	}
`
export const WORKER_FRAGMENT = gql`
	fragment workerFragment on Worker {
		id
		email
		firstName
		lastName
		avatarUrl
		phone
		phoneVerifiedAt
		workAuthorized
		maidenName
		hasWorkerAssignments
		hasActiveAssignment
		company {
			companyConfig {
				adpUrl
				companyId
				oneSignalAppId
				enableHourlySearch
			}
		}
		notificationSetting {
			...notificationSettingFragment
		}
	}
	${NOTIFICATION_SETTING_FRAGMENT}
`

export const WORKER_FRAGMENT_SHORT = gql`
	fragment workerFragment on Worker {
		id
		email
		firstName
		lastName
		avatarUrl
		phone
		phoneVerifiedAt
		workAuthorized
		maidenName
		hasWorkerAssignments
		hasActiveAssignment
	}
`

export const LOCATION_FRAGMENT = gql`
	fragment locationFragment on Location {
		id
		address
		addressState
		city
		country
		zipcode
		latitude
		longitude
	}
`

export const JOB_FRAGMENT = gql`
	fragment jobFragment on Job {
		id
		title
		description
		externalJobId
		hotJob
		startDate
		endDate
		duration
		matchingPercentage
		weeklyPayAmount
		minWeeklyPayAmount
		hourlyPayHigh
		hourlyPayLow
		stipends
		taxableHigh
		taxableLow
		specialty
		discipline
		isFavorited
		distance
		status
		location {
			...locationFragment
		}
		locationImage
		jobApplicant {
			id
			status
		}
		shifts
		hoursPerWeek
		shiftsPerWeek
		externalJobId
		licensesRequired
		licenseStateRequired
		certificationsRequired
		account {
			name
		}
	}
	${LOCATION_FRAGMENT}
`

export const JOB_WITH_WORKER_ASSIGNMENT_FRAGMENT = gql`
	fragment jobFragment on Job {
		id
		title
		description
		hotJob
		externalJobId
		startDate
		endDate
		duration
		matchingPercentage
		weeklyPayAmount
		minWeeklyPayAmount
		hourlyPayHigh
		hourlyPayLow
		stipends
		taxableHigh
		taxableLow
		specialty
		discipline
		distance
		isFavorited
		status
		location {
			...locationFragment
		}
		locationImage
		jobApplicant {
			id
			status
			workerAssignment {
				id
				startDate
				endDate
				extendAssignment {
					id
					status
				}
			}
		}
		shifts
		hoursPerWeek
		shiftsPerWeek
		externalJobId
		licensesRequired
		licenseStateRequired
		certificationsRequired
		account {
			name
		}
	}
	${LOCATION_FRAGMENT}
`

export const COMPANY_FRAGMENT = gql`
	fragment companyFragment on Company {
		address
		addressState
		city
		country
		description
		id
		mainContactEmail
		mainContactName
		name
		phone
		zip
	}
`

export const WORKER_CREDENTIAL_FRAGMENT = gql`
	fragment workerCredentialFragment on WorkerCredential {
		id
		title
		createdAt
		credentialImages {
			id
			contentType
			filename
			imageUrls(size: "500x500")
			fileUrl
			blobId
		}
	}
`

export const WORKING_AUTHORIZATION_FRAGMENT = gql`
	fragment workingAuthorizationFragment on WorkingAuthorization {
		id
		dateOfBirth
		socialSecurityNumber
	}
`

export const WORK_EXPERIENCE_FRAGMENT = gql`
	fragment workExperienceFragment on WorkExperience {
		id
		startDate
		endDate
		facilityName
		unitSpecialty
		unitsFloatedTo
		floated
		chartingSoftwareUsed
		travelAssignment
		agency
		currentlyEmployed
		createdAt
		city
		state
		country
		patientRatio
		bedsInUnit
	}
`

export const SALESFORCE_INTEGRATION_FRAGMENT = gql`
	fragment salesforceIntegrationFragment on SalesforceIntegration {
		id
		companyId
		businessDay
		weekending
	}
`

export const WORKED_TIMESHEET_FRAGMENT = gql`
	fragment workedTimesheetFragment on WorkedTimesheet {
		id
		startDate
		endDate
		status
		updatedAt
	}
`

export const ONBOARDING_PART_FRAGMENT = gql`
	fragment onboardingPartFragment on OnboardingPart {
		id
		name
		description
	}
`
export const DIRECT_UPLOAD_FRAGMENT = gql`
	fragment directUploadFragment on DirectUpload {
		blobId
		headers
		signedBlobId
		url
		preSignedUrl
	}
`

export const WORKER_CERTIFICATION_FRAGMENT = gql`
	fragment workerCertificationFragment on WorkerCertification {
		id
		title
		licenseDiscipline
		licenseState
		licenseNumber
		compactLicense
		expirationDate
		certificationType
		createdAt
		licenseImages {
			id
			contentType
			filename
			imageUrls(size: "500x500")
			fileUrl
			blobId
		}
	}
`

export const WORKER_REFERENCE_FRAGMENT = gql`
	fragment workerReferenceFragment on WorkerReference {
		id
		facilityName
		contactFullName
		contactEmail
		contactPhone
		waitToContact
		jobTitle
		createdAt
	}
`
export const IMAGE_FILE_FRAGMENT = gql`
	fragment imageFileFragment on File {
		id
		fileUrl
		contentType
		blobId
	}
`

export const FILE_FRAGMENT = gql`
	fragment fileFragment on File {
		id
		filename
		fileUrl
		contentType
	}
`

export const WORKER_EDUCATION_FRAGMENT = gql`
	fragment workerEducationFragment on WorkerEducation {
		id
		schoolName
		major
		state
		city
		country
		degreeName
		degreeDate
	}
`

export const LICENSE_IMAGES_FRAGMENT = gql`
	fragment licenseImagesFragment on File {
		id
		fileUrl
		contentType
	}
`

export const WORKER_CETIFICATION_OVERVIEW_FRAGMENT = gql`
	fragment workerCertificationOverviewFragment on WorkerCertificationOverview {
		id
		workerCertifications {
			...workerCertificationFragment
		}
		workerCredentials {
			...workerCredentialFragment
		}
	}

	${WORKER_CERTIFICATION_FRAGMENT}
	${WORKER_CREDENTIAL_FRAGMENT}
`

export const WORKER_REFERENCE_OVERVIEW_FRAGMENT = gql`
	fragment workerReferenceOverviewFragment on WorkerReferenceOverview {
		id
		workerReferences {
			...workerReferenceFragment
		}
	}

	${WORKER_REFERENCE_FRAGMENT}
`

export const WORKER_EDUCATION_OVERVIEW_FRAGMENT = gql`
	fragment workerEducationOverviewFragment on WorkerEducationOverview {
		id
		workerEducations {
			...workerEducationFragment
		}
	}

	${WORKER_EDUCATION_FRAGMENT}
`

export const WORK_EXPERIENCE_OVERVIEW_FRAMENT = gql`
	fragment workExperienceOverview on WorkExperienceOverview {
		id
		discipline
		primarySpecialty
		secondarySpecialty
		yearsOfExperience
		workExperiences {
			...workExperienceFragment
		}
	}

	${WORK_EXPERIENCE_FRAGMENT}
`

export const WORKER_ONBOARDING_PART_INTEGRATION_FRAGMENT = gql`
	fragment workerOnboardingPartIntegrationFragment on WorkerOnboardingPartIntegration {
		... on WorkerCertificationOverview {
			...workerCertificationOverviewFragment
		}

		... on WorkExperienceOverview {
			...workExperienceOverview
		}

		... on WorkerReferenceOverview {
			...workerReferenceOverviewFragment
		}

		... on WorkerEducationOverview {
			...workerEducationOverviewFragment
		}

		... on UserTermsOfService {
			id
			agreedAt
			termsOfService {
				id
				url
				version
			}
		}

		... on CombinationOnboardingPart {
			id
			workerAddress {
				...workerAddressFragment
			}
			workingAuthorization {
				...workingAuthorizationFragment
			}
			workingPreferredLocations {
				id
				preferredWorkingState
			}
			emergencyContact {
				...emergencyContactFragment
			}
		}
	}

	${WORKER_CETIFICATION_OVERVIEW_FRAGMENT}
	${WORK_EXPERIENCE_OVERVIEW_FRAMENT}
	${WORKER_REFERENCE_OVERVIEW_FRAGMENT}
	${WORKER_EDUCATION_OVERVIEW_FRAGMENT}
	${WORKER_ADDRESS_FRAGMENT}
	${WORKING_AUTHORIZATION_FRAGMENT}
	${EMERGENCY_CONTACT_FRAGMENT}
`

export const WORKER_ONBOARDING_PART_FRAGMENT = gql`
	fragment workerOnboardingPartFragment on WorkerOnboardingPart {
		id
		integrationConfigurations
		integrationObject
		state
		integration {
			...workerOnboardingPartIntegrationFragment
		}
	}

	${WORKER_ONBOARDING_PART_INTEGRATION_FRAGMENT}
`

export const REFERRED_TRAVELER_FRAGMENT = gql`
	fragment referredTravelerFragment on ReferredTraveler {
		id
		firstName
		lastName
		email
		phone
		relationship
		discipline
	}
`
export const WORKER_SKILL_CHECKLIST_FRAGMENT = gql`
	fragment workerSkillChecklistFragment on WorkerSkillChecklist {
		createdAt
		dueDate
		expirationDate
		externalUrl
		id
		name
		status
		state
		worker {
			...workerFragment
		}
	}

	${WORKER_FRAGMENT_SHORT}
`

export const SKILL_CHECKLIST_FRAGMENT = gql`
	fragment skillChecklistFragment on SkillChecklist {
		createdAt
		id
		name
		workerSkillChecklist {
			...workerSkillChecklistFragment
		}
	}

	${WORKER_SKILL_CHECKLIST_FRAGMENT}
`

export const WORKER_ASSIGNMENT_FRAGMENT = gql`
	fragment workerAssignmentFragment on WorkerAssignment {
		id
		startDate
		endDate
		extendAssignment {
			id
			status
		}
		job {
			...jobFragment
		}
		account {
			id
			name
		}
	}

	${JOB_FRAGMENT}
`
