import moment from 'moment'
import { entries, flatMap, includes, isEmpty, isEqual, keyBy, omit, round, toNumber } from 'lodash'
import { nanoid } from 'nanoid'
import { apolloClient, GET_SIGNED_URLS, GET_ALL_WORKER_ASSIGNMENTS_QUERY } from '~/common/apollo'
import { action, computed, observable, store, toJS } from '~/common/mobx.decorator'
import { appStore, authStore, notifyStore, routerStore } from '~/stores'
import {
	CREATE_WORKED_TIMESHEET_MUTATION,
	DESTROY_FILE_MUTATION,
	DESTROY_FILES_MUTATION,
	DESTROY_WORKED_SHIFT_MUTATION,
	DESTROY_WORKER_EXPENSE_MUTATION,
	GET_PREVIOUS_WORKED_TIMESHEET_QUERY,
	UPDATE_WORKED_TIMESHEET_MUTATION,
} from '../../care-timecards.graphql'
import {
	COLECTIONS,
	DATE_FORMAT_SAVE,
	MASTER_DATA_FIELDS,
	MASTER_DATA_OBJECTS,
	MONTH_DAY_FORMAT_VIEW,
	PATHS,
	TIME_FORMAT_SAVE,
	TIME_FORMAT_VIEW_TIMECARD,
	WEEK_MANAGEMENT_FILTER_ENUM,
} from '~/common/constants'
import Axios from 'axios'
import { delay, equalsId, uniqueShifts } from '~/common/helpers'
import { eventClient } from '@opus/web.core.lib.event-tracking'
import { clearAllDataStorageFx, loadDataStorageFx, syncDocumentEvent } from '~/common/helpers/sync.helper'
import { combineRealAndStoragedData, hasTimecardData } from '~/common/helpers/auto-save.helper'

const TEMP_ID = 'TEMP_ID'
@store()
class PreviousTabStore {
	@observable workedTimesheet
	@observable previousWeek
	@observable facilitySigned
	@observable isSpecialWorkWeek

	@observable currentJob = null

	@observable shifts = []
	@observable reimbursements = []
	@observable notes
	@observable preEmploymentHours
	@observable didNotWork
	@observable timesheetImages = []

	@observable selectedShift
	@observable selectedReimbursement

	@observable showInstruction
	@observable showLateSubmission

	@observable showSuccessSubmitted

	@observable categories = []

	@observable storagedData
	@observable hasTempStoragedData = false
	@observable isLoadingUploadReceipt = false
	@observable isLoadingUploadTimecard = false
	@observable activeWeekEdit = false

	@observable workerAssignments = []
	@observable selectedAssignment = null
	@observable multiStoragedData = []

	@computed
	get facilityOptions() {
		return this.workerAssignments?.map((assignment) => ({ label: assignment?.account?.name, value: assignment?.id }))
	}

	@action
	setSelectedAssignment = async (assignmentId) => {
		const assignment = this.workerAssignments?.find((assignment) => assignment?.id === assignmentId)

		if (!isEmpty(assignment)) {
			this.selectedAssignment = assignment
			await this.resetAttributeOfAssignment()
			await this.setAttributeOfAssignment()
			await this.fetchWorkedTimesheet()
		}
	}

	@computed
	get appliedStorageIds() {
		return this.multiStoragedData.map((item) => item.id)
	}
	@computed
	get showStoragedDataConfirm() {
		return !includes(this.appliedStorageIds, this.submitData.id) && !!this.storagedData
	}

	@computed
	get linkItems() {
		return this.selectedAssignment?.account?.timekeepingDocuments || []
	}

	@computed
	get initialValues() {
		let faciltyVal = this.facilityOptions[0]?.value || null

		if (!isEmpty(this.selectedAssignment) && this.selectedAssignment?.id) {
			faciltyVal = this.selectedAssignment?.id
		}

		return {
			facility: faciltyVal,
		}
	}

	@computed
	get hasTempCurrentShifts() {
		if (this.didNotWork) return false
		if (isEmpty(this.workedTimesheet) && isEmpty(this.shifts)) return false
		if (isEmpty(this.workedTimesheet?.workedShifts) && isEmpty(this.shifts)) return false
		return !isEqual(this.workedTimesheet?.workedShifts?.slice().sort(), this.shifts?.slice().sort()) ? true : false
	}

	@computed
	get hasTempReimbursements() {
		if (isEmpty(this.workedTimesheet) && isEmpty(this.reimbursements)) return false
		if (isEmpty(this.workedTimesheet?.workerExpenses) && isEmpty(this.reimbursements)) return false

		const modWokerExpenses = this.workedTimesheet?.workerExpenses
			?.map((item) => {
				let modReceiptImages = null
				if (!isEmpty(item.receiptImages)) {
					modReceiptImages = item.receiptImages.map((receiptItem) => omit(receiptItem, ['fileUrl']))
				}
				return { ...item, receiptImages: modReceiptImages }
			})
			.sort()

		const modReimbursements = this.reimbursements
			?.map((item) => {
				let modReceiptImages = null
				if (!isEmpty(item.receiptImages)) {
					modReceiptImages = item.receiptImages.map((receiptItem) => omit(receiptItem, ['fileUrl']))
				}
				return { ...item, receiptImages: modReceiptImages }
			})
			.sort()

		return !isEqual(modWokerExpenses, modReimbursements) ? true : false
	}
	@computed
	get hasTempTimesheetImages() {
		const timesheetImages = this.timesheetImages?.filter((image) => !image._destroy)

		if (isEmpty(this.workedTimesheet) && isEmpty(timesheetImages)) return false
		if (isEmpty(this.workedTimesheet?.timesheetImages) && isEmpty(timesheetImages)) return false
		return !equalsId(this.workedTimesheet?.timesheetImages?.slice().sort(), timesheetImages?.slice().sort()) ? true : false
	}
	@computed
	get hasTempNote() {
		if (isEmpty(this.workedTimesheet) && isEmpty(this.notes)) return false
		if (isEmpty(this.workedTimesheet?.notes) && isEmpty(this.notes)) return false
		return !isEqual(this.workedTimesheet?.notes, this.notes) ? true : false
	}
	@computed
	get hasTempPreEmploymentHours() {
		if (isEmpty(this.workedTimesheet) && !this.preEmploymentHours) return false
		return !isEqual(this.workedTimesheet?.preEmploymentHours, this.preEmploymentHours) ? true : false
	}
	@computed
	get hasTempDidNotWork() {
		if (isEmpty(this.workedTimesheet) && isEmpty(this.didNotWork)) return false
		return !isEqual(this.workedTimesheet?.didNotWork, this.didNotWork) ? true : false
	}

	@computed
	get signedBlobIds() {
		return this.timesheetImages?.filter((image) => !image._destroy && image.signedBlobId).map((image) => image.signedBlobId)
	}

	@computed
	get hasCurrentJob() {
		return !isEmpty(this.currentJob)
	}

	@computed
	get hasAllowEdit() {
		return this.workedTimesheet !== null && !this.isApprovedOrRejected && this.activeWeekEdit
	}
	@computed
	get hasShowTooltip() {
		return this.workedTimesheet !== null && this.isApprovedOrRejected
	}
	@computed
	get isApprovedOrRejected() {
		return this.workedTimesheet?.status === 'approved' || this.workedTimesheet?.status === 'rejected'
	}

	@computed
	get removeImageIds() {
		return this.timesheetImages?.filter((image) => image._destroy && !image.id?.startsWith(TEMP_ID)).map((image) => image.id) || []
	}

	@computed
	get removeReceiptIds() {
		return (
			flatMap(this.reimbursements, (re) =>
				re.receiptImages?.filter((receipt) => receipt._destroy && !receipt.id?.startsWith(TEMP_ID)).map((receipt) => receipt.id)
			) || []
		)
	}

	@computed
	get submitData() {
		const { startDate, endDate } = this.previousWeek || {}

		return {
			id: this.workedTimesheet?.id || `${TEMP_ID}_${authStore.id}_${this.selectedAssignment?.id}_${startDate}_${endDate}`,
			workerId: authStore.id,
			startDate,
			endDate,
			notes: this.notes,
			preEmploymentHours: this.preEmploymentHours,
			didNotWork: this.didNotWork,
			shifts: this.shifts,
			reimbursements: this.reimbursements,
			signedBlobIds: this.signedBlobIds,
			timesheetImages: this.timesheetImages,
		}
	}

	@computed
	get enableSubmitBtn() {
		if (!this.previousWeek?.active) return false

		if (this.didNotWork) return true

		const { hasDuplicate } = uniqueShifts(this.shiftItems)
		if (hasDuplicate) return false

		return (
			this.hasImages &&
			(this.hasTempTimesheetImages ||
				this.hasTempCurrentShifts ||
				this.hasTempReimbursements ||
				this.hasTempNote ||
				this.hasTempPreEmploymentHours ||
				this.hasTempDidNotWork)
		)
	}

	@computed
	get hasImages() {
		return this.submitData?.timesheetImages?.filter((image) => !image._destroy)?.length > 0
	}

	@computed
	get weekDescription() {
		const [startDate, endDate] = [moment(this.previousWeek?.startDate, DATE_FORMAT_SAVE), moment(this.previousWeek?.endDate, DATE_FORMAT_SAVE)]

		return `(${startDate.format(MONTH_DAY_FORMAT_VIEW)} - ${endDate.format(MONTH_DAY_FORMAT_VIEW)})`
	}

	@computed
	get typeOptions() {
		return entries(this.selectedAssignment?.shiftTypes).map(([value, label]) => ({ value, label }))
	}

	@computed
	get categoryOptions() {
		return this.categories
	}

	@computed
	get photoImages() {
		return this.timesheetImages?.filter((image) => !image._destroy)
	}

	@computed
	get shiftItems() {
		const typeKeys = keyBy(this.typeOptions, 'value')
		return this.shifts
			?.filter((shift) => !shift._destroy)
			.map((shift) => {
				const hours = moment.duration(moment(shift.endTime, TIME_FORMAT_SAVE).diff(moment(shift.startTime, TIME_FORMAT_SAVE))).asHours()
				const mealHours =
					moment.duration(moment(shift.firstBreakEndTime, TIME_FORMAT_SAVE).diff(moment(shift.firstBreakStartTime, TIME_FORMAT_SAVE))).asHours() || 0
				const secondMealHours =
					moment.duration(moment(shift.secondBreakEndTime, TIME_FORMAT_SAVE).diff(moment(shift.secondBreakStartTime, TIME_FORMAT_SAVE))).asHours() || 0

				const finalHours =
					(hours < 0 ? 24 + hours : hours) - (mealHours < 0 ? 24 + mealHours : mealHours) - (secondMealHours < 0 ? 24 + secondMealHours : secondMealHours)

				return {
					title: moment(shift.workDate).format('ddd'),
					description: typeKeys[shift.taskExternalId]?.label,
					meta: `${round(finalHours, 2)} hours`,
					finalHours: round(finalHours, 2),
					startEnd: `${moment(shift.startTime, TIME_FORMAT_SAVE).format(TIME_FORMAT_VIEW_TIMECARD)} - ${moment(shift.endTime, TIME_FORMAT_SAVE).format(
						TIME_FORMAT_VIEW_TIMECARD
					)}`,
					__shift: shift,
				}
			})
	}

	@computed
	get reimbursementItems() {
		return this.reimbursements
			?.filter((im) => !im._destroy)
			.map((im) => ({ title: im.category, meta: `$${round(im.amount, 2).toFixed(2)}`, __reimbursement: im }))
	}

	@action
	resetAttributeOfAssignment = async () => {
		this.workedTimesheet = null
		this.previousWeek = null
		this.isSpecialWorkWeek = null
		this.facilitySigned = null
		this.currentJob = null

		this.shifts = []
		this.reimbursements = []
		this.timesheetImages = []
		this.notes = ''
		this.preEmploymentHours = null
		this.didNotWork = null
	}

	@action
	setAttributeOfAssignment = async () => {
		this.previousWeek = this.selectedAssignment?.previousWorkWeek
		this.isSpecialWorkWeek = this.selectedAssignment?.isSpecialWorkWeek
		this.facilitySigned = this.selectedAssignment?.facilitySigneds
		this.currentJob = this.selectedAssignment?.job

		if (isEmpty(this.currentJob)) {
			await delay(1500)
			await this.clearStoragedData(this.submitData?.id)
		}
	}

	@action
	fetchAllWorkerAssignments = async () => {
		const response = await apolloClient.query({
			query: GET_ALL_WORKER_ASSIGNMENTS_QUERY,
			variables: { filterScope: 'timecards', weekManagementScope: WEEK_MANAGEMENT_FILTER_ENUM.previous_week },
		})

		const workerAssignments = response?.data?.workerAssignments
		if (!isEmpty(workerAssignments)) {
			this.workerAssignments = workerAssignments

			if (workerAssignments.length > 1) {
				const selectedAssignmentId = this.selectedAssignment?.id
				const facilityOption = this.facilityOptions?.find((facility) => facility?.value === selectedAssignmentId)

				if (isEmpty(selectedAssignmentId) || !isEmpty(facilityOption)) {
					this.setSelectedAssignment(selectedAssignmentId || workerAssignments[0]?.id)
				}
			} else {
				const selectedAssignmentId = this.workerAssignments[0]?.id
				this.setSelectedAssignment(selectedAssignmentId)
			}
		}
	}

	@action
	fetchWorkedTimesheet = async () => {
		const response = await apolloClient.query({ query: GET_PREVIOUS_WORKED_TIMESHEET_QUERY, variables: { workerAssignmentId: this.selectedAssignment?.id } })
		this.setWorkedTimesheet(response?.data?.previousWorkedTimesheet)

		await delay(500)
		await this.fetchStoragedData(this.submitData?.id)
	}
	@action
	handleUpdateData = async (idData) => {
		const response = await apolloClient.query({
			query: GET_SIGNED_URLS,
			variables: {
				blobIds: [idData],
			},
		})
		const id = response?.data?.signedUrls?.[0]?.id
		const fileUrl = response?.data?.signedUrls?.[0]?.fileUrl

		if (this.selectedReimbursement) {
			this.selectedReimbursement.receiptImages = this.selectedReimbursement.receiptImages
				.filter((item) => !item.hasOwnProperty('_destroy'))
				.map((item) => (item.blobId === id ? { ...item, fileUrl: fileUrl } : item))
		}
		if (this.timesheetImages) {
			this.timesheetImages = this.timesheetImages
				.filter((item) => !item.hasOwnProperty('_destroy'))
				.map((item) => (item.blobId === id ? { ...item, fileUrl: fileUrl } : item))
		}

		await this.saveStoragedData(this.submitData.id)
	}

	@action
	fetchStoragedData = async (id) => {
		const fetch = includes(this.appliedStorageIds, id)
		if (fetch) {
			const storaged = this.multiStoragedData.find((item) => item.id === id)
			if (storaged) {
				this.storagedData = storaged.data
				this.onApplyStoragedData()
			}

			return
		}
		const document = await loadDataStorageFx({ companyId: process.env['REACT_APP_COMPANY_ID'], collection: COLECTIONS.time_cards, document: id })

		if (document?.data) {
			try {
				const documentData = JSON.parse(document?.data)
				if (typeof documentData === 'object' && documentData) {
					this.storagedData = documentData
				}
			} catch (error) {
				console.warn('🚀 ~ CareActivationStore ~ Object.values ~ error:', error)
			}
		} else {
			this.storagedData = null
		}
	}

	@action
	onApplyStoragedData = async () => {
		this.shifts = combineRealAndStoragedData(this.submitData?.shifts, this.storagedData?.shifts)
		this.reimbursements = combineRealAndStoragedData(this.submitData?.reimbursements, this.storagedData?.reimbursements)
		this.timesheetImages = combineRealAndStoragedData(this.submitData?.timesheetImages, this.storagedData?.timesheetImages)
		this.notes = this.storagedData?.notes
		this.preEmploymentHours = this.storagedData?.preEmploymentHours
		this.didNotWork = this.storagedData?.didNotWork
		if (includes(this.appliedStorageIds, this.submitData.id)) {
			this.multiStoragedData = this.multiStoragedData.map((item) => (item.id === this.submitData.id ? { ...item, data: this.storagedData } : item))
		} else {
			this.multiStoragedData = [...this.multiStoragedData, { id: this.submitData.id, data: this.storagedData }]
		}

		this.storagedData = null
		this.hasTempStoragedData = true
	}

	@action
	onCancelApplyStoragedData = async () => {
		this.clearStoragedData(this.storagedData.id)
	}

	@action
	saveStoragedData = async (id) => {
		if (!this.hasCurrentJob) {
			return
		}

		await delay(500)
		if (!isEmpty(this.submitData) && hasTimecardData({ ...this.submitData })) {
			const document = {
				name: id,
				collection: COLECTIONS.time_cards,
				data: { ...this.submitData },
			}

			syncDocumentEvent(document)
			this.multiStoragedData = this.multiStoragedData.map((item) => (item.id === id ? { ...item, data: this.submitData } : item))
			this.hasTempStoragedData = true
		} else {
			this.clearStoragedData(this.submitData.id)
		}
	}

	@action
	clearStoragedData = async (id) => {
		clearAllDataStorageFx({ companyId: process.env['REACT_APP_COMPANY_ID'], collection: COLECTIONS.time_cards, document: id })

		this.storagedData = null
		this.hasTempStoragedData = false
	}

	@action
	setWorkedTimesheet = async (timesheet) => {
		this.workedTimesheet = timesheet
		this.shifts =
			this.workedTimesheet?.workedShifts?.map((shift) => ({
				...shift,
				startTime: shift.startTime && moment(shift.startTime, TIME_FORMAT_SAVE).format(TIME_FORMAT_SAVE),
				endTime: shift.endTime && moment(shift.endTime, TIME_FORMAT_SAVE).format(TIME_FORMAT_SAVE),
				firstBreakStartTime: shift.firstBreakStartTime && moment(shift.firstBreakStartTime, TIME_FORMAT_SAVE).format(TIME_FORMAT_SAVE),
				firstBreakEndTime: shift.firstBreakEndTime && moment(shift.firstBreakEndTime, TIME_FORMAT_SAVE).format(TIME_FORMAT_SAVE),
				secondBreakStartTime: shift.secondBreakStartTime && moment(shift.secondBreakStartTime, TIME_FORMAT_SAVE).format(TIME_FORMAT_SAVE),
				secondBreakEndTime: shift.secondBreakEndTime && moment(shift.secondBreakEndTime, TIME_FORMAT_SAVE).format(TIME_FORMAT_SAVE),
			})) || []
		this.reimbursements = this.workedTimesheet?.workerExpenses || []

		this.preEmploymentHours = this.workedTimesheet?.preEmploymentHours
		this.didNotWork = this.workedTimesheet?.didNotWork
		this.notes = this.workedTimesheet?.notes
		this.timesheetImages = this.workedTimesheet?.timesheetImages || []
	}

	@action
	fetchWorkerExpenseCategory = async () => {
		const response = await Axios.get(`/company_configs/${appStore.id}/salesforce_picklists`, {
			params: {
				object: MASTER_DATA_OBJECTS.WorkerExpense,
				field: MASTER_DATA_FIELDS.category,
			},
		})

		this.categories = response.data?.salesforcePicklists
	}

	@action
	handleShowInstruction = async () => {
		this.showInstruction = true
	}

	@action
	handleShowSuccessSubmitted = async () => {
		this.showSuccessSubmitted = true
	}

	@action
	handleCloseInstruction = async () => {
		this.showInstruction = null
	}
	@action
	handleShowLateSubmission = async () => {
		this.showLateSubmission = true
	}

	@action
	handleCloseLateSubmission = async () => {
		this.showLateSubmission = null
	}

	@action
	handleNavigateTimeCardDetail = async () => {
		this.showSuccessSubmitted = null
		const timecardId = this.workedTimesheet.id
		routerStore.goPage(`${PATHS.care.timecards}/${timecardId}`)
	}

	@action
	onClickShowShift = async (values) => {
		this.selectedShift = values || { id: `${TEMP_ID}_${nanoid()}`, __new: true }
	}

	@action
	onCancelShowShift = async () => {
		this.selectedShift = null
	}

	@action
	onSubmitShowShift = async (values) => {
		if (!values?.__new) {
			this.shifts = this.shifts?.map((shift) => (shift.id === values.id ? values : shift))
		} else {
			this.shifts = [...this.shifts, { ...values, __new: false }]
		}

		this.selectedShift = null

		await this.saveStoragedData(this.submitData?.id)
	}

	@action
	onSubmitDeleteShift = async () => {
		if (!this.selectedShift?.id?.startsWith(TEMP_ID)) {
			await apolloClient.mutate({ mutation: DESTROY_WORKED_SHIFT_MUTATION, variables: { id: this.selectedShift.id } })
		}

		this.shifts = this.shifts.filter((shift) => shift.id !== this.selectedShift.id)
		if (this.workedTimesheet) {
			this.workedTimesheet.workedShifts = this.workedTimesheet.workedShifts?.filter((shift) => shift.id !== this.selectedShift.id)
		}

		this.selectedShift = null

		await this.saveStoragedData(this.submitData?.id)
	}

	@action
	onClickShowReimbursement = async (values) => {
		this.selectedReimbursement = values || { id: `${TEMP_ID}_${nanoid()}`, __new: true, category: '' }
	}

	@action
	onCancelShowReimbursement = async () => {
		this.selectedReimbursement = null
	}

	@action
	onSubmitShowReimbursement = async (values) => {
		if (!values?.__new) {
			this.reimbursements = this.reimbursements?.map((im) => (im.id === values?.id ? values : im))
		} else {
			this.reimbursements = [...this.reimbursements, { ...values, __new: false }]
		}

		this.selectedReimbursement = null

		this.saveStoragedData(this.submitData?.id)
	}

	@action
	onSubmitDeleteReimbursement = async () => {
		if (!this.selectedReimbursement?.id?.startsWith(TEMP_ID)) {
			await apolloClient.mutate({ mutation: DESTROY_WORKER_EXPENSE_MUTATION, variables: { id: this.selectedReimbursement.id } })
		}

		this.reimbursements = this.reimbursements?.filter((im) => this.selectedReimbursement?.id !== im.id)
		if (this.workedTimesheet) {
			this.workedTimesheet.workerExpenses = this.workedTimesheet.workerExpenses?.filter((im) => im.id !== this.selectedReimbursement?.id)
		}
		this.selectedReimbursement = null

		await this.saveStoragedData(this.submitData?.id)
	}

	@action
	onSubmitCurrentTimesheet = async () => {
		const variables = toJS(this.submitData)

		if (typeof variables.preEmploymentHours === 'string') {
			return
		}

		variables.reimbursements = variables.reimbursements?.map(({ id, __new, _destroy, receiptImages, amount, fileUrl, ...im }) => {
			const isTemp = id?.startsWith(TEMP_ID)

			if (isTemp && _destroy) {
				return null
			}

			const signedBlobIds = receiptImages?.filter((r) => !r._destroy && r.id?.startsWith(TEMP_ID) && r.signedBlobId).map((r) => r.signedBlobId) || []

			return {
				...im,
				id: !isTemp ? id : undefined,
				_destroy,
				amount: toNumber(amount),
				signedBlobIds,
			}
		})

		variables.shifts = variables.shifts
			?.map(({ __new, _destroy, id, firstBreakStartTime, firstBreakEndTime, secondBreakStartTime, secondBreakEndTime, ...shift }) => {
				const isTemp = id?.startsWith(TEMP_ID)

				if (isTemp && _destroy) {
					return null
				}

				return {
					...shift,
					id: !isTemp ? id : undefined,
					firstBreakStartTime: firstBreakStartTime || null,
					firstBreakEndTime: firstBreakEndTime || null,
					secondBreakStartTime: secondBreakStartTime || null,
					secondBreakEndTime: secondBreakEndTime || null,
					_destroy: this.didNotWork ? true : _destroy,
				}
			})
			?.filter((s) => s)

		try {
			const removeFileIds = [...this.removeReceiptIds]
			if (removeFileIds?.length > 0) {
				await apolloClient.mutate({ mutation: DESTROY_FILES_MUTATION, variables: { ids: removeFileIds } })
			}

			const response = this.workedTimesheet?.id
				? await apolloClient.mutate({
						mutation: UPDATE_WORKED_TIMESHEET_MUTATION,
						variables: { ...variables, id: this.workedTimesheet?.id, removeFileIds: this.removeImageIds, workerAssignmentId: this.selectedAssignment?.id },
				  })
				: await apolloClient.mutate({
						mutation: CREATE_WORKED_TIMESHEET_MUTATION,
						variables: { ...variables, weekManagementId: this.previousWeek?.id, workerAssignmentId: this.selectedAssignment?.id },
				  })

			const timesheet = this.workedTimesheet?.id ? response?.data?.updateWorkedTimesheet : response?.data?.createWorkedTimesheet
			this.setWorkedTimesheet(timesheet)
			this.showSuccessSubmitted = true
			this.clearStoragedData(this.submitData?.id)
		} catch (error) {
			notifyStore.error(error?.message)
		}
	}

	@action
	onChangePreHours = async (event) => {
		this.preEmploymentHours = event?.target?.value && toNumber(event?.target?.value)
		this.saveStoragedData(this.submitData.id)
	}

	@action
	onChangeDidNotWork = async (event) => {
		this.didNotWork = event?.target?.value
		this.saveStoragedData(this.submitData.id)
	}

	@action
	onChangeNotes = async (event) => {
		this.notes = event?.target?.value
		this.saveStoragedData(this.submitData.id)
	}

	@action
	onChangePhotoUpload = async ({ values, isLoading }) => {
		let eventId = 'upload-timecard_photo_btn'
		this.isLoadingUploadTimecard = isLoading
		eventClient.logClickEvent(eventId, null).catch((error) => console.log(`Error tracking data ${eventId}`, error.message))
		this.timesheetImages = [...this.timesheetImages, values]
		await this.saveStoragedData(this.submitData.id)
	}

	@action
	onSubmitDeleteTimesheetImage = async (id) => {
		let eventId = 'delete-timecard_photo_btn'
		eventClient.logClickEvent(eventId, null).catch((error) => console.log(`Error tracking data ${eventId}`, error.message))

		if (!id.startsWith(TEMP_ID)) {
			this.timesheetImages = this.timesheetImages?.map((image) => {
				if (image.id === id) return { ...image, _destroy: true }
				return image
			})
		} else {
			this.timesheetImages = this.timesheetImages?.filter((image) => image.id !== id)
		}

		await this.saveStoragedData(this.submitData?.id)
	}

	@action
	onUploadReceipt = async ({ values }) => {
		return values
	}

	@action
	onSubmitDeleteReceipt = async (id) => {
		try {
			if (!id?.startsWith(TEMP_ID)) {
				await apolloClient.mutate({ mutation: DESTROY_FILE_MUTATION, variables: { id } })
			}

			this.reimbursements = this.reimbursements.map((reim) => {
				if (this.selectedReimbursement?.id === reim.id) {
					if (id.startsWith(TEMP_ID)) {
						const receiptImages = reim.receiptImages?.filter((image) => image.id !== id)
						return { ...reim, receiptImages }
					}

					return { ...reim }
				}

				return reim
			})

			if (this.workedTimesheet) {
				this.workedTimesheet.workerExpenses = this.workedTimesheet?.workerExpenses?.map((reim) => {
					if (this.selectedReimbursement?.id === reim.id) {
						if (id.startsWith(TEMP_ID)) {
							const receiptImages = reim.receiptImages?.filter((image) => image.id !== id)
							return { ...reim, receiptImages }
						}

						return { ...reim }
					}
					return reim
				})
			}

			await this.saveStoragedData(this.submitData?.id)
		} catch (error) {
			notifyStore.error(error.message)
		}
	}

	@action
	resetSelectedWorkerAssignment = () => {
		this.workerAssignments = []
		this.selectedAssignment = null
	}

	@action
	onUnmountTab = () => {
		this.workedTimesheet = null
		this.previousWeek = null
		this.shifts = []
		this.reimbursements = []
		this.notes = null
		this.preEmploymentHours = null
		this.didNotWork = null
		this.timesheetImages = []
		this.storagedData = null
		this.hasTempStoragedData = false
		this.multiStoragedData = []
	}
}

export const previousTabStore = new PreviousTabStore()
