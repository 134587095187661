import { gql } from '@apollo/client'

export const LOOK_UP_LOCATION_QUERY = gql`
	query lookupLocationQuery($zipcode: String!) {
		lookUpLocation(zipcode: $zipcode) {
			id
			city
			country
			countryCode
			latitude
			longitude
			state
			stateCode
			zipcode
		}
	}
`
