import { makeStyles } from '@material-ui/core'

export const useStyle = makeStyles((theme) => ({
	root: {
		backgroundColor: 'white',
		padding: '12px 16px',
		minWidth: 'unset',
	},

	action: {
		display: 'flex',
		gap: '1rem',
		color: 'black',
		padding: '0',

		'& .message': {
			fontWeight: 600,
			color: '#297704',
		},
	},
}))
