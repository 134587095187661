import React from 'react'
import { Checkbox as MuiCheckbox } from '@material-ui/core'
import { ReactComponent as UncheckedSvg } from './unchecked.svg'
import { ReactComponent as CheckedSvg } from './checked.svg'
import { css } from 'styled-components'

export const checkboxStyle = css`
	color: #82cecf;
`

export const Checkbox = (props) => {
	return <MuiCheckbox css={checkboxStyle} classes={{}} icon={<UncheckedSvg />} checkedIcon={<CheckedSvg />} {...props} />
}
