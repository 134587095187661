import React from 'react'
import { fileNameElisips } from '~/components/fields/file-upload-field/file-upload-field.style'
import { convertFileNameEllipsis } from '~/common/helpers'
import { FIELD_MODE } from '~/common/constants'
import { Box, IconButton } from '@material-ui/core'
import { RemoveSvg } from '~/components/icons'
import { GET_SIGNED_URLS, apolloClient } from '~/common/apollo'

function UploadListImage({ mode, onClick, item, onClick1, handleErrorImage, refresh = false, setRefreshImage }) {
	const isRenderEdit = mode === FIELD_MODE.edit || (mode === FIELD_MODE.viewDisable && item.hasOwnProperty('signedBlobId'))
	// const [refreshImage, setRefreshImage] = useState(null)
	return (
		<Box
			onClick={onClick}
			style={{
				// padding: '10px 10px 10px 0',
				cursor: 'pointer',
				position: 'relative',
				maxWidth: '90px',
				minWidth: '90px',
				margin: '10px 10px 10px 0px',
			}}
		>
			<img
				key={item?.fileUrl}
				onError={async () => {
					try {
						if (refresh) {
							const response = await apolloClient.query({
								query: GET_SIGNED_URLS,
								variables: {
									blobIds: [item.blobId],
								},
							})
							const fileUrl = response?.data?.signedUrls?.[0]?.fileUrl
							setRefreshImage && setRefreshImage({ ...item, fileUrl })
						} else {
							handleErrorImage && handleErrorImage(item?.blobId)
						}
					} catch (error) {
						console.error(error)
					}
				}}
				style={{
					width: 80,
					height: 80,
					borderRadius: 8,
				}}
				src={item?.fileUrl || item?.imageUrls?.s_500x500 || item?.src}
				alt=""
			/>
			<p css={fileNameElisips}>{convertFileNameEllipsis(item?.filename)}</p>
			{isRenderEdit && (
				<IconButton style={{ position: 'absolute', top: '5px', right: '20px' }} size="small" onClick={onClick1}>
					<RemoveSvg />
				</IconButton>
			)}
		</Box>
	)
}

export default UploadListImage
