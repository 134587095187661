import React from 'react'
import { useSnackbar } from 'notistack'
import { observer, autorun } from '~/common/mobx.decorator'
import { notifyStore } from '~/stores'
import { Button } from '~/components/button'
import { useTranslation } from '@opus/web.core.hooks.use-translation'
import { NotifyDialog } from './notify-dialog.component'
import { defaultTo } from 'lodash'
import { Typography } from '../typography'
import { SnackbarCustomization } from '../snackbar'

export const GlobalNotify = observer(() => {
	const { t } = useTranslation()
	const { waitDialogs, doneDialogs, waitMessages, doneMessages } = notifyStore

	const { enqueueSnackbar, closeSnackbar } = useSnackbar()
	autorun(() => {
		waitMessages.forEach((note) => {
			const { key, message, options } = note
			if (doneMessages[key]) {
				return
			}

			const actions = options.persist ? [...options.actions, { label: 'DISMISS' }] : options.actions

			enqueueSnackbar(t(message), {
				key,
				...options,
				...(options?.variant === 'success' && {
					content: (key) => <SnackbarCustomization variant={options?.variant} key={key} message={t(message)} />,
				}),
				action: () =>
					actions?.map(({ onClick, label, keepSnackbar, ...rest }) => {
						const handleClick = () => {
							if (typeof onClick === 'function') {
								onClick()
							}

							if (!keepSnackbar) {
								closeSnackbar(key)
							}
						}

						return (
							<Button key={key} {...rest} onClick={handleClick}>
								{t(label)}
							</Button>
						)
					}),
			})

			notifyStore.closeMessage(key)
		})
	})

	return waitDialogs.map(({ key, message, options }) => {
		const { title, onOk, onCancel, okText, cancelText, actionStyle, contentStyle } = options || {}

		const handleOnOk = () => {
			onOk(key)
		}

		const handleOnCancel = () => {
			onCancel(key)
		}

		return (
			<NotifyDialog
				key={key}
				open={defaultTo(doneDialogs[key], true)}
				title={title}
				onCancel={options?.onCancel ? () => handleOnCancel() : undefined}
				onClose={() => notifyStore.closeDialog(key)}
				onOk={options?.onOk ? () => handleOnOk() : () => notifyStore.closeDialog(key)}
				okText={okText}
				cancelText={cancelText}
				actionStyle={actionStyle}
				contentStyle={contentStyle}
			>
				<Typography whiteSpace="pre-line" variant="body1" className="content">
					{t(message)}
				</Typography>
			</NotifyDialog>
		)
	})
})
