import gql from 'graphql-tag'

export const WORKED_SHIFT_FRAGMENT = gql`
	fragment workedShiftFragment on WorkedShift {
		id
		workDate
		taskExternalId

		startTime
		endTime

		firstBreakStartTime
		firstBreakEndTime

		secondBreakStartTime
		secondBreakEndTime
	}
`

export const WORKED_EXPENSE_FRAGMENT = gql`
	fragment workedExpenseFragment on WorkerExpense {
		id
		category
		amount
		notes
		receiptImages {
			id
			filename
			fileUrl
			blobId
		}
	}
`

export const WORKED_TIMESHEET_FRAGMENT = gql`
	fragment workedTimesheetFragment on WorkedTimesheet {
		id
		notes
		startDate
		endDate
		preEmploymentHours
		status
		updatedAt
		didNotWork
	}
`

export const GET_CURRENT_WEEK_QUERY = gql`
	query {
		currentWeekManagement {
			id
			startDate
			endDate
		}
	}
`

export const GET_WORKER_ASSIGNMENT_TIMECARD_INSTRUCTIONS_QUERY = gql`
	query {
		currentWorkerAssignment {
			id
			account {
				callOffInstructions
				callOffPolicy
				covidVaccineRequirement
				createdAt
				dob
				guaranteedWorkWeekPolicy
				id
				license
				name
				preScreenRequired
				ssn
				timeCorrectionForm
				timeCorrectionProcess
				timeSubmissionRequirements
				timecard
				timecardDeadline
				timecardDueDate
				translatedCreditStatus
				travelExperience
				updatedAt
				workWeek
				x2RefsRequired
				yearOfExperience
			}
		}
	}
`

export const GET_WORKER_ASSIGNMENT_TIMECARD_INSTRUCTIONS_BY_ID_QUERY = gql`
	query getWorkerAssignmentByIdQuery($id: String!) {
		workerAssignment(id: $id) {
			id
			account {
				callOffInstructions
				callOffPolicy
				covidVaccineRequirement
				createdAt
				dob
				guaranteedWorkWeekPolicy
				id
				license
				name
				preScreenRequired
				ssn
				timeCorrectionForm
				timeCorrectionProcess
				timeSubmissionRequirements
				timecard
				timecardDeadline
				timecardDueDate
				translatedCreditStatus
				travelExperience
				updatedAt
				workWeek
				x2RefsRequired
				yearOfExperience
			}
		}
	}
`

export const GET_WORKER_ASSIGNMENT_QUERY = gql`
	query {
		currentWorkerAssignment {
			id
			workerTasks {
				externalId
				name
				available
			}
			currentWorkWeek {
				id
				active
				startDate
				endDate
			}
			previousWorkWeek {
				id
				active
				startDate
				endDate
			}
			shiftTypes
			facilitySigned
			isSpecialWorkWeek
		}
	}
`

export const GET_WORKED_TIMESHEET_QUERY = gql`
	query getWorkedTimesheet($id: String!) {
		workedTimesheet(id: $id) {
			...workedTimesheetFragment

			job {
				title
			}

			timesheetImages {
				blobId
				id
				filename
				fileUrl
			}

			workedShifts {
				...workedShiftFragment
			}

			workerExpenses {
				...workedExpenseFragment
			}

			workerAssignment {
				id
				shiftTypes
				startDate
				endDate
			}

			weekManagement {
				active
				endDate
				id
				startDate
			}
		}
	}

	${WORKED_SHIFT_FRAGMENT}
	${WORKED_EXPENSE_FRAGMENT}
	${WORKED_TIMESHEET_FRAGMENT}
`

export const GET_CURRENT_WORKED_TIMESHEET_QUERY = gql`
	query getCurrentWorkedTimesheet($workerAssignmentId: String) {
		currentWorkedTimesheet(workerAssignmentId: $workerAssignmentId) {
			...workedTimesheetFragment

			timesheetImages {
				blobId
				id
				filename
				fileUrl
			}

			workedShifts {
				...workedShiftFragment
			}
			weekManagement {
				active
			}

			workerExpenses {
				...workedExpenseFragment
			}
		}
	}

	${WORKED_SHIFT_FRAGMENT}
	${WORKED_EXPENSE_FRAGMENT}
	${WORKED_TIMESHEET_FRAGMENT}
`

export const GET_PREVIOUS_WORKED_TIMESHEET_QUERY = gql`
	query getPreviousWorkedTimesheet($workerAssignmentId: String) {
		previousWorkedTimesheet(workerAssignmentId: $workerAssignmentId) {
			...workedTimesheetFragment

			timesheetImages {
				blobId
				id
				filename
				fileUrl
			}

			workedShifts {
				...workedShiftFragment
			}
			weekManagement {
				active
			}

			workerExpenses {
				...workedExpenseFragment
			}
		}
	}

	${WORKED_SHIFT_FRAGMENT}
	${WORKED_EXPENSE_FRAGMENT}
	${WORKED_TIMESHEET_FRAGMENT}
`

export const CREATE_WORKED_TIMESHEET_MUTATION = gql`
	mutation createWorkedTimesheetMutation(
		$workerId: String!
		$notes: String
		$preEmploymentHours: Float
		$weekManagementId: String
		$startDate: ISO8601Date!
		$endDate: ISO8601Date!
		$didNotWork: Boolean
		$shifts: [WorkedShiftArgument!]
		$reimbursements: [WorkerExpenseArgument!]
		$signedBlobIds: [String!]
		$workerAssignmentId: String
	) {
		createWorkedTimesheet(
			notes: $notes
			submittedThrough: opus_web_portal
			preEmploymentHours: $preEmploymentHours
			weekManagementId: $weekManagementId
			startDate: $startDate
			endDate: $endDate
			didNotWork: $didNotWork
			workerId: $workerId
			workedShiftsAttributes: $shifts
			workerExpensesAttributes: $reimbursements
			signedBlobIds: $signedBlobIds
			workerAssignmentId: $workerAssignmentId
		) {
			...workedTimesheetFragment

			job {
				title
			}

			timesheetImages {
				blobId
				id
				filename
				fileUrl
			}

			workedShifts {
				...workedShiftFragment
			}

			workerExpenses {
				...workedExpenseFragment
			}
		}
	}

	${WORKED_TIMESHEET_FRAGMENT}
	${WORKED_SHIFT_FRAGMENT}
	${WORKED_EXPENSE_FRAGMENT}
`

export const UPDATE_WORKED_TIMESHEET_MUTATION = gql`
	mutation updateWorkedTimesheetMutation(
		$id: String!
		$workerId: String!
		$notes: String
		$preEmploymentHours: Float
		$startDate: ISO8601Date!
		$endDate: ISO8601Date!
		$didNotWork: Boolean
		$shifts: [WorkedShiftArgument!]
		$reimbursements: [WorkerExpenseArgument!]
		$signedBlobIds: [String!]
		$removeFileIds: [String!]
	) {
		updateWorkedTimesheet(
			id: $id
			notes: $notes
			submittedThrough: opus_web_portal
			preEmploymentHours: $preEmploymentHours
			startDate: $startDate
			endDate: $endDate
			didNotWork: $didNotWork
			workerId: $workerId
			workedShiftsAttributes: $shifts
			workerExpensesAttributes: $reimbursements
			signedBlobIds: $signedBlobIds
			removeFileIds: $removeFileIds
		) {
			...workedTimesheetFragment

			job {
				title
			}

			timesheetImages {
				blobId
				id
				filename
				fileUrl
			}

			workedShifts {
				...workedShiftFragment
			}

			workerExpenses {
				...workedExpenseFragment
			}

			workerAssignment {
				id
				shiftTypes
				startDate
				endDate
			}
		}
	}

	${WORKED_TIMESHEET_FRAGMENT}
	${WORKED_SHIFT_FRAGMENT}
	${WORKED_EXPENSE_FRAGMENT}
`

export const DESTROY_FILES_MUTATION = gql`
	mutation destroyFiles($ids: [String!]!) {
		destroyFiles(ids: $ids) {
			success
		}
	}
`

export const DESTROY_FILE_MUTATION = gql`
	mutation destroyFile($id: String!) {
		destroyFile(id: $id) {
			success
		}
	}
`

export const DESTROY_WORKED_SHIFT_MUTATION = gql`
	mutation destroyWorkedShift($id: String!) {
		destroyWorkedShift(id: $id) {
			success
		}
	}
`

export const DESTROY_WORKER_EXPENSE_MUTATION = gql`
	mutation destroyWorkerExpense($id: String!) {
		destroyWorkerExpense(id: $id) {
			success
		}
	}
`
export const GET_WORKED_TIMESHEET = gql`
	query getWorkedTimesheet($workedTimesheetId: String!) {
		workedTimesheet(id: $workedTimesheetId) {
			workedShifts {
				task {
					available
					externalId
					name
				}
			}
		}
	}
`
