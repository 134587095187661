import { ButtonBase, Grid, styled } from '@material-ui/core'
import { NOTI_STATUS } from './notification-item.component'
import { css } from 'styled-components'

export const ContentWrapper = styled(Grid)(({ theme, status }) => ({
	padding: '12px 0 ',
	flexWrap: 'nowrap',

	'& .bell-icon': {
		color: theme.palette.primary.main,
	},

	'& .MuiGrid-item:nth-of-type(2)': {
		display: 'flex',
		flexDirection: 'column',
		gap: '4px',

		'& .MuiTypography-body1': {
			color: '#868686',
			fontFamily: 'Avenir',
			fontSize: '14px',
			fontStyle: 'normal',
			fontWeight: 500,
			lineHeight: '18px',
			letterSpacing: '0.1px',
			...(status === NOTI_STATUS.unread && {
				fontWeight: 900,
				color: '#000000',
			}),
		},
		'& .MuiTypography-body1:nth-of-type(2)': {
			color: '#868686',
			fontFamily: 'Avenir',
			fontSize: '12px',
			fontStyle: 'normal',
			lineHeight: '18px',
			letterSpacing: '0.1px',
		},
		'& .MuiTypography-body1:nth-of-type(3)': {
			color: '#5F9D9D',
			fontFamily: 'Avenir',
			fontSize: '12px',
			fontStyle: 'normal',
			fontWeight: 400,
			lineHeight: '18px',
			letterSpacing: '0.1px',
		},
	},
}))

export const StyledDeleteButton = styled(ButtonBase)({
	display: 'flex',
	padding: '10px 1rem',
	justifyContent: 'center',
	alignItems: 'center',
})

export const typoPopoverStyle = css`
	font-family: Avenir;
	font-size: 14px;
	font-style: normal;
	font-weight: 800;
	line-height: 22px;
	letter-spacing: 0.2px;
	color: #03606a;
`
