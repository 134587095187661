import { css } from 'styled-components'

export const labelStyle = css`
	transform: scale(0.86);
	transform-origin: top left;
	color: rgba(0, 0, 0, 0.5);
`

export const radioStyle = css`
	margin: 2px 9px;
	padding: 0;

	+ span {
		color: rgba(0, 0, 0, 1);
		font-size: 12px;
		font-weight: 400;
	}
`
