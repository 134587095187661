import axios from 'axios'
import { isEmpty } from 'lodash'
import { MASTER_DATA_FIELDS, MASTER_DATA_OBJECTS } from '~/common/constants'
import { store, observable, action } from '~/common/mobx.decorator'
import { appStore } from './app.store'
import { apolloClient, FAVORITE_JOB_MUTATION } from '~/common/apollo'
import { authStore, notifyStore } from '~/stores'
import { redirectEventTrackingBookMark } from '~/common/helpers/bookMark.helper'

@store()
class MasterStore {
	@observable disciplines
	@observable locations
	@observable referrals
	@observable agencies
	@observable chartings
	@observable primarySpecialities
	@observable secondarySpecialities
	@observable licenseStates
	@observable referralRelationships
	@observable jobDisciplines
	@observable jobShifts

	@action
	fetchDisciplines = async (force = false) => {
		if (!force && !isEmpty(this.disciplines)) {
			return
		}

		const response = await axios.get(`/company_configs/${appStore.id}/salesforce_picklists`, {
			params: {
				object: MASTER_DATA_OBJECTS.WorkExperienceOverview,
				field: MASTER_DATA_FIELDS.primarySpecialty,
				// dependent_field: SALESFORCE_FIELDS.primarySpecialty,
			},
		})
		this.disciplines = response.data?.salesforcePicklists
	}

	@action
	fetchJobDisciplines = async (force = false) => {
		if (!force && !isEmpty(this.jobDisciplines)) {
			return
		}

		const response = await axios.get(`/company_configs/${appStore.id}/salesforce_picklists`, {
			params: {
				object: MASTER_DATA_OBJECTS.Job,
				field: MASTER_DATA_FIELDS.specialty,
			},
		})
		this.jobDisciplines = response.data?.salesforcePicklists
	}

	@action
	fetchJobShifts = async (force = false) => {
		if (!force && !isEmpty(this.jobShifts)) {
			return
		}

		const response = await axios.get(`/company_configs/${appStore.id}/salesforce_picklists`, {
			params: {
				object: MASTER_DATA_OBJECTS.Job,
				field: MASTER_DATA_FIELDS.shifts,
			},
		})

		this.jobShifts = response.data?.salesforcePicklists
	}

	@action
	fetchLocations = async (force = false) => {
		if (!force && !isEmpty(this.locations)) {
			return
		}

		const response = await axios.get(`/company_configs/${appStore.id}/salesforce_picklists`, {
			params: {
				object: MASTER_DATA_OBJECTS.Worker,
				field: MASTER_DATA_FIELDS.preferredLocations,
			},
		})

		this.locations = response.data?.salesforcePicklists
	}

	@action
	fetchReferralRelationships = async (force = false) => {
		if (!force && !isEmpty(this.locations)) {
			return
		}

		const response = await axios.get(`/company_configs/${appStore.id}/salesforce_picklists`, {
			params: {
				object: MASTER_DATA_OBJECTS.Worker,
				field: MASTER_DATA_FIELDS.referralRelationship,
			},
		})

		this.referralRelationships = response.data?.salesforcePicklists
	}

	@action
	fetchReferrals = async (force = false) => {
		if (!force && !isEmpty(this.referrals)) {
			return
		}

		const response = await axios.get(`/company_configs/${appStore.id}/salesforce_picklists`, {
			params: {
				object: MASTER_DATA_OBJECTS.Worker,
				field: MASTER_DATA_FIELDS.referral,
			},
		})

		this.referrals = response.data?.salesforcePicklists
	}

	@action
	fetchAgencies = async (force = false) => {
		if (!force && !isEmpty(this.agencies)) {
			return
		}

		const response = await axios.get(`/company_configs/${appStore.id}/salesforce_picklists`, {
			params: {
				object: MASTER_DATA_OBJECTS.WorkExperience,
				field: MASTER_DATA_FIELDS.agency,
			},
		})

		this.agencies = response.data?.salesforcePicklists
	}

	@action
	fetchChartings = async (force = false) => {
		if (!force && !isEmpty(this.chartings)) {
			return
		}

		const response = await axios.get(`/company_configs/${appStore.id}/salesforce_picklists`, {
			params: {
				object: MASTER_DATA_OBJECTS.WorkExperience,
				field: MASTER_DATA_FIELDS.charting,
			},
		})

		this.chartings = response.data?.salesforcePicklists
	}

	@action
	fetchSecondarySpecialities = async (force = false) => {
		if (!force && !isEmpty(this.secondarySpecialities)) {
			return
		}

		const response = await axios.get(`/company_configs/${appStore.id}/salesforce_picklists`, {
			params: {
				object: MASTER_DATA_OBJECTS.WorkExperienceOverview,
				field: MASTER_DATA_FIELDS.secondarySpecialty,
			},
		})

		this.secondarySpecialities = response.data?.salesforcePicklists
	}

	fetchLicenseStates = async (force = false) => {
		if (!force && !isEmpty(this.licenseStates)) {
			return
		}

		const response = await axios.get(`/company_configs/${appStore.id}/salesforce_picklists`, {
			params: {
				object: MASTER_DATA_OBJECTS.WorkerCertification,
				field: MASTER_DATA_FIELDS.licenseState,
			},
		})
		this.licenseStates = response.data?.salesforcePicklists
	}

	@action
	fetchPrimarySpecialities = async (force = false) => {
		if (!force && !isEmpty(this.primarySpecialities)) {
			return
		}

		const response = await axios.get(`/company_configs/${appStore.id}/salesforce_picklists`, {
			params: {
				object: MASTER_DATA_OBJECTS.WorkExperienceOverview,
				field: MASTER_DATA_FIELDS.primarySpecialty,
			},
		})

		this.primarySpecialities = response.data?.salesforcePicklists
	}

	@action
	onFavoriteJob = async (jobId, flag) => {
		try {
			const response = await apolloClient.mutate({
				mutation: FAVORITE_JOB_MUTATION,
				variables: { jobId, action: flag ? 'unfavorite' : 'favorite' },
			})
			await notifyStore.success('$MESSAGES.SUCCESSFUL')
			return response?.data?.favoriteOrUnfavoriteAJob
		} catch (error) {
			notifyStore.error(error.message)
		}
	}
	@action
	onTrackingFavorite = async (jobId, flag, finalizeData) => {
		if (finalizeData?.type) {
			if (finalizeData?.status === 'EVENT_ORDER_CITY') {
				await redirectEventTrackingBookMark(finalizeData.type, {
					job_id: jobId,
					is_bookmarked: !flag,
					worker_id: authStore.id,
					order_number: finalizeData.order,
					city_id: finalizeData.cityId,
				})
			} else if (finalizeData?.status === 'EVENT_ORDER') {
				await redirectEventTrackingBookMark(finalizeData.type, {
					job_id: jobId,
					is_bookmarked: !flag,
					worker_id: authStore.id,
					order_number: finalizeData.order,
				})
			} else {
				await redirectEventTrackingBookMark(finalizeData.type, {
					job_id: jobId,
					is_bookmarked: !flag,
					worker_id: authStore.id,
				})
			}
		}
	}
}

export const masterStore = new MasterStore()
