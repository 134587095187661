import React from 'react'
import { Box, InputLabel, TextField as MuiTextField } from '@material-ui/core'
import { LabelField } from '@opus/web.core.form.label-field'
import { useTranslation } from '@opus/web.core.hooks.use-translation'
import { useField } from 'formik'
import { useCallback, useState } from 'react'
import { useDebounce } from 'react-use'
import { FIELD_MODE } from '~/common/constants'
import { useFieldError, useFieldFocused } from '~/common/hooks'
import { useStyles } from './text-field.style'
import { RemoveSvg as ClearInputSvg } from '~/components/icons'

export const TextField = ({ name, validate, label, placeholder, mode, shrink, helperText, endLabel, ...rest }) => {
	const { t } = useTranslation()
	const classes = useStyles()

	const [field, meta] = useField({
		name,
		validate: mode === FIELD_MODE.edit && validate,
	})
	const [focused, focusProps] = useFieldFocused(field)
	const [inputValue, updateInputValue] = useState(field.value)
	const error = useFieldError(meta)

	useDebounce(
		() => {
			if (field.value !== inputValue && !focused) {
				updateInputValue(field.value)
			}
		},
		500,
		[field.value]
	)

	useDebounce(
		() => {
			field.onChange({ target: { name, value: inputValue } })
		},
		300,
		[inputValue]
	)

	const handleChange = useCallback(
		(event) => {
			updateInputValue(event.target.value || '')
		},
		[updateInputValue]
	)

	const clearInput = useCallback(() => {
		updateInputValue('')
	}, [])

	if (mode === FIELD_MODE.view) {
		return <LabelField classes={{ root: classes.label }} shrink label={t(label)} displayValueFormat={() => inputValue} />
	}

	return (
		<Box>
			<Box display="flex" justifyContent="space-between">
				<InputLabel shrink htmlFor={name} className={classes.label}>
					{t(label)}
				</InputLabel>
				{endLabel}
			</Box>
			<MuiTextField
				id={name}
				placeholder={placeholder && t(placeholder)}
				helperText={error}
				error={!!error}
				InputProps={{
					...focusProps,
					endAdornment: inputValue ? <ClearInputSvg style={{ cursor: 'pointer' }} onClick={clearInput} /> : <> </>,
				}}
				classes={{ root: classes.root }}
				InputLabelProps={{
					shrink: focused || !!inputValue || shrink,
				}}
				{...field}
				value={inputValue || ''}
				onChange={handleChange}
				{...rest}
			/>
		</Box>
	)
}

TextField.defaultProps = {
	mode: FIELD_MODE.edit,
	shrink: true,
}
