import React from 'react'
import { FormControl, FormControlLabel, FormHelperText, Switch } from '@material-ui/core'
import { useField } from 'formik'
import { FIELD_MODE } from '~/common/constants'
import { useFieldError } from '~/common/hooks'
import { useTranslation } from '@opus/web.core.hooks.use-translation'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
	root: {
		width: 42,
		height: 26,
		padding: 0,
		margin: theme.spacing(1),
	},
	switchBase: {
		padding: 1,
		'&$checked': {
			transform: 'translateX(16px)',
			color: theme.palette.common.white,
			'& + $track': {
				backgroundColor: '#3EA9BC',
				opacity: 1,
				border: 'none',
			},
		},
		'&$focusVisible $thumb': {
			color: '#0E3F6C',
			border: '6px solid #fff',
		},
	},
	thumb: {
		width: 24,
		height: 24,
	},
	track: {
		borderRadius: 26 / 2,
		border: `1px solid ${theme.palette.grey[400]}`,
		backgroundColor: theme.palette.grey[200],
		opacity: 1,
		transition: theme.transitions.create(['background-color', 'border']),
	},
	checked: {},
	focusVisible: {},
}))

export const SwitchField = ({ name, validate, label, mode, ...props }) => {
	const { disabled, id, callBackHandler, nameAttribute, handleSelectAllCallBack } = props

	const { t } = useTranslation()
	const classes = useStyles()

	const [field, meta] = useField({ name, validate: mode === FIELD_MODE.edit && validate })
	const error = useFieldError(meta)
	const handleChange = (event, checked) => {
		event.target.value = checked

		if (handleSelectAllCallBack) {
			handleSelectAllCallBack(event.target.name)
		}

		if (callBackHandler) {
			callBackHandler(event.target.id, nameAttribute)
		}

		if (mode === FIELD_MODE.edit) {
			field.onChange(event)
		}
	}

	return (
		<FormControl error={!!error}>
			<FormControlLabel
				control={
					<Switch
						id={id}
						disabled={disabled}
						onChange={handleChange}
						checked={field.value || false}
						name={name}
						focusVisibleClassName={classes.focusVisible}
						disableRipple
						classes={{
							root: classes.root,
							switchBase: classes.switchBase,
							thumb: classes.thumb,
							track: classes.track,
							checked: classes.checked,
						}}
					/>
				}
				label={t(label)}
			/>
			{error && <FormHelperText>{error}</FormHelperText>}
		</FormControl>
	)
}

SwitchField.defaultProps = {
	mode: FIELD_MODE.edit,
}
