import { gql } from '@apollo/client'
import { WORKER_EDUCATION_FRAGMENT } from '../apollo.fragment'

export const GET_WORKER_CREDENTIALS_WALLET_QUERY = gql`
	query getWorkerCredentialsWalletQuery($workerId: String!, $credentialId: String) {
		workerCredentials(workerId: $workerId, credentialId: $credentialId) {
			id
			esignUrl
			requiredFields
			credentialImages {
				id
				blobId
				contentType
				fileUrl
				filename
			}
			credential {
				id
				isLicense
				category
				default
				name
				walletLocationIndex
			}
			title
			status
			visibleFields
			title
			expirationDate
			effectiveDate
			name
			number
		}
	}
`

export const MARK_CREDENTIAL_AS_SIGNED = gql`
	mutation markSignedWorkerCredentialMutation($id: String!) {
		markSignedWorkerCredential(id: $id) {
			success
		}
	}
`
export const CREATE_WORKER_EDUCATION_MUTATION = gql`
	mutation createWorkerEducationMutation(
		$schoolName: String
		$major: String
		$state: String
		$city: String
		$country: String
		$degreeName: String
		$graduationDate: ISO8601Date
	) {
		createWorkerEducation(
			schoolName: $schoolName
			major: $major
			state: $state
			city: $city
			country: $country
			degreeName: $degreeName
			graduationDate: $graduationDate
		) {
			...workerEducationFragment
		}
	}

	${WORKER_EDUCATION_FRAGMENT}
`
