export const showMappingAddress = (data) => {
	const addressParts = []
	if (!!data?.address) {
		addressParts.push(data.address)
	}

	if (!!data?.city) {
		addressParts.push(data.city)
	}

	if (!!data?.addressState) {
		addressParts.push(data.addressState)
	}

	if (!!data?.country) {
		addressParts.push(data.country)
	}

	if (!!data?.zipcode) {
		addressParts.push(data.zipcode)
	}

	return addressParts.filter(Boolean).join(', ')
}
