import { TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { useField, useFormikContext } from 'formik'
import { filter, find, includes } from 'lodash'
import React, { useCallback, useMemo, useState } from 'react'
import { MdExpandMore } from 'react-icons/md'
import { useDebounce } from 'react-use'
import { FIELD_MODE } from '~/common/constants'
import { useFieldError, useFieldFocused } from '~/common/hooks'
import { useTranslation } from '@opus/web.core.hooks.use-translation'
import { LabelField } from '@opus/web.core.form.label-field'
import axios from 'axios'
import { parseLocation } from '~/common/helpers'
import CircularProgress from '@material-ui/core/CircularProgress'

export const CityField = ({ name, validate, label, placeholder, disabled, freeSolo, mode, multiple, disableClearable }) => {
	const { t } = useTranslation()
	const { setFieldValue } = useFormikContext()
	const [field, meta] = useField({ name, validate: mode === FIELD_MODE.edit && validate })

	const [inputValue, updateInputValue] = useState(field.value?.name)
	const [focused, focusProps] = useFieldFocused(field)

	const [open, setOpen] = React.useState(false)
	const [options, setOptions] = React.useState([])
	const [fetchDone, setFetchDone] = React.useState(false)
	const loading = open && options.length === 0 && !fetchDone

	const fetchCities = async (input) => {
		const response = await axios.get(`/cities/search?terms=${input}`)
		const cities = response?.data?.data
		setFetchDone(true)
		setOptions(cities)
	}

	React.useEffect(() => {
		if (!loading) {
			return undefined
		}

		fetchCities(field.value?.name || '')
	}, [loading, field.value])

	React.useEffect(() => {
		if (!open) {
			setOptions([])
		} else {
			fetchCities(field.value?.name || '')
		}
	}, [open, field.value])

	const error = useFieldError(meta)

	const handleChange = useCallback(
		(_, option) => {
			if (option !== null) {
				const address = parseLocation(option)
				setFieldValue(name, { ...address })
				updateInputValue(multiple ? option?.map((opt) => opt.name) : option?.name)
			} else {
				setFieldValue(name, {})
				updateInputValue(undefined)
			}
		},
		[updateInputValue, multiple, setFieldValue, name]
	)

	useDebounce(
		() => {
			if (field.value?.name !== inputValue && !focused) {
				updateInputValue(field.value?.name || (multiple ? [] : ''))
			}
		},
		300,
		[field.value]
	)

	const selectedOption = useMemo(() => {
		if (multiple) {
			return filter(options, (option) => includes(field.value, option.name)) || []
		}

		return find(options, (option) => option.fullAddress === field.value.fullAddress)
	}, [options, field.value, multiple])

	const viewValue = useMemo(() => (multiple ? selectedOption?.map((opt) => opt.name)?.join(', ') : selectedOption?.name), [multiple, selectedOption])

	if (mode === FIELD_MODE.view) {
		return <LabelField label={t(label)} displayValueFormat={() => viewValue || field.value} />
	}

	return (
		<Autocomplete
			id={name}
			freeSolo={freeSolo}
			open={open}
			onOpen={() => {
				setOpen(true)
			}}
			onClose={() => {
				setOpen(false)
			}}
			loading={loading}
			options={options}
			getOptionSelected={(option, value) => {
				return option.fullAddress === value.fullAddress
			}}
			renderOption={(option) => (
				<>
					<b>{option.name}</b>
					{', ' + option.stateCode + ', ' + option.countryCode}
				</>
			)}
			getOptionLabel={(option) => {
				return option.name || ''
			}}
			fullWidth
			multiple={multiple}
			{...field}
			{...focusProps}
			disabled={disabled}
			onChange={handleChange}
			inputValue={inputValue}
			disableClearable={disableClearable}
			filterOptions={(options, params) => {
				return options
			}}
			onInputChange={(event, newInputValue) => {
				updateInputValue(newInputValue)
				fetchCities(newInputValue)
			}}
			popupIcon={<MdExpandMore />}
			renderInput={(params) => {
				return (
					<TextField
						{...params}
						label={t(label)}
						disabled={disabled}
						placeholder={placeholder && t(placeholder)}
						error={!!error}
						helperText={error}
						InputProps={{
							...params.InputProps,
							endAdornment: (
								<React.Fragment>
									{loading ? <CircularProgress color="inherit" size={20} /> : null}
									{params.InputProps.endAdornment}
								</React.Fragment>
							),
						}}
					/>
				)
			}}
		/>
	)
}

CityField.defaultProps = {
	freeSolo: false,
	mode: FIELD_MODE.edit,
	multiple: false,
}
