export const app = {
	white: '#ffffff',
	black: '#000000',
}

export const text = {
	default: '#24444E',
	text2: '#7E7E80',
	text3: '#3D3D3D',
}

export const primary = {
	inactive: '#5F9D9D',
	main: '#3EA9BC',
}

export const brand = {
	emerald: '#3EA9BC',
	sea_blue: '#6CBABA',
	mustard_yellow: '#FF9B04',
	coral: '#FF7D74',
	jewel: '#7F3552',
	light_blue: '#D8F4F7',
	light_yellow: '#FFFBF2',
	faint_yellow: '#FFFBF2',
	blue_light: '#F9FEFE',
}

export const denotive = {
	blue: '#2854AA',
	green: '#297704',
	error: '#FB4E4E',
	dark_blue: '#0E3F6C',
}

export const wireframe = {
	default: '#3C64B1',
	dark: '#1E4693',
	light: '#5A82CF',
	bg: '#3C64B1',
}

export const flowkit = {
	purple: '#7B61FF',
	dark: '#7B61FF',
	red: '#FC5555',
	green: '#29CC6A',
	blue: '#0099FF',
	white: '#FFFFFF',
}

export const background = {
	app,
	element: {
		light: '#EDF7FF',
	},
}

export const colors = {
	menu_inactive: '#868686',
	gray: '#D1D1D1',
	gray_lightest: '#F4F5F4',
	gray_light: '#E6E6E6',
	dark_yellow: '#B59C5A',
}
