import { Box, Dialog, Grid, SvgIcon, Typography } from '@material-ui/core'
import React from 'react'
import { useTranslation } from '@opus/web.core.hooks.use-translation'
import { notifyDialogStyle } from './notify.style'
import { appStore } from '~/stores'
import { CloseIconDialog } from '~/components/icons'
import { Button } from '~/components/button'
import { isEmpty, isFunction } from 'lodash'

export const NotifyDialog = ({
	title,
	hideTitle,
	okText,
	cancelText,
	onCancel = null,
	onOk,
	okProps,
	cancelProps,
	children,
	disableBackdropClick,
	disableEscapeKeyDown,
	showButtonClose = false,
	onClose,
	image = undefined,
	childrenEnhancedTitle,
	contentStyle,
	isShowOkText = true,
	containerStyle,
	...props
}) => {
	const { t } = useTranslation()

	const handleClose = (event, reason) => {
		if (showButtonClose) {
			if (disableBackdropClick && reason === 'backdropClick') {
				return false
			}

			if (disableEscapeKeyDown && reason === 'escapeKeyDown') {
				return false
			}

			if (typeof onClose === 'function') {
				onClose()
			}
		}
	}

	return (
		<Dialog onClose={showButtonClose && isFunction(handleClose) ? handleClose : () => {}} {...props} css={notifyDialogStyle}>
			<Box margin={3} css={containerStyle}>
				<div style={{ display: 'flex' }}>
					{childrenEnhancedTitle}
					{!hideTitle && (
						<Typography
							css={[
								appStore?.getTheme?.typography?.h5,
								{
									color: appStore?.getTheme?.colors.primary.main,
									fontSize: '16px',
									fontWeight: 800,
								},
							]}
							variant="subtitle1"
						>
							{t(title)}
						</Typography>
					)}
				</div>

				{showButtonClose && (
					<div
						data-testid="btn-close"
						onClick={handleClose}
						style={{
							position: 'absolute',
							top: 15,
							right: 15,
							cursor: 'pointer',
						}}
					>
						<SvgIcon component={CloseIconDialog} />
					</div>
				)}

				{!isEmpty(image) && (
					<Box style={{ display: 'flex', justifyContent: 'center', marginTop: 24, marginBottom: 40 }}>
						<SvgIcon style={{ fontSize: '140px' }} component={image} />
					</Box>
				)}

				<Box marginTop={1} marginBottom={1} className="content-notify" css={contentStyle}>
					{children}
				</Box>

				<Grid container spacing={2}>
					{onCancel && (
						<Grid item xs={6}>
							<Button variant="outlined" color="primary" onClick={onCancel} {...cancelProps}>
								{t(cancelText)}
							</Button>
						</Grid>
					)}
					{isShowOkText && (
						<Grid item xs={onCancel ? 6 : 12}>
							<Button color={'primary'} onClick={onOk} {...okProps}>
								{t(okText)}
							</Button>
						</Grid>
					)}
				</Grid>
			</Box>
		</Dialog>
	)
}

NotifyDialog.defaultProps = {
	open: true,
	okText: 'OK',
	cancelText: 'CANCEL',
	title: 'NOTIFICATION',
}
