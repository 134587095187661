import { isValidNumberForRegion, AsYouType, isValidPhoneNumber } from 'libphonenumber-js'

export const formatPhoneNumber = (value) => {
	// if input value is falsy eg if the user deletes the input, then just return
	if (!value) return value

	// clean the input for any non-digit values.
	const phoneNumber = value.replace(/[^\d]/g, '')

	// phoneNumberLength is used to know when to apply our formatting for the phone number
	const phoneNumberLength = phoneNumber.length

	// we need to return the value with no formatting if its less then four digits
	// this is to avoid weird behavior that occurs if you  format the area code to early

	if (phoneNumberLength < 4) return phoneNumber

	// if phoneNumberLength is greater than 4 and less the 7 we start to return
	// the formatted number
	if (phoneNumberLength < 7) {
		return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`
	}

	// finally, if the phoneNumberLength is greater then seven, we add the last
	// bit of formatting and return it.

	if (phoneNumberLength <= 10) {
		return `(${phoneNumber.slice(0, phoneNumberLength - 7)}) ${phoneNumber.slice(phoneNumberLength - 7, phoneNumberLength - 4)}-${phoneNumber.slice(
			phoneNumberLength - 4,
			phoneNumberLength
		)}`
	}

	return `(${phoneNumber.slice(1, phoneNumberLength - 7)}) ${phoneNumber.slice(phoneNumberLength - 7, phoneNumberLength - 4)}-${phoneNumber.slice(
		phoneNumberLength - 4,
		phoneNumberLength
	)}`
}

export const validateUSPhoneNumber = (value) => {
	return isValidNumberForRegion(value, 'US')
}

export const formatPhoneNumberAsYouType = (value) => {
	if (!value) return null

	return new AsYouType('US').input(value)
}

export const isValidPhoneNumberForUS = (value) => {
	if (!value) return false

	return isValidPhoneNumber(value, 'US')
}
